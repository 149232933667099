import { Container, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { MatchTypeMenu, NextMatches } from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { Footer, HeaderLogged } from 'shared/layouts';

function Home() {
  const team = useContext(TeamContext);
  const { t } = useTranslation();

  const title = (
    <Typography
      sx={{
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '24px',
        color: '#263140',
      }}
    >
      {t('Estatísticas gerais')}
    </Typography>
  );

  const headerTitle = (
    <>
      {t('Olá')}
      ,
      <strong>{` ${team.name}`}</strong>
    </>
  );

  return (
    <div id="home">
      <HeaderLogged title={headerTitle} team={team} />
      <NextMatches />
      <Container component="main" maxWidth="xs" className="home" sx={{ marginTop: '40px', paddingBottom: '110px' }}>
        {title}
        <MatchTypeMenu
          tournamentLink={`/team/${team._id}/stats/tournaments`}
          scrimmageLink={`/team/${team._id}/stats/scrimmage`}
          practiceLink={`/team/${team._id}/stats/practice`}
          color="#0066CC"
        />
        <Outlet />
      </Container>
      <Footer />
    </div>
  );
}

export default Home;
