import {
  Box, LinearProgress, linearProgressClasses, styled,
  Typography,
} from '@mui/material';
import React from 'react';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 12,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#3DCD7F' : '#3DCD7F',
  },
}));

function StepsCounter(props) {
  const currentStep = (props.currentStep - 1);
  const percentage = (currentStep / props.totalSteps) * 100;

  return (
    <>
      <Typography>
        {`Passo ${props.currentStep} de ${props.totalSteps}`}
      </Typography>
      <Box sx={{ flexGrow: 1, mb: 3 }}>
        <BorderLinearProgress variant="determinate" value={percentage} />
      </Box>
    </>
  );
}

export default StepsCounter;
