import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import {
  ErrorMessage,
  Loading,
  MatchCard,
  TournamentLabel,
} from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { useFetchTeamTournamentMatchesQuery } from 'shared/services';

export function AthleteListTournamentMatches() {
  const team = useContext(TeamContext);
  const [athlete] = useOutletContext();
  const { tournamentId } = useParams();
  const { t } = useTranslation();
  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data: matches,
  } = useFetchTeamTournamentMatchesQuery({
    team: team._id, tournament: tournamentId,
  });

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <ErrorMessage messages={error.data.messages} />;
  } else if (isSuccess) {
    content = matches.map((match) => <MatchCard key={`match-card-${match._id}`} match={match} linkTo={`/athletes/${athlete._id}/stats/match/${match._id}`} actionLabel={t('Ver Estatísticas')} showIcon />);
  }

  return (
    <>
      {matches
        && matches[0]?.tournament
        && (
          <Box sx={{ marginTop: '20px' }}>
            <TournamentLabel label={matches[0]?.tournament} />
          </Box>
        )}
      {content}
    </>
  );
}

export default AthleteListTournamentMatches;
