import { Route, Routes } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { MatchView } from 'pages';
import {
  MatchCreate,
  MatchUpdate,
  MatchStatsTable,
  MatchTimeline,
  Play,
  PageNotFound,
  EditPlay,
} from 'shared/components';
import { PlayTypeContext, ScoutingStatusProvider } from 'shared/contexts';

export function MatchRoutes() {
  const [playType, setPlayType] = useState('');
  const contextValue = useMemo(() => ({ playType, setPlayType }), [playType, setPlayType]);

  return (
    <PlayTypeContext.Provider value={contextValue}>
      <ScoutingStatusProvider>
        <Routes>
          <Route path="*" element={<PageNotFound />} />
          <Route path="new/:matchType" element={<MatchCreate />} />
          <Route path=":matchId/edit" element={<MatchUpdate />} />
          <Route path=":matchId/play" element={<Play />} />
          <Route path=":matchId/play/:playId" element={<EditPlay />} />

          <Route path=":matchId" element={<MatchView />}>
            <Route index element={<MatchTimeline />} />
            <Route path="stats" element={<MatchStatsTable />} />
            <Route path="timeline" element={<MatchTimeline />} />
          </Route>
        </Routes>
      </ScoutingStatusProvider>
    </PlayTypeContext.Provider>
  );
}
