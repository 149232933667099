import React from 'react';
import { CookieConsent } from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

export function CookieBanner() {
  const { t } = useTranslation();

  return (
    <CookieConsent
      location="bottom"
      buttonText={t('Aceitar')}
      cookieName="flagStatsCookiesConsent"
      style={{
        background: 'linear-gradient(137.13deg, #004D99 31.91%, #0080FF 140.67%)',
        color: '#ffffff',
        textAlign: 'left',
        display: 'block',
      }}
      buttonStyle={{
        background: '#ffffff',
        color: 'rgb(0, 102, 204)',
        fontWeight: 'bold',
        padding: '10px 20px',
        margin: '20px auto',
        display: 'block',
      }}
      contentStyle={{
        maxWidth: '100%',
        padding: '0 10px',
        display: 'block',
      }}
    >
      {t('Este site usa cookies para garantir que você obtenha a melhor experiência.')}
      <a
        href={`https://site.flagstats.app/${t('pt/politica-de-privacidade')}`}
        target="_blank"
        rel="noreferrer"
        style={{
          color: 'white',
          textDecoration: 'underline',
          marginLeft: '5px',
        }}
      >
        {t('Politica de privacidade')}
      </a>
      .
    </CookieConsent>
  );
}

export default CookieBanner;
