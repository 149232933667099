import {
  Box,
  Button, Container, Grid, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { updateToken, useUpdateUserOnboardingMutation } from 'shared/services';

function RegisterSuccessHandBlueBg() {
  const [updateOnboarding] = useUpdateUserOnboardingMutation();
  const navigate = useNavigate();

  const handleupdateOnboarding = async () => {
    const result = await updateOnboarding({ showOnboarding: false });
    if (result.data.success) {
      if (result.data?.token) {
        updateToken(result.data);
      }
      navigate('/');
    }
  };

  const { t } = useTranslation();

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        background: 'linear-gradient(137.13deg, #004D99 31.91%, #0080FF 140.67%)',
        color: '#fff',
      }}
    >
      <Grid
        container
        direction="column"
        sx={{
          height: '100vh',
        }}
      >
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            gap: '40px',
            padding: '0 10px',
          }}
        >
          <Grid item>
            <img src="/assets/img/svg/hand_success_bg_blue.svg" alt="success stats" />

          </Grid>
          <Grid item>
            <Typography component="h2" variant="h6" sx={{ marginBottom: '10px' }}>{t('Cadastro concluído com sucesso!')}</Typography>
            <Typography component="p" variant="body2" sx={{ fontSize: '0.855rem' }}>{t('Para registrar seus jogos e acompanhar suas estatísticas de time, clique em começar.')}</Typography>
          </Grid>

          <Grid item>
            <Button
              fullWidth
              onClick={() => handleupdateOnboarding()}
              variant="contained"
              color="secondary"
            >
              {t('Começar')}
            </Button>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
}

export default RegisterSuccessHandBlueBg;
