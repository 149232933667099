import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import { IconAthlete } from 'shared/components';

export function CardAthlete(props) {
  const { offense } = props.athlete;
  const { defense } = props.athlete;
  let teamPosition;
  if (offense && defense) {
    teamPosition = 'Ataque / Defesa';
  } else if (offense) {
    teamPosition = 'Ataque';
  } else {
    teamPosition = 'Defesa';
  }

  return (
    props.athlete && (
      <Grid item>
        <Card
          sx={{
            minHeight: '168px',
          }}
        >
          <CardHeader
            sx={{
              fontSize: '14px',
              padding: '8px 16px',
            }}
            avatar={(
              <Avatar
                sx={{
                  width: '48px',
                  height: '48px',
                }}
              >
                <IconAthlete />
              </Avatar>
            )}
            action={(
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            )}
            title={teamPosition}
            subheader={`#${props.athlete.number} ${props.athlete.name}`}
          />
          <Divider
            sx={{
              padding: '8px 0',
              color: '#9DB3CC',
              opacity: '0.32',
            }}
            variant="middle"
          />
          <CardContent
            sx={{
              textAlign: 'center',
              padding: '8px 16px',
            }}
          >
            {props.athlete.positions && props.athlete.positions.map((option) => (
              <Typography
                key={option}
                component="span"
                variant="caption"
                sx={{
                  display: 'inline-block',
                  padding: '0px 8px',
                  margin: '5px',
                  background: '#EFF3F7',
                  borderRadius: '4px',
                  fontFamily: 'Mulish',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: '#0066CC',
                  textAlign: 'center',
                }}
              >
                {option}
              </Typography>
            ))}
          </CardContent>
        </Card>
      </Grid>
    )
  );
}

export default CardAthlete;
