import React, { useState } from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDeletePlayMutation } from 'shared/services';

const DeletePlayDialog = React.forwardRef(({
  openDeletePlayDialog,
  handleClose,
  match,
  play,
  title,
}, ref) => {
  const { t } = useTranslation();
  const [setState] = useState({
    redirect: false,
  });
  const [deletePlay, deletePlayResult] = useDeletePlayMutation();

  const handleDeletePlay = async (e) => {
    e.preventDefault();
    const teamId = match.team;
    const playId = play._id;
    if (!match?._id || !teamId || !playId) {
      setState((prevState) => ({ ...prevState, error: t('Partida não encontrada.') }));
    } else {
      const response = await deletePlay({
        team: teamId,
        match: match._id,
        play: playId,
        body: {
          play: playId,
        },
      });
      if (response?.data?.success) {
        handleClose();
      }
    }
  };

  return (
    <Dialog
      className="ignore-unselect-playCard"
      ref={ref}
      open={openDeletePlayDialog}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {t('Exclusão de jogada')}
        {deletePlayResult.isError && <Alert severity="error">{deletePlayResult.error?.data?.messages}</Alert>}
      </DialogTitle>
      <DialogContent>
        <p>{`${t('Você está prestes a excluir esta jogada')}: `}</p>
        <p>
          <strong>
            {title}
          </strong>
        </p>
        <p>{t('Esta ação não pode ser desfeita.')}</p>
        <p>{t('Deseja continuar?')}</p>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} variant="outlined" size="small">
          {t('Cancelar')}
        </Button>
        <Button
          variant="contained"
          size="small"
          color="error"
          onClick={handleDeletePlay}
        >
          {t('Excluir jogada')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default DeletePlayDialog;
