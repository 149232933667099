import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Container,
  createFilterOptions,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useParams } from 'react-router-dom';
import { IconClose } from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { useCreateMatchMutation, useFetchTournamentsQuery } from 'shared/services';

export function MatchCreate() {
  const team = useContext(TeamContext);
  const { matchType } = useParams();
  const { t } = useTranslation();

  const filter = createFilterOptions();

  function getLabel() {
    switch (matchType) {
      case 'tournament':
        return t('Jogo de campeonato');
      case 'scrimmage':
        return t('Amistoso');
      default:
        return t('Treino');
    }
  }

  const matchLabel = getLabel();

  const [state, setState] = useState({
    label: matchLabel,
    _id: '',
    adversary: '',
    date: '',
    local: '',
    tournament: '',
    type: matchType,
    athletes: [],
    plays: [],
    error: '',
    redirect: false,
    submitted: false,
  });

  const tournaments = useFetchTournamentsQuery(team._id);
  const [createMatch, createMatchResult] = useCreateMatchMutation();

  const handleSubmit = async (e) => {
    setState((prevState) => ({ ...prevState, submitted: true }));
    e.preventDefault();

    const {
      adversary, date, local, type, tournament,
    } = state;

    let validate = false;
    const isValidPractice = team && date;
    const isValidScrimmage = isValidPractice && adversary;
    const isValidTounament = isValidScrimmage && tournament;

    switch (type) {
      case 'tournament':
        validate = isValidTounament;
        break;
      case 'scrimmage':
        validate = isValidScrimmage;
        break;
      case 'practice':
        validate = isValidPractice;
        break;
      default:
        validate = isValidPractice;
        break;
    }

    if (validate) {
      const result = await createMatch({
        team: team._id,
        body: {
          team, adversary, date: date.toJSDate(), local, type, tournament,
        },
      });
      if (result?.data?.success) {
        setState((prevState) => ({ ...prevState, redirect: `/matches/${result.data.match._id}` }));
      }
    } else {
      setState((prevState) => ({ ...prevState, error: t('Preencha os campos obrigatórios para continuar'), submitted: false }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDateInputChange = (datetime) => {
    if (!datetime) {
      setState((prevState) => ({ ...prevState, error: t('É preciso selecionar uma data') }));
      return;
    }

    datetime.set({
      hour: state.date.hour,
      minute: state.date.minute,
      second: 0,
    });

    setState((prevState) => ({ ...prevState, date: datetime }));
  };

  const handleTimeInputChange = (datetime) => {
    if (!datetime) {
      setState((prevState) => ({ ...prevState, error: t('É preciso selecionar um horário') }));
      return;
    }

    datetime.set({
      day: state.date.day,
      month: state.date.month,
      year: state.date.year,
    });

    setState((prevState) => ({ ...prevState, date: datetime }));
  };

  const tournamentOptions = [];
  if (tournaments.isSuccess) {
    tournaments.data.map((tournament) => tournamentOptions.push({
      value: tournament, label: tournament,
    }));
  }

  const handleSelectChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setState((prevState) => ({ ...prevState, tournament: newValue }));
    } else if (newValue?.value) {
      setState((prevState) => ({ ...prevState, tournament: newValue.value }));
    } else {
      setState((prevState) => ({ ...prevState, tournament: newValue }));
    }
  };

  return (
    <>
      {state.redirect && <Navigate to={state.redirect} />}
      <Box
        sx={{
          padding: '20px',

        }}
      >
        <Link to="/dashboard"><IconClose /></Link>
        <Typography variant="h6" align="center">
          {t('Novo {{label}}', { label: state.label })}
        </Typography>
      </Box>

      <Divider />

      <Container component="main" maxWidth="xs">

        <Box
          sx={{
            marginTop: 4,
            marginBottom: '114px',
            display: 'flex',
            flexDirection: 'center',
            alignItems: 'center',
          }}
        >
          <Grid container direction="column" spacing={2}>

            <Grid item>
              <Typography variant="h6">
                {state.label}
              </Typography>
            </Grid>

            <Grid item>
              {createMatchResult.isError && <Alert severity="error">{createMatchResult.error?.data?.message}</Alert>}
              {state.error && <Alert severity="error">{state.error}</Alert>}
              <Box
                id="match-form"
                component="form"
                onSubmit={handleSubmit}
              >
                <Grid item>
                  <input id="matchType" type="hidden" name="type" value={matchType} />

                  {(matchType === 'tournament')
                    && (
                      <Box
                        sx={{ mt: 2 }}
                      >
                        <FormControl variant="filled" fullWidth>
                          <Autocomplete
                            name="tournament"
                            value={state.tournament}
                            onChange={handleSelectChange}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              const { inputValue } = params;
                              // Suggest the creation of a new value
                              const isExisting = options.some(
                                (option) => inputValue === option.value,
                              );
                              if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                  value: inputValue,
                                  label: `${t('Adicionar')} "${inputValue}"`,
                                });
                              }

                              return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="tournament"
                            options={tournamentOptions}
                            getOptionLabel={(option) => {
                              // Regular // Add created dynamically
                              if (option.value) {
                                return option.value;
                              }

                              return option;
                            }}
                            /* TODO: pesquisar alternativa para passar
                              atributos para TextField sem utilizar spreding de props */
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                            freeSolo
                            renderInput={
                              (params) => (
                                <TextField
                                  /* TODO: pesquisar alternativa para passar
                                    atributos para TextField sem utilizar spreding de props */
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...params}
                                  error={state.error !== '' && state.tournament === ''}
                                  helperText={state.error !== '' && state.tournament === '' ? t('Jogo de campeonato deve ser preenchido.') : ' '}
                                  label={t('Procurar campeonato')}
                                />
                              )
                            }
                          />
                        </FormControl>
                      </Box>
                    )}

                  {matchType !== 'practice'
                  && (
                    <TextField
                      id="adversary"
                      label={t('Adversário')}
                      name="adversary"
                      value={state.adversary}
                      error={state.error !== '' && state.adversary === ''}
                      helperText={state.error !== '' && state.adversary === '' ? t('Adversário deve ser preenchido.') : ''}
                      onChange={handleInputChange}
                      margin="normal"
                      fullWidth
                      color={state.error ? 'error' : ''}
                    />
                  )}

                  <MobileDatePicker
                    id="date"
                    label={t('Data do {{label}}', { label: matchLabel })}
                    name="date"
                    value={state.date}
                    onChange={handleDateInputChange}
                    inputFormat="d/M/y"
                    renderInput={
                      (params) => (
                        <TextField
                          /* TODO: pesquisar alternativa para passar
                            atributos para TextField sem utilizar spreding de props */
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={state.error !== '' && state.date === ''}
                          helperText={
                            state.error !== ''
                            && state.date === '' ? t('Data deve ser preenchida.') : ''
                          }
                          margin="normal"
                          fullWidth
                          variant="filled"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <EventIcon />
                              </InputAdornment>
                            ),
                            disableUnderline: true,
                          }}
                        />
                      )
                    }
                  />

                  <MobileTimePicker
                    id="time"
                    label={t('Horário do {{label}}', { label: matchLabel })}
                    name="time"
                    ampm={false}
                    value={state.date}
                    error={state.error !== '' && state.date === ''}
                    onChange={handleTimeInputChange}
                    renderInput={
                      (params) => (
                        <TextField
                          /* TODO: pesquisar alternativa para passar
                            atributos para TextField sem utilizar spreding de props */
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={state.error !== '' && state.date === ''}
                          helperText={
                            state.error !== ''
                            && state.date === '' ? t('Hora deve ser preenchida.') : ''
                          }
                          margin="normal"
                          fullWidth
                          variant="filled"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccessTimeIcon />
                              </InputAdornment>
                            ),
                            disableUnderline: true,
                          }}
                        />
                      )
                    }
                  />

                  <TextField
                    id="local"
                    color="warning"
                    label={t('Local')}
                    name="local"
                    value={state.local}
                    onChange={handleInputChange}
                    margin="normal"
                    fullWidth
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box
        sx={{
          position: 'fixed',
          bottom: '0',
          left: '0',
          width: '100%',
          zIndex: '2',
        }}
      >
        <Button
          type="submit"
          form="match-form"
          fullWidth
          variant="contained"
          color="primary"
          disabled={state.submitted}
        >
          {t('Salvar')}
        </Button>
      </Box>
    </>
  );
}

export default MatchCreate;
