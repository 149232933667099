import { Link, useLocation } from 'react-router-dom';
import { Box, Fab } from '@mui/material';
import { IconTactics } from './Icons';

function CoachIAButton(props) {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const lastSegment = pathSegments[pathSegments.length - 1];

  let positionStyle = {};
  if (lastSegment === 'timeline') {
    positionStyle = props.ended ? { bottom: '50px' } : { bottom: '150px' };
  } else if (lastSegment === 'stats') {
    positionStyle = { bottom: '50px' };
  }

  const gradientColor = 'rgba(255, 255, 255, 0.8)';
  const gradient = `radial-gradient(circle at top center, ${gradientColor} 0%, rgba(255, 255, 255, 0) 100%)`;
  return (
    <Box
      role="presentation"
      sx={{
        position: 'fixed',
        right: 8,
        zIndex: 4,
        ...positionStyle,
      }}
    >
      <Link
        to="/coach-ia"
        state={{ prevPath: location.pathname }}
        style={{ textDecoration: 'none' }}
      >
        <Fab
          size="large"
          aria-label="scroll back to top"
          sx={{
            background: `${gradient}, ${gradientColor}`,
          }}
        >
          <IconTactics color="#0066CC" size="30" />
        </Fab>
      </Link>
    </Box>
  );
}

export default CoachIAButton;
