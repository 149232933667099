import {
  Box, Container, Grid, Typography,
} from '@mui/material';

export function EmptyPage(props) {
  return (
    <Container component="main" maxWidth="md">
      <Grid container direction="column">
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            gap: '40px',
            padding: '0 10px',
            marginTop: '50px',
          }}
        >
          <Grid item>
            <img src="/assets/img/svg/home-empty.svg" alt="success stats" />

          </Grid>
          <Grid item>
            <Typography component="h2" variant="h6" align="left">{props.title}</Typography>
            <Typography component="p" variant="body2" align="left">{props.content}</Typography>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
}

export default EmptyPage;
