import { apiSlice } from './apiSlice';

const teamSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchTeam: builder.query({
      query: () => '/api/team',
      providesTags: () => [{ type: 'Teams', id: 'team' }],
    }),
    fetchTeamStatsByType: builder.query({
      query: ({ team, type }) => `/api/team/${team}/stats/${type}`,
      providesTags: () => [{ type: 'Teams', id: 'all' }],
    }),
    fetchTeamResumeStatsByType: builder.query({
      query: ({ team, type }) => `/api/team/${team}/stats/${type}/resume`,
      providesTags: () => [{ type: 'Teams', id: 'all' }],
    }),
    fetchTeamTournaments: builder.query({
      query: ({ team }) => `/api/team/${team}/tournaments/stats`,
      providesTags: (result, error, id) => [{ type: 'Athletes', id }],
    }),
    fetchTeamTournamentMatches: builder.query({
      query: ({ team, tournament }) => `/api/team/${team}/tournaments/${tournament}/matches`,
      providesTags: (result, error, id) => [{ type: 'Athletes', id }],
    }),
    createTeam: builder.mutation({
      query: (body) => ({
        url: '/api/team',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Teams', id: 'team' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchTeamQuery,
  useFetchTeamTournamentsQuery,
  useFetchTeamTournamentsByAthleteQuery,
  useFetchTeamStatsByTypeQuery,
  useFetchTeamResumeStatsByTypeQuery,
  useFetchTeamMatchesByTypeQuery,
  useFetchTeamStatsByMatchQuery,
  useFetchTeamTournamentMatchesQuery,
  useCreateTeamMutation,
} = teamSlice;
