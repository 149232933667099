import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ScoutingStatusContext } from 'shared/contexts';
import { useContext } from 'react';

function AddPlayButton(props) {
  const {
    setIsScouting,
    setPlayInsertPosition,
    setSelectedPlayId,
  } = useContext(ScoutingStatusContext);

  const handleAddPlay = () => {
    setIsScouting(true);
    setPlayInsertPosition(props.insertPosition);
    setSelectedPlayId(props.selectedPlayId);
  };

  return (
    <Fab
      className="ignore-unselect-playCard"
      onClick={handleAddPlay}
      size="medium"
      sx={{
        position: 'absolute',
        top: props.insertPosition === 'before' ? '-40px' : 'none',
        bottom: props.insertPosition === 'after' ? '-95px' : 'none',
        right: '50%',
        marginRight: '-24px',
        color: '#0796F2',
        background: '#ffffff',
      }}
    >
      <AddIcon />
    </Fab>
  );
}

export default AddPlayButton;
