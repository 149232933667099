import { apiSlice } from './apiSlice';

const coachSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    requestSuggestion: builder.mutation({
      query: ({ team, body }) => ({
        url: `/api/team/${team}/athletes/suggestion`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, match) => [
        { type: 'Matches', id: match },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useRequestSuggestionMutation,
} = coachSlice;
