import { Route, Routes } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { LiveMatchView } from 'pages';
import {
  LiveMatchTimeline,
  PageNotFound,
} from 'shared/components';
import { PlayTypeContext } from 'shared/contexts';

export function LiveRoutes() {
  const [playType, setPlayType] = useState('');
  const contextValue = useMemo(() => ({ playType, setPlayType }), [playType, setPlayType]);

  return (
    <PlayTypeContext.Provider value={contextValue}>
      <Routes>
        <Route path="*" element={<PageNotFound />} />

        <Route path="/match/:matchId" element={<LiveMatchView />}>
          <Route index element={<LiveMatchTimeline />} />
        </Route>
      </Routes>
    </PlayTypeContext.Provider>
  );
}
