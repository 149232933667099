import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LanguageIcon from '@mui/icons-material/Language';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import {
  AppBar,
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import React, {
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconTeam, Logo } from 'shared/components';
import { logout } from 'shared/services';
import { useLanguageManager } from 'shared/hooks';
import { UserContext } from 'shared/contexts';

export function HeaderLogged(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const logoutUser = () => {
    logout();
    navigate('/login');
  };

  const [open, setOpen] = useState(false);
  const [insideItemOpen, setInsideItemOpen] = useState(false);
  const version = process.env.REACT_APP_VERSION;
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const { handleLanguageChange } = useLanguageManager();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleInsideItemClick = () => {
    setInsideItemOpen(!insideItemOpen);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          width: '100%',
          minHeight: '208px',
          padding: '0 20px',
          background: 'linear-gradient(137.13deg, #004D99 31.91%, #0080FF 140.67%)',
          color: '#ffffff',
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <Toolbar
          sx={{
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ position: 'absolute', left: '10px', ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{ flexGrow: 1, textAlign: 'center' }}
          >
            <Logo variant="h5" color="#ffffff" />
          </Box>
        </Toolbar>

        <Typography
          sx={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '20px',
            lineHeight: '24px',
            color: '#FFFFFF',
          }}
        >
          {props.title}
        </Typography>

      </AppBar>

      <Drawer
        sx={{
          width: '80vw',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '80vw',
            boxSizing: 'border-box',
          },
        }}
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: 1,
            justifyContent: 'flex-end',
          }}
        >
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </Box>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} href="https://linktr.ee/flag.stats" target="_blank">
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <EmailOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={t('Contato')} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} href="https://billing.stripe.com/p/login/cN24ii4Ezb2d8BaaEE" target="_blank">
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <PaidOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={t('Assinatura')} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} href={`https://site.flagstats.app/${t('pt/politica-de-privacidade')}`} target="_blank">
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <PolicyOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={t('Politica de privacidade')} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} href={`/team/${props.team._id}/edit`}>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <IconTeam size="24" />
              </ListItemIcon>
              <ListItemText primary={t('Time')} />
            </ListItemButton>
          </ListItem>
          <ListItemButton onClick={handleInsideItemClick}>
            <ListItemIcon sx={{ minWidth: '30px' }}>
              <LanguageIcon />
            </ListItemIcon>
            <ListItemText primary={t('Idioma')} />
            {insideItemOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={insideItemOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} onClick={() => handleLanguageChange(user, 'en')}>
                <ListItemText primary={t('Inglês')} />
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }} onClick={() => handleLanguageChange(user, 'es')}>
                <ListItemText primary={t('Espanhol')} />
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }} onClick={() => handleLanguageChange(user, 'pt')}>
                <ListItemText primary={t('Português')} />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton onClick={logoutUser}>
            <ListItemIcon sx={{ minWidth: '30px' }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </List>
        <Divider />
        <List style={{ marginTop: 'auto' }}>
          <ListItem>
            <ListItemText>
              {`${t('Versão')}: ${version}`}
              {environment !== 'Production' ? ` - ${environment}` : ''}
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}

export default HeaderLogged;
