import { Route, Routes } from 'react-router-dom';
import { Home } from 'pages/';
import {
  PageNotFound,
  Team,
  TeamListScrimmageMatches,
  TeamListPractice,
  TeamListPracticeMatches,
  TeamListTournamentMatches,
  TeamListTournaments,
} from 'shared/components';

export function TeamRoutes() {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route path=":team/edit" element={<Team />} />
      <Route path=":team/" element={<Home />}>
        <Route index element={<TeamListTournaments />} />
        <Route path="stats/tournaments" element={<TeamListTournaments />} />
        <Route path="stats/scrimmage" element={<TeamListScrimmageMatches />} />
        <Route path="stats/practice" element={<TeamListPractice />} />
        <Route path="stats/tournaments/:tournamentId" element={<TeamListTournamentMatches />} />
        <Route path="stats/practice/matches" element={<TeamListPracticeMatches />} />
      </Route>
    </Routes>
  );
}
