import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUpdateLiveMutation } from 'shared/services';

export function BroadcastDialog({
  openBroadcastDialog,
  handleClose,
  team,
  match,
}) {
  const { t } = useTranslation();
  const [setState] = useState({
    redirect: false,
  });
  const matchId = match?._id;
  const [updateLive] = useUpdateLiveMutation();

  const handleUpdateLiveMatch = async (publicBroadcast) => {
    const teamId = team._id;
    if (!matchId || !teamId) {
      setState((prevState) => ({ ...prevState, error: t('Partida não encontrada.') }));
    } else {
      await updateLive({
        team: teamId,
        match: match._id,
        body: {
          match: matchId,
          publicBroadcast,
        },
      });
    }
  };

  return (
    <Dialog
      open={openBroadcastDialog}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {t('Transmissão play-by-play')}
      </DialogTitle>
      <DialogContent>
        <p>{t('Ao ativar uma transmissão "play by play" o placar e a timeline de jogadas desta partida serão tornados públicos.')}</p>
        <p>{t('As estatísticas de atletas, do time e da partida não serão públicas.')}</p>
        {match?.ended
          && !match?.publicBroadcast
          && <p>{t('A timeline do jogo não será compartilhada quando ele for finalizado, mas o placar continuará público.')}</p>}
        <TextField
          id="broadcastUrl"
          label={t('URL')}
          readOnly="readonly"
          value={`${process.env.REACT_APP_APP_URL}/live/match/${match._id}`}
          margin="normal"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} variant="outlined" size="small">
          {t('Cancelar')}
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => handleUpdateLiveMatch(!match.publicBroadcast)}
        >
          {match.publicBroadcast === true
            ? t('Desativar transmissão')
            : t('Ativar transmissão')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BroadcastDialog;
