import { apiSlice } from './apiSlice';

const liveSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchLiveTeam: builder.query({
      query: ({ match }) => `/api/live/match/${match}/team`,
      providesTags: () => [{ type: 'Teams', id: 'team' }],
    }),
    fetchLiveMatch: builder.query({
      query: ({ match }) => `/api/live/match/${match}`,
      providesTags: (result, error, match) => [{ type: 'Matches', id: match }],
    }),
    fetchLivePlays: builder.query({
      query: ({ match }) => `/api/live/match/${match}/plays`,
      providesTags: (result, error, match) => [{ type: 'Plays', id: match }],
    }),
    fetchLiveAthletes: builder.query({
      query: ({ match }) => `/api/live/match/${match}/athletes`,
      providesTags: () => [{ type: 'Athletes', id: 'all' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchLiveTeamQuery,
  useFetchLiveMatchQuery,
  useFetchLivePlaysQuery,
  useFetchLiveAthletesQuery,
} = liveSlice;
