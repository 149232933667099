import './MatchTimeline.css';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Fab,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import { DateTime } from 'luxon';
import {
  useContext,
  useState,
  useLayoutEffect,
  useEffect,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {
  EventCard,
  IconClose,
  Loading,
  PlayCard,
} from 'shared/components';
import { PlayTypeContext, TeamContext, ScoutingStatusContext } from 'shared/contexts';
import { useFetchAthletesQuery, useFetchPlaysQuery, useUpdateMatchMutation } from 'shared/services';

export function MatchTimeline() {
  const team = useContext(TeamContext);
  const { t } = useTranslation();
  const [match] = useOutletContext();
  const plays = useFetchPlaysQuery({ team: team._id, match: match._id });
  const athletes = useFetchAthletesQuery(team._id);
  const isEnded = !!((match && match.ended));
  const [updateMatch] = useUpdateMatchMutation();
  const navigate = useNavigate();
  const { setPlayType } = useContext(PlayTypeContext);
  const {
    isScouting,
    setIsScouting,
    setPlayInsertPosition,
    setSelectedPlayId,
  } = useContext(ScoutingStatusContext);

  let content;
  let matchTime;
  const [state, setState] = useState({
    open: false,
    cardSelectedId: false,
  });
  const handleOpen = () => setState((prevState) => ({ ...prevState, open: true }));
  const handleClose = () => setState((prevState) => ({ ...prevState, open: false }));
  const handleDefenseClick = () => {
    setPlayType('defense');
    navigate(`/matches/${match._id}/play`);
  };
  const handleOffenseClick = () => {
    setPlayType('offense');
    navigate(`/matches/${match._id}/play`);
  };
  let showDefenseOfenseLabel = false;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleScoutingChange = useCallback((value) => {
    setIsScouting(value);
    setPlayInsertPosition(false);
    setSelectedPlayId(false);
  }, [setIsScouting, setPlayInsertPosition, setSelectedPlayId]);

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  };

  const handleSelectCard = (id) => {
    setState((prevState) => ({ ...prevState, cardSelectedId: id }));
  };

  useEffect(() => {
    const handleBodyClick = (event) => {
      if (
        event.target.closest('.ignore-unselect-playCard')
      ) return;
      setState((prevState) => ({ ...prevState, cardSelectedId: null }));
      handleScoutingChange(false);
    };

    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, [handleScoutingChange]);

  useLayoutEffect(() => {
    if (plays.isSuccess && match?.started) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }
  }, [plays, match?.started]);

  const gradientColor = 'rgba(255, 255, 255, 0.8)';
  const gradient = `radial-gradient(circle at top center, ${gradientColor} 0%, rgba(255, 255, 255, 0) 100%)`;
  const scrollTopButton = (
    <Box
      onClick={handleClick}
      role="presentation"
      sx={{
        position: 'fixed',
        bottom: isEnded ? 136 : 230,
        right: 16,
        zIndex: 4,
      }}
    >
      <Fab size="small" aria-label="scroll back to top" sx={{ background: `${gradient}, ${gradientColor}` }}>
        <KeyboardArrowUpIcon />
      </Fab>
    </Box>
  );

  const fadeContent = (
    <Fade in={trigger}>
      {scrollTopButton}
    </Fade>
  );

  const modalStyle = {
    position: 'absolute',
    width: '80%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#fff',
    boxShadow: '0px 10px 20px rgba(43, 37, 63, 0.1)',
    borderRadius: '4px',
    p: 4,
    textAlign: 'center',
  };

  if (plays.isLoading || athletes.isLoading) {
    content = <Loading />;
  } else if (plays.isError || athletes.isError) {
    content = (
      <Container component="main" maxWidth="xs">
        {plays.error}
        {' '}
        {athletes.error}
      </Container>
    );
  } else if (match && plays.isSuccess && athletes.isSuccess && !match?.started) {
    const startGame = async () => {
      const updatedMatch = JSON.parse(JSON.stringify(match));
      updatedMatch.time = {
        first_time: { start: DateTime.now().toJSDate(), end: null },
        second_time: { start: null, end: null },
      };
      const teamId = team._id;
      setIsScouting(true);
      await updateMatch({ team: teamId, body: updatedMatch });
    };

    content = (
      <>
        <Box
          sx={{
            height: '45vh',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body2"
            textAlign="center"
            sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '24px',
              textAlign: 'center',
              color: '#3E4A59',
            }}
          >
            {t('Jogo não iniciado')}
          </Typography>
          <Typography
            variant="body2"
            textAlign="center"
            sx={{
              fontFamily: 'Mulish',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '24px',
              textAlign: 'center',
              color: '#50729A',
            }}
          >
            {t('Inicie o tempo para registrar as jogadas')}
          </Typography>
        </Box>

        {match
          && (
            <Button
              fullWidth
              className="ignore-unselect-playCard"
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 5 }}
              onClick={startGame}
            >
              {t('Iniciar Jogo')}
            </Button>
          )}
      </>
    );
  } else if (match && plays.isSuccess && athletes.isSuccess) {
    const matchIsRunning = (
      match?.time?.first_time?.start
      && !match.time?.first_time?.end)
      || (match?.time?.second_time?.start
        && !match.time?.second_time?.end);

    showDefenseOfenseLabel = plays.data.length > 0 ?? true;

    const triggerUpdateMatchTime = async () => {
      const updatedMatch = JSON.parse(JSON.stringify(match));

      if (updatedMatch.current_time === 1) {
        updatedMatch.time.first_time.end = DateTime.now().toJSDate();
      } else {
        updatedMatch.time.second_time.end = DateTime.now().toJSDate();
      }
      await updateMatch({ team: team._id, body: updatedMatch });
      handleClose();
    };

    const triggerStartSecondTime = async () => {
      const updatedMatch = JSON.parse(JSON.stringify(match));
      updatedMatch.time.second_time.start = DateTime.now().toJSDate();

      const result = await updateMatch({ team: team._id, body: updatedMatch });
      if (result?.data?.success) {
        setIsScouting(true);
      }
    };

    content = (
      <Container component="main" maxWidth="xs" sx={{ marginBottom: '180px', flex: 1 }}>
        <div className="playcards-list">
          <div className="playcardlist-header" />
          {plays.data.map((play, index) => {
            const playCard = (
              <PlayCard
                className={play.defense ? 'defense' : 'offense'}
                key={play._id}
                play={play}
                athletes={athletes.data}
                cardSelectedId={state.cardSelectedId}
                handleSelectCard={handleSelectCard}
                shouldShowAddPlayButton={index !== plays.data.length - 1 || isEnded}
              />
            );
            if (matchTime !== play.match_time) {
              matchTime = play.match_time;
              const eventCard = <EventCard key={`event-${play._id}`} label={`${t('Início do')} ${t(`${matchTime}º tempo`)}`} icon="watch" />;
              return [eventCard, playCard];
            }
            return playCard;
          })}
          {isEnded
            && <EventCard label={t('Fim de jogo')} icon="wistle" />}
        </div>
        {!isEnded
          && (
            <Box
              sx={{
                width: '100%',
                position: 'fixed',
                bottom: '0',
                left: '0',
                padding: '35px 20px 20px 20px',
                margin: '0',
                backgroundImage: 'linear-gradient(transparent, white 14%)',
                zIndex: '3',
              }}
            >
              {matchIsRunning
                && !isScouting
                && (
                  <>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={handleOpen}
                      sx={{
                        marginBottom: '10px',
                      }}
                    >
                      {t('Encerrar')}
                      {' '}
                      {t(`${match.current_time}º tempo`)}
                    </Button>

                    <Button
                      className="ignore-unselect-playCard"
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => handleScoutingChange(true)}
                    >
                      {t('Continuar')}
                      {' '}
                      {t(`${match.current_time}º tempo`)}
                    </Button>
                  </>
                )}

              {!matchIsRunning && !match.time.second_time.start
                && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={triggerStartSecondTime}
                  >
                    {t('Começar 2º tempo')}
                  </Button>
                )}
            </Box>
          )}
        <Modal
          open={state.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t('Deseja finalizar o tempo?')}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {t('Esta ação é irreversivel!')}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={triggerUpdateMatchTime}
              sx={{
                mt: '20px',
                mb: '10px',
              }}
            >
              {t('Finalizar tempo')}
            </Button>

            <Button
              variant="text"
              color="primary"
              onClick={handleClose}
            >
              {t('Cancelar')}
            </Button>
          </Box>
        </Modal>
        {isScouting
          && (
            <Card
              sx={{
                position: 'fixed',
                width: '100%',
                height: '152px',
                marginLeft: 'auto',
                marginRight: 'auto',
                left: '0',
                right: '0',
                textAlign: 'center',
                bottom: '0',
                background: '#FFFFFF',
                boxShadow: '0px 0px 30px rgba(51, 48, 62, 0.2)',
                borderRadius: '10px 10px 0 0',
                zIndex: '3',
              }}
            >
              <CardHeader
                sx={{
                  fontSize: '14px',
                  padding: '8px 16px',
                }}
                action={(
                  <Box
                    sx={{
                      margin: '16px',
                    }}
                  >
                    <IconButton
                      aria-label="settings"
                      onClick={() => handleScoutingChange(false)}
                    >
                      <IconClose />
                    </IconButton>
                  </Box>
                            )}
                title={(
                  <Typography
                    sx={{
                      fontFamily: 'Montserrat',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      fontSize: '16px',
                      lineHeight: '24px',
                      textAlign: 'left',
                      marginLeft: '17px',
                      marginTop: '16px',
                      color: '#3E4A59',
                    }}
                  >
                    {t('Escolher jogada')}
                  </Typography>
                            )}
              />
              <CardContent
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  margin: '8px 16px',
                  marginTop: '0',
                  padding: '0',
                }}
              >
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  sx={{
                    width: '160px',
                    height: '56px',
                  }}
                  onClick={handleOffenseClick}
                >
                  {t('Ataque')}
                </Button>

                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  sx={{
                    width: '160px',
                    height: '56px',
                  }}
                  onClick={handleDefenseClick}
                >
                  {t('Defesa')}
                </Button>
              </CardContent>
            </Card>
          )}
      </Container>
    );
  }

  return (
    <>
      <Grid
        id="back-to-top-anchor"
        sx={{
          height: '100%', marginTop: '20px', marginBottom: '20px', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around',
        }}
      >
        {showDefenseOfenseLabel
          && (
            <>
              <Grid item>
                <Typography component="h3" variant="subtitle">{t('Ataque')}</Typography>
              </Grid>
              <Grid item>
                <Typography component="h3" variant="subtitle">{t('Defesa')}</Typography>
              </Grid>

            </>
          )}
      </Grid>
      {content}
      {fadeContent}
    </>
  );
}

export default MatchTimeline;
