export const SuggestionQuestions = [
  {
    id: 'teamType',
    label: 'Qual é o time?',
    field: 'tryType',
    visible: true,
    actions: [
      { label: 'Ataque', value: 'offense', next: 'tryType' },
      { label: 'Defesa', value: 'defense', next: 'tryType' },
    ],
  },
  {
    id: 'tryType',
    label: 'Qual é a tentativa?',
    field: 'tryType',
    visible: false,
    actions: [
      { label: 'Meio', value: 'is_try_first', next: 'down' },
      { label: 'Touchdown', value: 'is_try_td', next: 'down' },
    ],
  },
  {
    id: 'down',
    label: 'Qual é a descida?',
    field: 'down',
    visible: false,
    actions: [
      { label: '1', value: 1, next: 'yards' },
      { label: '2', value: 2, next: 'yards' },
      { label: '3', value: 3, next: 'yards' },
      { label: '4', value: 4, next: 'yards' },
    ],
  },
  {
    id: 'yards',
    label: 'Quantas jardas para o objetivo?',
    field: 'given_yards',
    visible: false,
    actions: [
      { type: 'number', value: '', next: 'save' },
    ],
    type: 'yards',
  },
];
