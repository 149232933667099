import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, IconStats, Loading } from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { useFetchAthleteStatsByMatchQuery } from 'shared/services';

function AthletePracticeStatsCard(props) {
  const team = useContext(TeamContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data: stats,
  } = useFetchAthleteStatsByMatchQuery({
    team: team._id, athlete: props.athlete._id, match: props.match._id,
  });

  const resumeTitleStyle = {
    fontFamily: 'Mulish',
    fontWeight: '400',
    lineHeight: '24px',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    color: '#50729A',
  };

  const resumeValueStyle = {
    fontFamily: 'Mulish',
    fontWeight: '800',
    lineHeight: '24px',
    fontSize: '24px',
    display: 'flex',
    alignItems: 'center',
    color: '#3E4A59',
  };

  const headerTextStyle = {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWweight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#9DB3CC',
  };

  let content;
  let formatedDate;

  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <ErrorMessage messages={error.data.messages} />;
  } else if (isSuccess) {
    formatedDate = stats?.date ? DateTime.fromISO(stats.date).toFormat('dd/LL ・ HH:mm ・ ') : '';

    const defenseStats = (
      <Box sx={{
        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '5px 10px', padding: '5px 10px',
      }}
      >
        <Box>
          <Typography variant="subtitle2" sx={resumeTitleStyle}>
            Tackles
          </Typography>
          <Typography variant="body1" sx={{ ...resumeTitleStyle, ...resumeValueStyle }}>
            {stats?.defense?.end_plays?.tackle || 0}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={resumeTitleStyle}>
            {t('Interceptações')}
          </Typography>
          <Typography variant="body1" sx={{ ...resumeTitleStyle, ...resumeValueStyle }}>
            {stats?.defense?.end_plays?.intercept || 0}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={resumeTitleStyle}>
            Sacks
          </Typography>
          <Typography variant="body1" sx={{ ...resumeTitleStyle, ...resumeValueStyle }}>
            {stats?.defense?.end_plays?.sack || 0}
          </Typography>
        </Box>
      </Box>
    );

    const offenseStats = (
      <Box sx={{
        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '5px 10px', padding: '5px 10px',
      }}
      >
        <Box>
          <Typography variant="subtitle2" sx={resumeTitleStyle}>
            touchdowns
          </Typography>
          <Typography variant="body1" sx={{ ...resumeTitleStyle, ...resumeValueStyle }}>
            {stats?.offense?.td || 0}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={resumeTitleStyle}>
            First
          </Typography>
          <Typography variant="body1" sx={{ ...resumeTitleStyle, ...resumeValueStyle }}>
            {stats?.offense?.first || 0}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={resumeTitleStyle}>
            {t('Jardas')}
          </Typography>
          <Typography variant="body1" sx={{ ...resumeTitleStyle, ...resumeValueStyle }}>
            {stats?.offense?.yards || 0}
          </Typography>
        </Box>
      </Box>
    );

    content = (
      <>
        {props.athlete.offense && offenseStats}
        {props.athlete.defense && defenseStats}
      </>
    );
  }

  return (
    <Card sx={{ margin: '20px 0' }}>
      <CardHeader
        title={(
          <Typography variant="body1" sx={{ ...resumeTitleStyle, ...headerTextStyle }}>
            {formatedDate}
            {props.match.local}
          </Typography>
)}
      />
      <CardContent sx={{ paddingTop: '4px' }}>
        {content}
      </CardContent>
      <Divider />
      <CardActions
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <Button
          onClick={() => navigate(`/athletes/${props.athlete._id}/stats/match/${props.match._id}`)}
          sx={{ height: '32px' }}
        >
          <IconStats color="#0066CC" />
          <Typography
            variant="body2"
            sx={{
              display: 'inline-block',
              fontFamily: 'Mulish',
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: '14px',
              lineHeight: '24px',
              marginLeft: '5px',
            }}
          >
            {t('Ver Estatísticas')}
          </Typography>
        </Button>
      </CardActions>
    </Card>
  );
}

export default AthletePracticeStatsCard;
