import React from 'react';
import { Box, Typography } from '@mui/material';
import i18n from 'lang/i18n';

export function LanguageSwitcher(props) {
  const languages = ['en', 'pt', 'es'];

  const currentLanguage = i18n.language || 'en';

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        position: 'absolute',
        top: '17px',
        left: '50%',
        transform: 'translateX(-50%)',
        padding: 1,
        zIndex: 10,
      }}
    >
      {languages.map((locale, index) => (
        <React.Fragment key={locale}>
          <Typography
            sx={{
              mx: 1,
              fontWeight: currentLanguage === locale ? 'bold' : 'normal',
              cursor: 'pointer',
              color: props.color,
            }}
            onClick={() => i18n.changeLanguage(locale)}
          >
            {locale.toUpperCase()}
          </Typography>
          {index < languages.length - 1 && (
            <Typography
              sx={{
                mx: 1,
                color: props.color,
              }}
            >
              |
            </Typography>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
}

export default LanguageSwitcher;
