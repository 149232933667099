import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import {
  AthleteStatsResumeCard,
  ErrorMessage,
  Loading,
  MatchCard,
} from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { useFetchAthleteMatchesByTypeQuery, useFetchAthleteResumeStatsByTypeQuery } from 'shared/services';

export function AthleteListScrimmageMatches() {
  const team = useContext(TeamContext);
  const [athlete] = useOutletContext();
  const { t } = useTranslation();

  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data: scrimmageMatches,
  } = useFetchAthleteMatchesByTypeQuery({ team: team._id, athlete: athlete._id, type: 'scrimmage' });

  const resumeStats = useFetchAthleteResumeStatsByTypeQuery({ team: team._id, athlete: athlete._id, type: 'scrimmage' });

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <ErrorMessage messages={error.data.messages} />;
  } else if (isSuccess) {
    content = scrimmageMatches.map((scrimmage) => <MatchCard key={`match-card-${scrimmage._id}`} match={scrimmage} athleteId={athlete._id} linkTo={`/athletes/${athlete._id}/stats/match/${scrimmage._id}`} actionLabel={t('Ver Estatísticas')} showIcon />);
  }

  return (
    <>
      {
        resumeStats.isSuccess
        && <AthleteStatsResumeCard stats={resumeStats.data.stats} athlete={athlete} />
      }
      {content}
    </>
  );
}

export default AthleteListScrimmageMatches;
