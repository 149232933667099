import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie';
import en from './locales/en.json';
import es from './locales/es.json';

const getInitialLanguage = () => {
  const cookieLang = Cookies.get('i18next');
  if (cookieLang) {
    return cookieLang;
  }

  const browserLang = navigator.language || navigator.userLanguage || 'en';
  Cookies.set('i18next', browserLang, { expires: 30 });
  return browserLang;
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en,
      es,
      pt: {},
    },
    lng: getInitialLanguage(),
    fallbackLng: {
      pt: ['pt'],
      default: ['en'],
    },
    detection: {
      order: ['cookie', 'navigator'],
      caches: ['cookie'],
    },
  });

i18n.on('languageChanged', (lng) => {
  Cookies.set('i18next', lng, { expires: 30 });
});

export default i18n;
