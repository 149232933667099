import { Button, Container } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyPage } from 'pages';
import { Loading, MatchCard } from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { Footer, GenericHeader } from 'shared/layouts';
import { useFetchMatchesByTypeQuery } from 'shared/services';
import { csvDownloadHandler } from 'shared/helpers';

export function StatsListPracticeMatches() {
  const team = useContext(TeamContext);
  const { t } = useTranslation();
  const {
    isLoading,
    isSuccess,
    isError,
    data: matches,
  } = useFetchMatchesByTypeQuery({ team: team._id, type: 'practice' }, { refetchOnMountOrArgChange: true });

  const handleExport = async () => {
    try {
      const url = `/api/team/${team._id}/matches/practice/stats/export`;

      await csvDownloadHandler(url, 'practice_stats.csv');
    } catch (error) {
      console.error('Erro ao exportar CSV:', error);
    }
  };

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <EmptyPage title={t('Ainda não há informações')} content={t('Adicione um novo Jogo de Campeonato, Amistoso ou Treino no botão Criar.')} />;
  } else if (isSuccess) {
    content = matches.map((match) => <MatchCard key={`match-card-${match._id}`} match={match} linkTo={`/matches/${match._id}/stats`} actionLabel={t('Ver Estatísticas')} showIcon />);
  }

  return (
    <div id="stats">
      <GenericHeader title={t('Treino')} linkBack={`/stats/${team._id}`} />
      <Container component="main" maxWidth="xs" className="home" sx={{ marginTop: '40px', paddingBottom: '110px' }}>
        <Button
          className="ignore-unselect-playCard"
          variant="outlined"
          color="primary"
          fullWidth
          onClick={handleExport}
        >
          {t('Exportar estatísticas')}
        </Button>
        {content}
      </Container>
      <Footer />
    </div>
  );
}

export default StatsListPracticeMatches;
