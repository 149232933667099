import { Route, Routes } from 'react-router-dom';
import {
  PageNotFound,
  RegisterSuccessHandBlueBg,
  RegisterAthleteForm,
  RegisterAthleteList,
} from 'shared/components';

export function RegisterRoutes() {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route path="athletes/form" element={<RegisterAthleteForm redirect="/register/athletes" statusBar />} />
      <Route path="athletes" element={<RegisterAthleteList />} />
      <Route path="success" element={<RegisterSuccessHandBlueBg />} />
    </Routes>
  );
}
