import { Box, Typography } from '@mui/material';
import { DateTime, Interval } from 'luxon';
import React from 'react';
import { Link } from 'react-router-dom';
import { IconClose, IconStopWatch } from 'shared/components';

export function PlayHeader(props) {
  const { match } = props;
  let formatedTime = '00 : 00 : 00';
  if (match?.time) {
    let startTime;
    if (match?.time?.second_time?.start) {
      startTime = DateTime.fromISO(match?.time?.second_time?.start);
    } else {
      startTime = DateTime.fromISO(match?.time?.first_time?.start);
    }
    const interval = Interval.fromDateTimes(startTime, DateTime.now());
    formatedTime = interval.toDuration(['hours', 'minutes', 'seconds']).toFormat('hh : mm : ss');
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '150px',
        padding: '20px',
        background: '#FFFFFF',
        color: '#3E4A59',
        textAlign: 'center',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: '20px',
          top: '20px',
        }}
      >
        <Link
          to={`/matches/${match._id}/timeline`}
        >
          <IconClose color="#3E4A59" />
        </Link>
      </Box>
      <Typography
        variant="subtitle2"
        align="left"
        sx={{
          marginLeft: '30px',
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '18px',
          lineHeight: '24px',
          color: '#3E4A59',
        }}
      >
        {props?.label}
      </Typography>
      <Box
        sx={{
          position: 'absolute',
          right: '20px',
          top: '20px',
        }}
      >
        <Box
          sx={{
            width: '120px',
            height: '32px',
            background: '#EFF3F7',
            opacity: '0.64',
            borderRadius: '4px',
            boxSizing: 'border-box',
            padding: '5px 10px',
          }}
        >
          <Box
            sx={{
              fontFamily: 'Mulish',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '24px',
              color: '#50729A',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <IconStopWatch />
            <span>{formatedTime}</span>
          </Box>

        </Box>
      </Box>
    </Box>
  );
}

export default PlayHeader;
