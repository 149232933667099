import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  IconLocal, IconStats, IconTeamDefault, IconScrimmage,
  IconTournament, IconWistle,
} from 'shared/components';

export function TeamScheduledMatchCard(props) {
  const { t } = useTranslation();
  const headerTextStyle = {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWweight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#9DB3CC',
  };

  const dateForm = t('dd-LL-yyyy');
  const formatedDate = props?.match?.date ? DateTime.fromISO(props.match.date).toFormat(`${dateForm} ・ HH:mm`) : '';

  let cardHeaderLabel;
  switch (props.match.type) {
    case 'tournament':
      cardHeaderLabel = (
        <>
          <IconTournament />
          <Typography
            sx={{
              marginLeft: '8px',
              maxWidth: '120px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {props.label}
          </Typography>
        </>
      );
      break;
    case 'scrimmage':
      cardHeaderLabel = (
        <>
          <IconScrimmage customSize="16" />
          <Typography
            sx={{
              marginLeft: '8px',
              maxWidth: '120px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {t('Amistoso')}
          </Typography>
        </>
      );
      break;
    case 'practice':
      cardHeaderLabel = (
        <>
          <IconWistle customSize="16" />
          <Typography
            sx={{
              marginLeft: '8px',
              maxWidth: '120px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {t('Treino')}
          </Typography>
        </>
      );
      break;
    default:
      cardHeaderLabel = (
        <>
          <IconWistle customSize="16" />
          <Typography
            sx={{
              marginLeft: '8px',
              maxWidth: '120px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {t('Treino')}
          </Typography>
        </>
      );
      break;
  }

  const action = (
    <CardActions>
      <Link to={`/matches/${props.match._id}`}>
        <Button size="small">
          <IconStats color="#0066CC" />
          <Typography
            variant="body2"
            sx={{
              display: 'inline-block',
              fontFamily: 'Mulish',
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: '14px',
              lineHeight: '24px',
              marginLeft: '5px',
            }}
          >
            {t('Detalhes')}
          </Typography>
        </Button>
      </Link>
    </CardActions>
  );

  const content = (
    <>
      <Grid container sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Grid item xs={3} md={3}>
          {props.match.type !== 'practice'
            && (
              <Avatar
                sx={{
                  width: '24px',
                  height: '24px',
                }}
                alt=""
                src="/broken-image.jpg"
              >
                <IconTeamDefault />
              </Avatar>
            )}
        </Grid>
        <Grid item xs={9} md={9}>
          <Typography
            variant="subtitle2"
            align="left"
            sx={{
              fontFamily: 'Mulish',
              fontWeight: '600',
              lineHeight: '130%',
              textAlign: 'left',
              fontSize: '14px',
              color: '#3E4A59',
              maxWidth: '120px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {props.match?._id ? props.match?.adversary : t('Adversário')}
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          paddingTop: '15px',
          paddingBottom: '0',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            ...headerTextStyle,
            color: '#50729A',
          }}
        >
          {formatedDate}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          paddingTop: '4px',
          paddingBottom: '0',
        }}
      >
        <IconLocal />
        <Box
          sx={{
            marginLeft: '8px',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              ...headerTextStyle,
              maxWidth: '150px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {props.match?.local}
          </Typography>
        </Box>
      </Box>

    </>
  );

  return (
    <Card sx={{
      margin: '20px 0', width: '184px', height: '210px', position: 'relative',
    }}
    >
      <CardHeader
        style={{ textAlign: 'center' }}
        title={(
          <Box
            sx={{
              fontFamily: 'Mulish',
              width: 'fit-content',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '24px',
              color: '#50729A',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              height: '24px',
              background: '#eff3f7',
              borderRadius: '4px',
              padding: '0 8px',
            }}
          >
            {cardHeaderLabel}
          </Box>
        )}
      />
      <CardContent sx={{ paddingTop: '4px' }}>
        {content}
        {action}
      </CardContent>
    </Card>
  );
}

export default TeamScheduledMatchCard;
