import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { EmptyPage } from 'pages';
import { Loading, PracticeStatsCard } from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { useFetchTeamResumeStatsByTypeQuery } from 'shared/services';

// @todo: Criar fetch de stats por tipo de jogo (treino)
export function StatsListPractice() {
  const team = useContext(TeamContext);
  const { t } = useTranslation();
  const {
    isLoading,
    isSuccess,
    isError,
    data: practiceStats,
  } = useFetchTeamResumeStatsByTypeQuery({ team: team._id, type: 'practice' }, { refetchOnMountOrArgChange: true });

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <EmptyPage title={t('Ainda não há informações')} content={t('Adicione um novo Jogo de Campeonato, Amistoso ou Treino no botão Criar.')} />;
  } else if (isSuccess && practiceStats.matches > 0) {
    content = <PracticeStatsCard matches={practiceStats.matches} stats={practiceStats.stats} />;
  } else {
    content = <EmptyPage title={t('Ainda não há informações')} content={t('Adicione um novo Jogo de Campeonato, Amistoso ou Treino no botão Criar.')} />;
  }

  return (
    <Box>
      {content}
    </Box>
  );
}

export default StatsListPractice;
