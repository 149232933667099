import { Route, Routes } from 'react-router-dom';
import { Coach } from 'shared/components';

export function CoachRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Coach />} />
    </Routes>
  );
}
