import { Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

function MatchTypeMenu(props) {
  const location = useLocation();
  const { color } = props;
  const activeColor = props.activeColor ?? '#50729A';
  const tournamentActive = !(location.pathname.split('/').length > 3);
  const listActiveStyle = {
    borderBottom: `2px solid ${color}`, color: `${color}`, padding: '10px', boxSizing: 'border-box',
  };
  const listStyle = { color: activeColor, padding: '10px', boxSizing: 'border-box' };
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-around',
        }}
      >
        <NavLink
          to={props.tournamentLink}
          style={({ isActive }) => (isActive || tournamentActive ? listActiveStyle : listStyle)}
        >
          {t('Campeonatos')}
        </NavLink>
        <NavLink
          to={props.scrimmageLink}
          style={({ isActive }) => (isActive ? listActiveStyle : listStyle)}
        >
          {t('Amistosos')}
        </NavLink>
        <NavLink
          to={props.practiceLink}
          style={({ isActive }) => (isActive ? listActiveStyle : listStyle)}
        >
          {t('Treino')}
        </NavLink>
      </Box>
      <Divider />

    </>
  );
}

export default MatchTypeMenu;
