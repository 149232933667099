import React, { createContext, useState, useMemo } from 'react';

export const ScoutingStatusContext = createContext();

export function ScoutingStatusProvider({ children }) {
  const [isScouting, setIsScouting] = useState('');
  const [playInsertPosition, setPlayInsertPosition] = useState('');
  const [selectedPlayId, setSelectedPlayId] = useState('');

  const contextValue = useMemo(
    () => ({
      isScouting,
      setIsScouting,
      playInsertPosition,
      setPlayInsertPosition,
      selectedPlayId,
      setSelectedPlayId,
    }),
    [
      isScouting,
      setIsScouting,
      playInsertPosition,
      setPlayInsertPosition,
      selectedPlayId,
      setSelectedPlayId,
    ],
  );

  return (
    <ScoutingStatusContext.Provider value={contextValue}>
      {children}
    </ScoutingStatusContext.Provider>
  );
}
