import styled from '@emotion/styled';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  tableCellClasses,
  TableContainer,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CollapsableTableRow } from 'shared/components';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function AthleteStatsMatchTable(props) {
  const { t } = useTranslation();
  const [openOffense, setOpenOffense] = useState(false);
  const [openDefense, setOpenDefense] = useState(false);
  const { offense, defense } = props.stats;

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      color: '#3E4A59',
      lineHeight: '24px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const offenseTable = () => {
    function createData(name, value) {
      return { name, value };
    }

    const rows = [
      createData('Touchdowns', offense?.td || 0),
      createData('Firsts', offense?.first || 0),
    ];

    const collapsableRows = {
      qb: [
        createData(t('Passes tentados / Passes completos'), `${offense.qb.attempts} / ${offense.qb.complete}`),
        createData(t('Passes para conversão para meio'), offense.qb.first_conv),
        createData(t('Passes para touchdown'), offense.qb.td_conv),
        createData(t('Passes para conversão de XP 1pt'), offense.qb.xp_1pt_conv),
        createData(t('Passes para conversão de XP 2pt'), offense.qb.xp_2pt_conv),
      ],
      received_pass: [
        createData(t('Tentados / Completos'), `${offense.received_pass.attempts} / ${offense.received_pass.complete}`),
        createData(t('Jardas'), offense.received_pass.yards),
        createData(t('Conversão para meio'), offense.received_pass.first_conv),
        createData(t('Conversão para touchdown'), offense.received_pass.td_conv),
        createData(t('Conversão de XP 1pt'), offense.received_pass.xp_1pt_conv),
        createData(t('Conversão de XP 2pt'), offense.received_pass.xp_2pt_conv),
      ],
      runs: [
        createData(t('Tentados / Completos'), `${offense.runs.attempts} / ${offense.runs.complete}`),
        createData(t('Jardas'), offense.runs.yards),
        createData(t('Conversão para meio'), offense.runs.first_conv),
        createData(t('Conversão para touchdown'), offense.runs.td_conv),
        createData(t('Conversão de XP 1pt'), offense.runs.xp_1pt_conv),
        createData(t('Conversão de XP 2pt'), offense.runs.xp_2pt_conv),
      ],
    };

    const titleMap = {
      qb: t('Passes'),
      received_pass: t('Passes recebidos'),
      runs: t('Corrida'),
    };

    return (
      <Table sx={{ width: '100%', margin: '20px 0' }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell component="th" scope="row">
              <Typography
                variant="subtitle1"
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                }}
                aria-label="expand row"
                onClick={() => setOpenOffense(!openOffense)}
              >
                {
                  openOffense
                    ? <KeyboardArrowUpIcon sx={{ margin: '0 5px', verticalAlign: 'text-bottom' }} />
                    : <KeyboardArrowDownIcon sx={{ margin: '0 5px', verticalAlign: 'text-bottom' }} />
                }
                {t('Ataque')}
              </Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ padding: 0 }} colSpan={12}>
              <Collapse in={openOffense} timeout="auto" unmountOnExit>
                <Table aria-label="purchases">
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={uuidv4()}>
                        <StyledTableCell component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="right">{row.value}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                    {
                    Object.entries(collapsableRows).map(([key, collapsableRow]) => (
                      <CollapsableTableRow
                        key={key}
                        header={t(titleMap[key])}
                        rows={collapsableRow}
                      />
                    ))
                  }
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  const defenseTable = () => {
    function createData(name, value) {
      return { name, value };
    }

    const rows = [
      createData('Pick six', defense.pick_six || 0),
      createData('Tackle', defense.tackle || 0),
      createData('Tip', defense.tip || 0),
      createData(t('Interceptações'), defense.intercept || 0),
      createData('Sack', defense.sack || 0),
    ];

    return (
      <Table sx={{ width: '100%', margin: '20px 0' }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell component="th" scope="row">
              <Typography
                variant="subtitle1"
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                }}
                aria-label="expand row"
                onClick={() => setOpenDefense(!openDefense)}
              >
                {
                  openDefense
                    ? <KeyboardArrowUpIcon sx={{ margin: '0 5px', verticalAlign: 'text-bottom' }} />
                    : <KeyboardArrowDownIcon sx={{ margin: '0 5px', verticalAlign: 'text-bottom' }} />
                }
                {t('Defesa')}
              </Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ padding: 0 }} colSpan={12}>
              <Collapse in={openDefense} timeout="auto" unmountOnExit>
                <Table aria-label="purchases">
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={uuidv4()}>
                        <StyledTableCell component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="right">{row.value}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  const content = (
    <>
      {props.stats.offense && offenseTable()}
      {props.stats.defense && defenseTable()}
    </>
  );

  return (
    <TableContainer component={Paper} sx={{ flex: 1 }}>
      {content}
    </TableContainer>
  );
}

export default AthleteStatsMatchTable;
