import { Box, Typography } from '@mui/material';

import { IconTournament } from './Icons';

function TournamentLabel(props) {
  const { labelWidth } = props;
  return (
    <Box
      sx={{
        fontFamily: 'Mulish',
        width: 'fit-content',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#50729A',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '24px',
        background: '#eff3f7',
        borderRadius: '4px',
        padding: '0 8px',
      }}
    >
      <IconTournament />
      <Typography
        sx={{
          marginLeft: '8px',
          maxWidth: labelWidth,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {props.label}
      </Typography>
    </Box>
  );
}

export default TournamentLabel;
