import { Container } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  AthleteStatsResumeCard,
  ErrorMessage,
  Loading,
  ShareableMatchCard,
} from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { ShareableAthleteHeader } from 'shared/layouts';
import {
  useFetchAthleteQuery,
  useFetchAthleteResumeStatsByMatchQuery,
  useFetchMatchQuery,
} from 'shared/services';

export function AthleteShareMatchStats() {
  const team = useContext(TeamContext);
  const { athleteId, matchId } = useParams();
  const { t } = useTranslation();

  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data: resumeStats,
  } = useFetchAthleteResumeStatsByMatchQuery({
    team: team._id, athlete: athleteId, match: matchId,
  });

  const athlete = useFetchAthleteQuery({ team: team._id, athlete: athleteId });
  const match = useFetchMatchQuery({ team: team._id, match: matchId });

  let content;
  let matchContent;
  if (isLoading && match.isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <ErrorMessage messages={error.data.messages} />;
  } else if (isSuccess && match.isSuccess) {
    content = <AthleteStatsResumeCard stats={resumeStats.stats} athlete={athlete} />;
    matchContent = <ShareableMatchCard match={match.data} athleteId={athlete._id} actionLabel={t('Ver Estatísticas')} showIcon />;
  }

  return (
    <>
      {athlete.isSuccess && <ShareableAthleteHeader athlete={athlete.data} backto={`/athletes/${athleteId}/stats/match/${matchId}`} />}
      <Container component="main" maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column', minHeight: '70vh' }}>
        {matchContent}
        {content}
      </Container>
    </>
  );
}

export default AthleteShareMatchStats;
