import { apiSlice } from './apiSlice';

const userSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchUser: builder.query({
      query: () => '/api/user/me',
      providesTags: () => [{ type: 'User', id: 'me' }],
    }),
    updateUserLanguage: builder.mutation({
      query: (body) => ({
        url: '/api/user/language/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'User', id: 'me' }],
    }),
    updateUserOnboarding: builder.mutation({
      query: (body) => ({
        url: '/api/user/showonboarding/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'User', id: 'me' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchUserQuery,
  useUpdateUserLanguageMutation,
  useUpdateUserOnboardingMutation,
} = userSlice;
