import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { LoadingLogo } from './Icons';

export function Loading() {
  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(137.13deg, rgb(0, 77, 153) 31.91%, rgb(0, 128, 255) 140.67%)',
      }}
    >
      <LoadingLogo primaryColor="#fff" secondaryColor="#fff" />
      <CircularProgress sx={{ color: '#fff', marginTop: '15px' }} />
    </Box>
  );
}

export default Loading;
