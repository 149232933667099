import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

/*
Primary: #00685b
Light: #439688
Dark: #003d32
*/

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0066CC',
    },
    secondary: {
      main: '#ffffff',
      contrastText: '#004D99',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    button: {
      height: '48px',
      fontWeight: 'bold',
    },
    h6: {
      fontWeight: '900',
    },
  },
});

theme.custom = {
  gridAction: {
    paddingBottom: theme.spacing(8),
  },
  buttonFixed: {
    borderRadius: '0',
    position: 'fixed',
    bottom: '0',
  },
};

theme.overrides = {
  container: {
    a: {
      color: 'red',
    },
  },
  MuiFormControl: {
    root: {
      width: '100%',
    },
  },
  MuiFilledInput: {
    root: {
      borderRadius: '4px',
      '&$focused': {
        '&:after': {
          opacity: '1',
        },
      },
    },
    underline: {
      '&:before': {
        content: 'unset',
      },
      '&:after': {
        opacity: 0,
        transform: 'unset',
        transition: 'opacity 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        border: `1px solid ${theme.palette.primary.main}`,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        height: '56px',
        borderRadius: '4px',
      },
    },
  },
  MuiInputLabel: {
    root: {
      '&$focused': {
        color: '#5F6C7B',
      },
    },
    filled: {
      '&$shrink': {
        marginLeft: '30px',
        borderRadius: '4px',
      },
    },
  },
};

theme.components = {
  MuiCard: {
    styleOverrides: {
      root: {
        boxShadow: '0 5px 15px 2px rgba(0, 102, 204, 0.15);',
      },
    },
  },
};
