import {
  Box,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading, TeamScheduledMatchCard } from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { useFetchUpcomingMatchesQuery } from 'shared/services';

export function NextMatches() {
  const team = useContext(TeamContext);
  const { t } = useTranslation();
  const {
    isLoading,
    isSuccess,
    data: matches,
  } = useFetchUpcomingMatchesQuery({ team: team._id }, { refetchOnMountOrArgChange: true });

  const title = (
    <Typography
      sx={{
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '24px',
        color: '#263140',
      }}
    >
      {t('Próximas partidas')}
    </Typography>
  );

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isSuccess && matches.length > 0) {
    content = (
      <>
        <Container component="main" maxWidth="xs" sx={{ marginTop: '10px', paddingBottom: '0' }}>
          {title}
        </Container>
        <Grid
          container
          sx={{
            minWidth: '100%',
            display: 'flex',
            flexWrap: 'nowrap',
            gap: '8px',
            overflowY: 'scroll',
            scrollBehavior: 'smooth',
            padding: '16px',
          }}
        >
          {
            matches.map((match) => (
              <Grid item key={match._id}>
                <TeamScheduledMatchCard key={`schedule-card-${match._id}`} label={match.tournament} match={match} />
              </Grid>
            ))
          }
        </Grid>
      </>
    );
  }

  return (
    <Box>
      { content }
    </Box>
  );
}

export default NextMatches;
