import React, { useContext } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  AthleteStatsResumeCard,
  AthleteTournamentStatsCard,
  ErrorMessage,
  Loading,
} from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { useFetchAthleteResumeStatsByTypeQuery, useFetchAthleteTournamentsStatsResumeQuery } from 'shared/services';

export function AthleteListTournaments() {
  const team = useContext(TeamContext);
  const [athlete] = useOutletContext();

  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data: tournamentsResumeStats,
  } = useFetchAthleteTournamentsStatsResumeQuery({ team: team._id, athlete: athlete._id });

  const resumeStats = useFetchAthleteResumeStatsByTypeQuery({ team: team._id, athlete: athlete._id, type: 'tournaments' });

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <ErrorMessage messages={error.data.messages} />;
  } else if (isSuccess) {
    content = tournamentsResumeStats.map((tournament) => <AthleteTournamentStatsCard key={`stats-card-${tournament.tournament}`} athlete={athlete} label={tournament.tournament} stats={tournament.stats} matches={tournament.matches} />);
  }

  return (
    <>
      {
        resumeStats.isSuccess
        && <AthleteStatsResumeCard stats={resumeStats.data.stats} athlete={athlete} />
      }
      {content}
    </>
  );
}

export default AthleteListTournaments;
