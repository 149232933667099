import { Route, Routes } from 'react-router-dom';
import { AthletesList, AthleteView } from 'pages';
import {
  AthleteEdit,
  AthleteListScrimmageMatches,
  AthleteListPractice,
  AthleteListTournamentMatches,
  AthleteListTournaments,
  AthleteMatchStats,
  AthleteShareMatchStats,
  PageNotFound,
  RegisterAthleteForm,
} from 'shared/components';

export function AthleteRoutes() {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route index element={<AthletesList />} />
      <Route path="new" element={<RegisterAthleteForm redirect="/athletes" statusBar={false} />} />
      <Route path=":athleteId/stats/match/:matchId" element={<AthleteMatchStats />} />
      <Route path=":athleteId/stats/match_share/:matchId" element={<AthleteShareMatchStats />} />
      <Route path=":athleteId/edit" element={<AthleteEdit statusBar={false} />} />
      <Route path=":athleteId" element={<AthleteView />}>
        <Route index element={<AthleteListTournaments />} />
        <Route path="stats/tournaments" element={<AthleteListTournaments />} />
        <Route path="stats/scrimmage" element={<AthleteListScrimmageMatches />} />
        <Route path="stats/practice" element={<AthleteListPractice />} />
        <Route path="stats/tournaments/:tournamentId" element={<AthleteListTournamentMatches />} />
      </Route>
    </Routes>
  );
}
