import { Box } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyPage } from 'pages';
import { Loading, TournamentStatsCard } from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { useFetchTeamTournamentsQuery } from 'shared/services';

export function TeamListTournaments() {
  const team = useContext(TeamContext);
  const { t } = useTranslation();

  const {
    isLoading,
    isSuccess,
    isError,
    data: tournaments,
  } = useFetchTeamTournamentsQuery({ team: team._id }, { refetchOnMountOrArgChange: true });

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <EmptyPage title={t('Ainda não há informações')} content={t('Adicione um novo Jogo de Campeonato, Amistoso ou Treino no botão Criar.')} />;
  } else if (isSuccess && tournaments.length > 0) {
    content = tournaments.map((tournament) => (
      <TournamentStatsCard
        key={`stats-card-${tournament.tournament}`}
        label={tournament.tournament}
        stats={tournament.stats}
        matches={tournament.matches}
        backLink={`/team/${team._id}/stats/tournaments/${tournament.tournament}`}
      />
    ));
  } else {
    content = <EmptyPage title={t('Ainda não há informações')} content={t('Adicione um novo Jogo de Campeonato, Amistoso ou Treino no botão Criar.')} />;
  }

  return (
    <Box>
      {content}
    </Box>
  );
}

export default TeamListTournaments;
