export const QuestionOffense = [
  {
    id: 'is_try_td',
    label: 'Qual é a tentativa?',
    field: 'is_try_td',
    visible: true,
    actions: [
      { label: 'Meio', value: false, next: 'fst_down' },
      { label: 'Touchdown', value: true, next: 'down' },
    ],
  },
  {
    id: 'down',
    label: 'Qual é a descida?',
    field: 'down',
    visible: false,
    actions: [
      { label: '1ª', value: 1, next: 'type' },
      { label: '2ª', value: 2, next: 'type' },
      { label: '3ª', value: 3, next: 'type' },
      { label: '4ª', value: 4, next: 'type' },
      {
        label: 'Pular pergunta', type: 'skip', value: '', next: 'type',
      },
    ],
  },
  {
    id: 'fst_down',
    label: 'Qual é a descida?',
    field: 'down',
    visible: false,
    actions: [
      { label: '1ª', value: 1, next: 'fst_type' },
      { label: '2ª', value: 2, next: 'fst_type' },
      { label: '3ª', value: 3, next: 'fst_type' },
      { label: '4ª', value: 4, next: 'fst_type' },
      {
        label: 'Pular pergunta', type: 'skip', value: '', next: 'fst_type',
      },
    ],
  },
  {
    id: 'type',
    label: 'Como foi a jogada?',
    field: 'type',
    visible: false,
    reset: ['fault'],
    actions: [
      { label: 'Passe', value: 'pass', next: 'qb_pass' },
      { label: 'Corrida', value: 'run', next: 'qb_running' },
      {
        label: 'Falta bola morta', value: 'fault_dead_ball', next: 'fault_team', set_values: ['fault=true'],
      },
      { label: 'Bad Snap', value: 'bad_snap', next: 'fault' },
      {
        label: 'Pular pergunta', type: 'skip', value: '', next: 'play_result',
      },
    ],
  },
  {
    id: 'fst_type',
    label: 'Como foi a jogada?',
    field: 'type',
    visible: false,
    reset: ['fault'],
    actions: [
      { label: 'Passe', value: 'pass', next: 'fst_qb_pass' },
      { label: 'Corrida', value: 'run', next: 'fst_qb_running' },
      {
        label: 'Falta bola morta', value: 'fault_dead_ball', next: 'fault_team', set_values: ['fault=true'],
      },
      { label: 'Bad Snap', value: 'bad_snap', next: 'fault' },
      {
        label: 'Pular pergunta', type: 'skip', value: '', next: 'play_result',
      },
    ],
  },
  {
    id: 'qb_pass',
    label: 'Quem foi a QB?',
    field: 'qb',
    visible: false,
    actions: [
      {
        type: 'athletes', team: 'offense', position: 'Quarterback', next: 'receiver',
      },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'receiver',
      },
    ],
  },
  {
    id: 'qb_running',
    label: 'Quem foi a QB?',
    field: 'qb',
    visible: false,
    actions: [
      {
        type: 'athletes', team: 'offense', position: 'Quarterback', next: 'running_back',
      },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'running_back',
      },
    ],
  },
  {
    id: 'fst_qb_pass',
    label: 'Quem foi a QB?',
    field: 'qb',
    visible: false,
    actions: [
      {
        type: 'athletes', team: 'offense', position: 'Quarterback', next: 'fst_receiver',
      },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fst_receiver',
      },
    ],
  },
  {
    id: 'fst_qb_running',
    label: 'Quem foi a QB?',
    field: 'qb',
    visible: false,
    actions: [
      {
        type: 'athletes', team: 'offense', position: 'Quarterback', next: 'fst_running_back',
      },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fst_running_back',
      },
    ],
  },
  {
    id: 'receiver',
    label: 'Quem foi o alvo?',
    field: 'receiver',
    visible: false,
    actions: [
      { type: 'athletes', team: 'offense', next: 'play_result' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'play_result',
      },
    ],
  },
  {
    id: 'fst_receiver',
    label: 'Quem foi o alvo?',
    field: 'receiver',
    visible: false,
    actions: [
      { type: 'athletes', team: 'offense', next: 'fst_play_result' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fst_play_result',
      },
    ],
  },
  {
    id: 'running_back',
    label: 'Quem foi a running back?',
    field: 'running_back',
    visible: false,
    actions: [
      { type: 'athletes', team: 'offense', next: 'play_result_running' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'play_result_running',
      },
    ],
  },
  {
    id: 'fst_running_back',
    label: 'Quem foi a running back?',
    field: 'running_back',
    visible: false,
    actions: [
      { type: 'athletes', team: 'offense', next: 'fst_play_result_running' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fst_play_result_running',
      },
    ],
  },
  {
    id: 'play_result',
    label: 'Qual o resultado do ataque?',
    field: 'play_result',
    visible: false,
    reset: ['is_touchdown'],
    actions: [
      { label: 'Tackle', value: 'tackle', next: 'yards' },
      { label: 'Down (sem tackle)', value: 'down', next: 'yards' },
      { label: 'Sack', value: 'sack', next: 'is_safety' },
      { label: 'Incompleto', value: 'incomplete', next: 'play_result_incomplete' },
      { label: 'Saída de campo', value: 'out_field', next: 'yards' },
      {
        label: 'Touchdown', value: 'touchdown', next: 'fault_td', set_values: ['is_touchdown=true'],
      },
    ],
  },
  {
    id: 'fst_play_result',
    label: 'Qual o resultado do ataque?',
    field: 'play_result',
    visible: false,
    reset: ['is_touchdown'],
    actions: [
      { label: 'Tackle', value: 'tackle', next: 'is_first_down' },
      { label: 'Down (sem tackle)', value: 'down', next: 'is_first_down' },
      { label: 'Sack', value: 'sack', next: 'is_safety' },
      { label: 'Incompleto', value: 'incomplete', next: 'play_result_incomplete' },
      { label: 'Saída de campo', value: 'out_field', next: 'is_first_down' },
      {
        label: 'Touchdown', value: 'touchdown', next: 'fault_td', set_values: ['is_touchdown=true'],
      },
    ],
  },
  {
    id: 'play_result_running',
    label: 'Qual o resultado do ataque?',
    field: 'play_result',
    visible: false,
    reset: ['is_touchdown'],
    actions: [
      { label: 'Tackle', value: 'tackle', next: 'yards' },
      { label: 'Down (sem tackle)', value: 'down', next: 'yards' },
      { label: 'Incompleto', value: 'incomplete', next: 'running_incomplete' },
      { label: 'Saída de campo', value: 'out_field', next: 'yards' },
      {
        label: 'Touchdown', value: 'touchdown', next: 'fault_td', set_values: ['is_touchdown=true'],
      },
    ],
  },
  {
    id: 'fst_play_result_running',
    label: 'Qual o resultado do ataque?',
    field: 'play_result',
    visible: false,
    reset: ['is_touchdown'],
    actions: [
      { label: 'Tackle', value: 'tackle', next: 'is_first_down' },
      { label: 'Down (sem tackle)', value: 'down', next: 'is_first_down' },
      { label: 'Incompleto', value: 'incomplete', next: 'running_incomplete' },
      { label: 'Saída de campo', value: 'out_field', next: 'is_first_down' },
      {
        label: 'Touchdown', value: 'touchdown', next: 'fault', set_values: ['is_touchdown=true'],
      },
    ],
  },
  {
    id: 'running_incomplete',
    label: 'Qual o resultado da tentativa?',
    field: 'play_result',
    visible: false,
    actions: [
      { label: 'Drop', value: 'drop', next: 'fault' },
      { label: 'Sack', value: 'sack', next: 'is_safety_on_running' },
    ],
  },
  {
    id: 'play_result_incomplete',
    label: 'Como acabou a jogada?',
    field: 'play_result',
    visible: false,
    actions: [
      { label: 'Drop', value: 'drop', next: 'fault' },
      { label: 'Tip', value: 'tip', next: 'fault' },
      { label: 'Passe errado', value: 'wrong_pass', next: 'fault' },
      { label: 'Interceptação', value: 'intercept', next: 'is_touchdown_intercept' },
      { label: 'Bola Fora', value: 'out', next: 'fault' },
      { label: 'Sack', value: 'sack', next: 'is_safety' },
      {
        label: 'Pular pergunta', type: 'skip', value: 'Incompleto', next: 'fault',
      },
    ],
  },
  {
    id: 'is_safety',
    label: 'Foi safety?',
    field: 'is_safety',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'fault' },
      { label: 'Não', value: false, next: 'given_yards' },
    ],
  },
  {
    id: 'is_safety_on_running',
    label: 'Foi safety?',
    field: 'is_safety',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'save' },
      { label: 'Não', value: false, next: 'given_yards' },
    ],
  },
  {
    id: 'is_touchdown',
    label: 'Foi touchdown?',
    field: 'is_touchdown',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'fault_td' },
      { label: 'Não', value: false, next: 'yards' },
    ],
  },
  {
    id: 'fst_is_touchdown',
    label: 'Foi touchdown?',
    field: 'is_touchdown',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'fault_td' },
      { label: 'Não', value: false, next: 'is_first_down' },
    ],
  },
  {
    id: 'is_first_down',
    label: 'Foi conversão para o meio?',
    field: 'is_first_down',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'yards' },
      { label: 'Não', value: false, next: 'yards' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'yards',
      },
    ],
  },
  {
    id: 'is_touchdown_intercept',
    label: 'Foi retorno para touchdown?',
    field: 'is_touchdown',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'fault_td_intercept' },
      { label: 'Não', value: false, next: 'is_first_down_intercept' },
    ],
  },
  {
    id: 'is_first_down_intercept',
    label: 'Foi conversão para o meio?',
    field: 'is_first_down',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'given_yards' },
      { label: 'Não', value: false, next: 'given_yards' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'given_yards',
      },
    ],
  },
  {
    id: 'yards',
    label: 'Quantas jardas foram avançadas?',
    field: 'yards',
    visible: false,
    actions: [
      { type: 'number', value: '', next: 'fault' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fault',
      },
    ],
    type: 'yards',
  },
  {
    id: 'given_yards',
    label: 'Quantas jardas foram cedidas?',
    field: 'given_yards',
    visible: false,
    actions: [
      { type: 'number', value: '', next: 'fault' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fault',
      },
    ],
    type: 'yards',
  },
  {
    id: 'fault',
    label: 'Teve falta na jogada?',
    field: 'fault',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'fault_team' },
      { label: 'Não', value: false, next: 'save' },
    ],
  },
  {
    id: 'fault_td',
    label: 'Teve falta na jogada?',
    field: 'fault',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'fault_team_td' },
      { label: 'Não', value: false, next: 'save' },
    ],
  },
  {
    id: 'fault_td_intercept',
    label: 'Teve falta na jogada?',
    field: 'fault',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'fault_team_td_intercept' },
      { label: 'Não', value: false, next: 'save' },
    ],
  },
  {
    id: 'fault_team',
    label: 'Qual time fez a falta?',
    field: 'fault_team',
    visible: false,
    actions: [
      { label: 'Ataque', value: 'offense', next: 'fault_player' },
      { label: 'Defesa', value: 'defense', next: 'fault_yards' },
    ],
  },
  {
    id: 'fault_team_td',
    label: 'Qual time fez a falta?',
    field: 'fault_team',
    visible: false,
    actions: [
      {
        label: 'Ataque', value: 'offense', next: 'fault_player', set_values: ['play_result=fault', 'is_touchdown=false'],
      },
      {
        label: 'Defesa', value: 'defense', next: 'fault_yards', set_values: ['play_result=touchdown', 'is_touchdown=true'],
      },
    ],
  },
  {
    id: 'fault_team_td_intercept',
    label: 'Qual time fez a falta?',
    field: 'fault_team',
    visible: false,
    actions: [
      {
        label: 'Ataque', value: 'offense', next: 'fault_player', set_values: ['play_result=intercept', 'is_touchdown=true'],
      },
      {
        label: 'Defesa', value: 'defense', next: 'fault_yards', set_values: ['play_result=fault', 'is_touchdown=false'],
      },
    ],
  },
  {
    id: 'fault_player',
    label: 'Qual atleta fez a falta?',
    field: 'fault_player',
    visible: false,
    actions: [
      { type: 'athletes', team: 'offense', next: 'fault_yards_offense' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fault_yards_offense',
      },
    ],
  },
  {
    id: 'fault_yards',
    label: 'Quantas jardas de penalidade?',
    field: 'fault_yards',
    visible: false,
    actions: [
      { label: '0', value: 0, next: 'fault_auto_first_down' },
      { label: '5', value: 5, next: 'fault_auto_first_down' },
      { label: '10', value: 10, next: 'fault_auto_first_down' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fault_auto_first_down',
      },
    ],
  },
  {
    id: 'fault_yards_offense',
    label: 'Quantas jardas de penalidade?',
    field: 'fault_yards',
    visible: false,
    actions: [
      { label: '0', value: 0, next: 'fault_repeat_down' },
      { label: '5', value: 5, next: 'fault_repeat_down' },
      { label: '10', value: 10, next: 'fault_repeat_down' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fault_repeat_down',
      },
    ],
  },
  {
    id: 'fault_auto_first_down',
    label: 'Primeira descida automática?',
    field: 'fault_auto_first_down',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'ignore_play' },
      { label: 'Não', value: false, next: 'fault_repeat_down' },
    ],
  },
  {
    id: 'fault_repeat_down',
    label: 'Repete a descida?',
    field: 'fault_repeat_down',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'ignore_play' },
      { label: 'Não', value: false, next: 'ignore_play' },
    ],
  },
  {
    id: 'ignore_play',
    label: 'Ignorar pontuação e estatísticas desta jogada?',
    field: 'ignore_play',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'save' },
      { label: 'Não', value: false, next: 'save' },
    ],
  },
];
