import {
  Box,
  Button, Container, Grid, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import { LanguageSwitcher, Logo } from 'shared/components';
import { isAuthenticated } from 'shared/services';

export function Splash() {
  const { t } = useTranslation();
  const version = process.env.REACT_APP_VERSION;
  const environment = process.env.REACT_APP_ENVIRONMENT;

  if (isAuthenticated()) return <Navigate to="/dashboard" />;

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        backgroundImage: `url(${'/assets/img/splash-photo.jpg'})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <LanguageSwitcher color="#fff" />
      <Grid
        container
        direction="column"
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          color: '#ffffff',
        }}
      >
        <Grid item>
          <Box
            sx={{
              marginTop: '136px',
            }}
          >
            <Logo color="#ffffff" variant="subtitle" />
          </Box>
        </Grid>

        <Grid item>
          <Typography component="h2" variant="subtitle" mb={2}>{t('Bem-vindo!')}</Typography>
          <Typography>{t('Acompanhe as estatísticas do seu time de onde você estiver')}</Typography>
        </Grid>

        <Grid item>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <Link to="/login">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
              >
                {t('Fazer Login')}
              </Button>
            </Link>

            <a href={process.env.REACT_APP_STRIPE_CHECKOUT_PAGE}>
              <Button
                type="submit"
                fullWidth
                variant="outlined"
                color="secondary"
              >
                {t('Cadastro')}
              </Button>
            </a>

            {/* <Link to="/register">
              <Button
                type="submit"
                fullWidth
                variant="outlined"
                color="secondary"
              >
                Cadastro
              </Button>
            </Link> */}
          </Box>
        </Grid>
      </Grid>
      <Typography
        component="span"
        color="secondary"
        sx={{
          position: 'absolute',
          bottom: 0,
        }}
      >
        {`${t('Versão')}: ${version}`}
        {environment !== 'Production' ? ` - ${environment}` : ''}
      </Typography>
    </Container>
  );
}

export default Splash;
