import '../match/MatchTimeline.css';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Container,
  Fab,
  Fade,
  Grid,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import {
  ErrorMessage,
  EventCard,
  Loading,
  PlayCard,
} from 'shared/components';
import { useFetchLiveAthletesQuery, useFetchLivePlaysQuery } from 'shared/services';

export function LiveMatchTimeline() {
  const { t } = useTranslation();
  const [match] = useOutletContext();

  const {
    isLoading: isLoadingPlays,
    isError: isErrorPlays,
    error: errorPlay,
    isSuccess: isSuccessPlays,
    data: plays,
    refetch: refetchPlays,
  } = useFetchLivePlaysQuery({ match: match._id });
  const {
    isLoading: isLoadingAthletes,
    isError: isErrorAthletes,
    error: errorAthlete,
    isSuccess: isSuccessAthletes,
    data: athletes,
  } = useFetchLiveAthletesQuery({ match: match._id });

  useEffect(() => {
    const interval = setInterval(() => {
      refetchPlays();
    }, 30000);

    return () => clearInterval(interval);
  }, [refetchPlays]);

  const isEnded = !!((match && match.ended));
  let content;
  let matchTime;
  let showDefenseOfenseLabel = false;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  };

  useLayoutEffect(() => {
    if (isSuccessPlays && match?.started) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }
  }, [plays, match?.started, isSuccessPlays]);

  const gradientColor = 'rgba(255, 255, 255, 0.8)';
  const gradient = `radial-gradient(circle at top center, ${gradientColor} 0%, rgba(255, 255, 255, 0) 100%)`;
  const scrollTopButton = (
    <Box
      onClick={handleClick}
      role="presentation"
      sx={{
        position: 'fixed',
        bottom: isEnded ? 36 : 156,
        right: 16,
        zIndex: 4,
      }}
    >
      <Fab size="small" aria-label="scroll back to top" sx={{ background: `${gradient}, ${gradientColor}` }}>
        <KeyboardArrowUpIcon />
      </Fab>
    </Box>
  );

  const fadeContent = (
    <Fade in={trigger}>
      {scrollTopButton}
    </Fade>
  );

  if (isLoadingPlays || isLoadingAthletes) {
    content = <Loading />;
  } else if (isErrorPlays || isErrorAthletes) {
    content = (
      <>
        <ErrorMessage messages={errorPlay.data.messages} />
        <ErrorMessage messages={errorAthlete.data.messages} />
      </>
    );
  } else if (match?._id && isSuccessPlays && isSuccessAthletes && !match?.started) {
    content = (
      <Box
        sx={{
          height: '45vh',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body2"
          textAlign="center"
          sx={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            textAlign: 'center',
            color: '#3E4A59',
          }}
        >
          {t('Jogo não iniciado')}
        </Typography>
      </Box>
    );
  } else if (match?._id && isSuccessPlays && isSuccessAthletes && !match?.ended) {
    showDefenseOfenseLabel = plays.length > 0 ?? true;

    content = (
      <Container component="main" maxWidth="xs" sx={{ marginBottom: '180px' }}>
        <div className="playcards-list">
          <div className="playcardlist-header" />
          {plays.map((play) => {
            const playCard = <PlayCard className={play.defense ? 'defense' : 'offense'} key={play._id} play={play} athletes={athletes} />;
            if (matchTime !== play.match_time) {
              matchTime = play.match_time;
              const eventCard = <EventCard key={`event-${play._id}`} label={`${t('Início do')} ${t(`${matchTime}º tempo`)}`} icon="watch" />;
              return [eventCard, playCard];
            }
            return playCard;
          })}
          {isEnded
            && <EventCard label={t('Fim de jogo')} icon="wistle" />}
        </div>
      </Container>
    );
  } else {
    content = (
      <Box
        sx={{
          height: '45vh',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body2"
          textAlign="center"
          sx={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            textAlign: 'center',
            color: '#3E4A59',
          }}
        >
          {t('Partida encerrada')}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Grid
        id="back-to-top-anchor"
        sx={{
          height: '100%', marginTop: '20px', marginBottom: '20px', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around',
        }}
      >
        {showDefenseOfenseLabel
          && (
            <>
              <Grid item>
                <Typography component="h3" variant="subtitle">{t('Ataque')}</Typography>
              </Grid>
              <Grid item>
                <Typography component="h3" variant="subtitle">{t('Defesa')}</Typography>
              </Grid>

            </>
          )}
      </Grid>
      {content}
      {fadeContent}
    </>
  );
}

export default LiveMatchTimeline;
