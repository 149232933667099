import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
} from 'react-router-dom';
import {
  Box,
  Typography,
} from '@mui/material';
import { LiveScoreCard } from 'shared/components';
import { useFetchLiveTeamQuery } from 'shared/services';

export function LiveMatchHeader(props) {
  const { match } = props;
  const {
    isSuccess,
    data: team,
  } = useFetchLiveTeamQuery({ match: match._id });
  const { t } = useTranslation();
  const [state] = useState({
    anchorEl: null,
    openOptions: false,
    openDeleteDialog: false,
    redirect: false,
  });

  const getLabel = (matchType) => {
    switch (matchType) {
      case 'tournament':
        return t('Jogo de campeonato');
      case 'scrimmage':
        return t('Amistoso');
      default:
        return t('Treino');
    }
  };

  let status;
  switch (true) {
    case match.ended:
      status = t('Finalizado');
      break;
    case match.started:
      status = getLabel(match.type) + t(' em andamento');
      break;
    default:
      status = getLabel(match.type) + t(' não iniciado');
      break;
  }

  return (
    <>
      {state.redirect && <Navigate to={state.redirect} />}
      <Box
        sx={{
          width: '100%',
          height: '150px',
          padding: '20px',
          background: 'linear-gradient(137.13deg, #004D99 31.91%, #0080FF 140.67%)',
          color: '#ffffff',
          textAlign: 'center',
          position: 'relative',
          marginBottom: '50px',
        }}
      >
        <header>
          <Typography variant="span" align="center">
            {status}
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            sx={{ fontSize: '1.2rem' }}
          >
            Transmissão de footballers
          </Typography>
        </header>
      </Box>
      {isSuccess
        && <LiveScoreCard team={team} match={match} />}
    </>
  );
}

export default LiveMatchHeader;
