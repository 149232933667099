/* eslint-disable max-len */
import {
  Button,
} from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  Loading,
  Question,
  QuestionDefense,
  QuestionExtraPointDefense,
  QuestionExtraPointOffense,
  QuestionOffense,
} from 'shared/components';
import { TeamContext, ScoutingStatusContext, PlayTypeContext } from 'shared/contexts';
import { PlayHeader } from 'shared/layouts';
import {
  useUpdatePlayMutation, useFetchAthletesQuery, useFetchMatchQuery, useFetchPlaysQuery,
} from 'shared/services';
import { useTranslation } from 'react-i18next';

export function EditPlay() {
  const team = useContext(TeamContext);
  const {
    playInsertPosition,
    setPlayInsertPosition,
    selectedPlayId,
    setIsScouting,
    setSelectedPlayId,
  } = useContext(ScoutingStatusContext);
  const { setPlayType } = useContext(PlayTypeContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const cleanState = {
    playSchema: {},
    questions: [],
    error: '',
    next: false,
    isLoadingMutator: false,
    savePlay: false,
  };

  const [state, setState] = useState({ ...cleanState });
  const { matchId, playId } = useParams();
  let redirect = false;

  const {
    isFetching: isFetchingMatch,
    isSuccess,
    data: match,
  } = useFetchMatchQuery({ team: team._id, match: matchId }, { refetchOnMountOrArgChange: true });

  const {
    isFetching: isFetchingAthletes,
    data: athletes,
  } = useFetchAthletesQuery(team._id);

  const {
    data: plays,
    isSuccess: playsIsSuccess,
  } = useFetchPlaysQuery({ team: team._id, match: matchId }, { refetchOnMountOrArgChange: true });

  let play;
  let playIsTouchdown = false;
  let pick6 = false;
  let interceptFirstDown = false;
  if (playsIsSuccess) {
    play = plays.find((selectedPlay) => selectedPlay._id === playId);

    if (play.play_result === 'touchdown' && !play.is_extra_point) {
      playIsTouchdown = true;
    }

    if (play.play_result === 'intercept' && play.is_touchdown) {
      pick6 = true;
    }

    if (play.play_result === 'intercept' && play.is_first_down) {
      interceptFirstDown = true;
    }
  }

  const [updatePlay] = useUpdatePlayMutation();

  const selectPlayStarter = useCallback((starter) => {
    const playSchema = playsIsSuccess ? { ...play } : {};
    const currentTime = match.time && match?.time?.first_time?.end ? 2 : 1;

    playSchema.match_time = (currentTime) || 1;

    switch (starter) {
      case 'offense':
        playSchema.offense = true;
        setState((prevState) => ({
          ...prevState, subtitle: 'Ataque', playSchema, questions: QuestionOffense, next: false, isLoadingMutator: false,
        }));
        break;
      case 'extrapointOffense':
        playSchema.is_extra_point = true;
        playSchema.offense = true;
        setState((prevState) => ({
          ...prevState, subtitle: 'Ataque | Ponto Extra', playSchema, questions: QuestionExtraPointOffense, next: false, isLoadingMutator: false,
        }));
        break;
      case 'defense':
        playSchema.defense = true;
        setState((prevState) => ({
          ...prevState, subtitle: 'Defesa', playSchema, questions: QuestionDefense, next: false, isLoadingMutator: false,
        }));
        break;
      case 'extrapointDefense':
        playSchema.is_extra_point = true;
        playSchema.defense = true;
        setState((prevState) => ({
          ...prevState, subtitle: 'Defesa | Ponto Extra', playSchema, questions: QuestionExtraPointDefense, next: false, isLoadingMutator: false,
        }));
        break;
      default:
        setState((prevState) => ({ ...prevState, playSchema, next: false }));
        break;
    }
  }, [match, play, playsIsSuccess]);

  const reset = () => {
    if (playIsTouchdown) {
      navigate(`/matches/${matchId}/timeline`);
      return;
    }
    const playSchema = { ...state.playSchema };

    if (playSchema.is_extra_point && playSchema.fault_repeat_down) {
      const nextPlayType = playSchema.offense ? 'extrapointOffense' : 'extrapointDefense';

      setPlayType(nextPlayType);
      setPlayInsertPosition('after');
      setSelectedPlayId(playId);

      navigate(`/matches/${matchId}/play`);
      return;
    }

    if (
      !playSchema.ignore_play
        && playSchema.is_touchdown
        && playSchema.is_extra_point !== true
    ) {
      let nextPlayType;

      if (playSchema.offense) {
        nextPlayType = playSchema.play_result === 'intercept' ? 'extrapointDefense' : 'extrapointOffense';
      } else if (!playSchema.offense) {
        nextPlayType = playSchema.play_result === 'intercept' ? 'extrapointOffense' : 'extrapointDefense';
      }

      setPlayType(nextPlayType);
      setPlayInsertPosition('after');
      setSelectedPlayId(playId);

      navigate(`/matches/${matchId}/play`);
      return;
    }

    setIsScouting(false);
    navigate(`/matches/${matchId}/timeline`);
  };

  const update = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoadingMutator: true, next: false }));
      if (playInsertPosition && selectedPlayId) {
        setIsScouting(false);
      }

      await updatePlay({
        team: team._id, match: matchId, playId, body: { ...state.playSchema, id: playId },
      });

      reset();
    } catch (err) {
      setState((prevState) => ({ ...prevState, next: false, isLoadingMutator: false }));
    }
  };

  const showSaveButton = () => {
    setState((prevState) => ({ ...prevState, savePlay: true }));
  };

  const querySelectElement = (questionId, value, field, next) => {
    const selector = `[question_id="${questionId}"][value="${value}"][field="${field}"][next="${next}"]`;
    const element = document.querySelector(selector);
    return element;
  };

  const handleButtonClick = (btn) => {
    btn.setAttribute('clicked', 'true');
    const clickEvent = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    btn.dispatchEvent(clickEvent);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const processQuestionsByAttribute = () => {
    state?.questions.forEach((question) => {
      if (question.visible) {
        question.actions.forEach((action) => {
          if (action.type === 'athletes') {
            const btn = querySelectElement(question.id, state.playSchema[question.field], question.field, action.next);
            if (btn && !btn.hasAttribute('clicked')) {
              handleButtonClick(btn);
            }
            return null;
          }

          if (state.playSchema[question.field] === action.value) {
            const btn = querySelectElement(question.id, action.value, question.field, action.next);
            if (btn && !btn.hasAttribute('clicked')) {
              handleButtonClick(btn);
            }
          } else if (question.field === 'fault' && action.value === true && state.playSchema.fault_team) {
            const btn = querySelectElement(question.id, action.value, question.field, action.next);
            if (btn && !btn.hasAttribute('clicked')) {
              handleButtonClick(btn);
            }
          } else if (question.field === 'fault' && action.value === false && !state.playSchema.fault_team) {
            const btn = querySelectElement(question.id, action.value, question.field, action.next);
            if (btn && !btn.hasAttribute('clicked')) {
              handleButtonClick(btn);
            }
          } else if (question.field === 'is_touchdown' && action.value === true && pick6) {
            const btn = querySelectElement(question.id, action.value, question.field, action.next);
            if (btn && !btn.hasAttribute('clicked')) {
              handleButtonClick(btn);
            }
          } else if (question.field === 'is_touchdown' && action.value === false && !pick6) {
            const btn = querySelectElement(question.id, action.value, question.field, action.next);
            if (btn && !btn.hasAttribute('clicked')) {
              handleButtonClick(btn);
            }
          } else if (question.field === 'is_first_down' && action.value === true && interceptFirstDown) {
            const btn = querySelectElement(question.id, action.value, question.field, action.next);
            if (btn && !btn.hasAttribute('clicked')) {
              handleButtonClick(btn);
            }
          } else if (question.field === 'is_first_down' && action.value === false && !interceptFirstDown) {
            const btn = querySelectElement(question.id, action.value, question.field, action.next);
            if (btn && !btn.hasAttribute('clicked')) {
              handleButtonClick(btn);
            }
          } else if (question.field === 'play_result' && action.value === 'incomplete') {
            const incompleteValues = ['drop', 'tip', 'wrong_pass', 'intercept', 'out'];

            if (incompleteValues.includes(state.playSchema[question.field])) {
              const btn = querySelectElement(question.id, action.value, question.field, action.next);
              if (btn && !btn.hasAttribute('clicked')) {
                btn.value = state.playSchema[question.field];
                handleButtonClick(btn);
              }
            }
          } else if (
            action.value === false
              && question.field !== 'fault'
              && question.field !== 'is_touchdown'
              && (
                state.playSchema[question.field] === undefined
                  || state.playSchema[question.field] === null
              )
          ) {
            const btn = querySelectElement(question.id, action.value, question.field, action.next);
            if (btn && !btn.hasAttribute('clicked')) {
              handleButtonClick(btn);
            }
          }
          return null;
        });

        if (question.field === 'yards' || question.field === 'given_yards') {
          const selector = `[question_id="${question.id}"][actiontype="number"]`;
          const btn = document.querySelector(selector);
          if (btn && !btn.hasAttribute('clicked')) {
            handleButtonClick(btn);
          }
        }

        const optionValues = [
          'qb',
          'receiver',
          'running_back',
          'down',
          'type',
          'play_result',
          'defensive_player_end_play',
          'is_first_down',
          'interceptor',
          'given_yards',
          'yards',
          'fault_player',
        ];

        if (
          optionValues.includes(question.field)
            && (
              state.playSchema[question.field] === 'undefined'
                || state.playSchema[question.field] === null
            )
        ) {
          const selector = `.skip[question_id="${question.id}"]`;
          const btn = document.querySelector(selector);
          if (btn && !btn.hasAttribute('clicked')) {
            handleButtonClick(btn);
          }
        }
      }
    });
  };

  useEffect(() => {
    if (playsIsSuccess) {
      let playType;

      if (play.is_extra_point) {
        playType = play.offense ? 'extrapointOffense' : 'extrapointDefense';
      } else {
        playType = play.offense ? 'offense' : 'defense';
      }

      selectPlayStarter(playType);
    }
  }, [play, selectPlayStarter, playsIsSuccess]);

  useEffect(() => {
    if (document.querySelector('.question_wrapper:last-of-type')) {
      window.scrollTo(0, document.querySelector('.question_wrapper:last-of-type').offsetTop - 10);
    }

    if (state.next === 'save') {
      showSaveButton();
    }

    /* TODO: warning pede para adicionar save como dependendcia
    do useEffect, porém ao adicionar save como dependencia
    a função save passa a exigida dentro do useEffect */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.next, state.questions]);

  useLayoutEffect(() => {
    if (state.questions && state.questions.length > 0) {
      processQuestionsByAttribute();
    }

    if (state.next === 'save') {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }
  }, [state.next, state.questions, processQuestionsByAttribute]);

  const showQuestion = (questions, next) => {
    const indexNextQuestion = questions.findIndex((x) => x.id === next);
    if (indexNextQuestion >= 0) {
      const updatedQuestion = {
        ...questions[indexNextQuestion],
        visible: true,
      };
      const updatedQuestions = [
        ...questions.slice(0, indexNextQuestion),
        updatedQuestion,
        ...questions.slice(indexNextQuestion + 1),
      ];
      return updatedQuestions;
    }
    return questions;
  };

  const resetQuestionValues = (playSchema, question) => {
    const result = playSchema;
    if (question?.field) delete result[question.field];

    const keys = question?.reset || [];

    keys.forEach((value) => {
      delete result[value];
    });

    setState((prevState) => ({ ...prevState, savePlay: false }));
    return result;
  };

  const onButtonPress = (event) => {
    const btn = event.target;
    const next = btn.getAttribute('next');
    let playSchema = { ...state.playSchema };

    // @todo otimizar caminhos / transformar em funções
    let questions = [...state.questions];
    const currentQuestionIndex = questions.findIndex((x) => x.id === btn.getAttribute('question_id'));
    if (btn.getAttribute('actiontype') !== 'number') playSchema = resetQuestionValues(playSchema, questions[currentQuestionIndex]);

    if (btn.getAttribute('field')) {
      const field = btn.getAttribute('field');
      const value = (btn.getAttribute('value') === 'true') ? true : btn.getAttribute('value');

      if (value !== 'false') playSchema[field] = value;

      if (playSchema.defense === true && field === 'play_result' && value === 'touchdown') {
        playSchema.is_touchdown = true;
      }
    }

    if (btn.getAttribute('set_values')) {
      const keysValues = btn.getAttribute('set_values').split(',');
      keysValues.forEach((value) => {
        const splited = value.split('=');
        if (splited.length === 2) {
          const [prop, val] = splited;
          playSchema[prop] = (val === 'true') ? true : val;
          playSchema[prop] = (val === 'false') ? false : val;
        }
      });
    }

    if (currentQuestionIndex >= 0) {
      const updatedCurrentQuestion = {
        ...questions[currentQuestionIndex],
        answered: true,
        answer: btn.getAttribute('answer') ?? btn.getAttribute('value'),
      };
      questions[currentQuestionIndex] = updatedCurrentQuestion;
    }

    if (questions[currentQuestionIndex]?.answered) {
      for (let i = currentQuestionIndex + 1; i < questions.length; i += 1) {
        if (questions[i].visible === true || questions[i].answered === true) {
          questions[i].visible = false;
          questions[i].answered = false;
          playSchema = resetQuestionValues(playSchema, questions[i]);
        }
      }
    }

    questions = showQuestion(questions, next);

    setState((prevState) => ({
      ...prevState, questions, playSchema, next,
    }));
  };

  const handleInputChange = (event) => {
    const playSchema = { ...state.playSchema };
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    playSchema[name] = value;

    setState((prevState) => ({ ...prevState, playSchema }));
  };

  const elQuestions = state?.questions.map(
    (question) => (
      question.visible
        ? (
          <Question
            key={question.id}
            question={question}
            onButtonPress={onButtonPress}
            handleInputChange={handleInputChange}
            athletes={athletes}
            play={state.playSchema}
          />
        )
        : null
    ),
  );

  if (state.isLoadingMutator || isFetchingMatch || isFetchingAthletes) return <Loading />;

  if (isSuccess) {
    const started = !!match?.time;
    const ended = !!(match?.time && match?.time?.second_time?.end);

    if (
      (!started || ended)
      && (!playInsertPosition && !selectPlayStarter)
    ) {
      redirect = `/matches/${matchId}/timeline`;
    }
  }

  return (
    <>
      {redirect && <Navigate to={redirect} />}

      {state.subtitle && <PlayHeader label={t(state.subtitle)} match={match} />}
      {elQuestions}
      {state.savePlay === true
        && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={update}
          >
            {t('Salvar jogada')}
          </Button>
        )}
    </>
  );
}

export default EditPlay;
