import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link, Navigate, useNavigate, useSearchParams,
} from 'react-router-dom';
import {
  IconLeftArrow,
  Loading,
} from 'shared/components';
import { useCheckTokenQuery, useUpdateUserPasswordMutation } from 'shared/services';

export function PasswordRecoverForm() {
  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
    alertResponse: false,
    showPassword: false,
    showConfirmPassword: false,
  });
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const token = searchParams.get('token');
  const [updatePassword] = useUpdateUserPasswordMutation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  let redirect;

  const handleClickShowPassword = (event) => {
    if (event === 'password') {
      setState((prevState) => ({
        ...prevState, showPassword: !state.showPassword,
      }));
    } else {
      setState((prevState) => ({
        ...prevState, showConfirmPassword: !state.showConfirmPassword,
      }));
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const {
    isFetching,
    isError,
  } = useCheckTokenQuery({ email, token });

  if (isFetching) return <Loading />;

  if (isError) {
    redirect = '/recover?invalid=true';
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, confirmPassword } = state;
    if (!password || !confirmPassword) {
      setState((prevState) => ({ ...prevState, alertResponse: { response: 'error', message: t('Preencha todos os campos') } }));
    } else if (password !== confirmPassword) {
      setState((prevState) => ({ ...prevState, alertResponse: { response: 'error', message: t('A confirmação de senha não confere') } }));
    } else {
      const result = await updatePassword({
        email, token, password, confirmPassword,
      });
      if (result?.data?.success) {
        navigate('/login?passwordUpdated=true');
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      {redirect && <Navigate to={redirect} />}
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 2,
            width: 'fit-content',
          }}
        >
          <Link
            to="/dashboard"
            sx={{
              width: 'fit-content',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconLeftArrow />
              <Typography variant="subtitle1" color="#3E4A59">
                {t('Voltar')}
              </Typography>
            </Box>
          </Link>
        </Box>
        <Box
          sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'center',
            alignItems: 'center',
          }}
          id="recover-password-form"
          component="form"
          onSubmit={handleSubmit}
        >
          <Grid container direction="column">
            <Grid item>
              <Box
                sx={{
                  margin: '20px 0',
                }}
              >
                <Typography component="h1" variant="h6">
                  {t('Recuperação de senha')}
                </Typography>
              </Box>
              <Typography component="h2" variant="caption">
                {t('Crie uma nova senha.')}
              </Typography>
            </Grid>

            <Grid item>
              {
                state.alertResponse
                && (
                  <Alert
                    severity={state.alertResponse.response}
                  >
                    {state.alertResponse.message}
                  </Alert>
                )
              }
            </Grid>

            <Grid item>
              <TextField
                fullWidth
                required
                margin="normal"
                variant="filled"
                id="password"
                label={t('Senha')}
                name="password"
                value={state.password}
                onChange={handleInputChange}
                type={state.showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment:
                    (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword('password')}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                required
                margin="normal"
                variant="filled"
                id="confirmPassword"
                label={t('Confirme sua senha')}
                name="confirmPassword"
                value={state.confirmPassword}
                onChange={handleInputChange}
                type={state.showConfirmPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment:
                    (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword('confirmation')}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          id="confirm"
                        >
                          {state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box
        sx={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          width: '100%',
          zIndex: '2',
        }}
      >
        <Button
          type="submit"
          form="recover-password-form"
          fullWidth
          variant="contained"
          color="primary"
        >
          {t('Continuar')}
        </Button>
      </Box>
    </>
  );
}

export default PasswordRecoverForm;
