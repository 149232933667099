import { Box, Typography } from '@mui/material';
import CrisisAlertOutlinedIcon from '@mui/icons-material/CrisisAlertOutlined';

function RatioLabel(props) {
  const { labelWidth } = props;
  return (
    <Box
      sx={{
        fontFamily: 'Mulish',
        width: 'fit-content',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '12px',
        color: '#fff',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        background: '#3DCD7F',
        borderRadius: '4px',
        padding: '4px 8px',
      }}
    >
      <CrisisAlertOutlinedIcon sx={{ fontSize: '1rem' }} />
      <Typography
        sx={{
          fontSize: '0.75rem',
          marginLeft: '8px',
          maxWidth: labelWidth,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {props.label}
      </Typography>
    </Box>
  );
}

export default RatioLabel;
