import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import {
  Box,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { IconLeftArrow } from 'shared/components';

export function ShareableAthleteHeader(props) {
  const { t } = useTranslation();
  const [state] = useState({
    anchorEl: null,
    openOptions: false,
    openDeleteDialog: false,
    redirect: false,
  });

  return (
    <>
      {state.redirect && <Navigate to={state.redirect} />}
      <Box
        sx={{
          width: '100%',
          minHeight: '260px',
          padding: '20px',
          background: 'linear-gradient(137.13deg, #004D99 31.91%, #0080FF 140.67%)',
          color: '#ffffff',
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              width: 'fit-content',
            }}
          >
            <Link to={props.backto}>
              <IconButton size="small" aria-label="return" sx={{ padding: '0' }}>
                <IconLeftArrow color="#ffffff" />
              </IconButton>
            </Link>
          </Box>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '24px',
              color: '#FFFFFF',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              paddingRight: '40px',
            }}
          >
            {t('Estatísticas de Atleta')}
          </Typography>
          <Box />
        </Box>
        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', margin: '0' }}>
          <Grid item sx={{ flexGrow: '1' }}>
            <Typography variant="h6" align="left">
              {props?.athlete?.name}
            </Typography>
            <Grid container sx={{ marginBottom: '16px' }}>
              <Grid item xs={2} md={2}>
                <Box
                  sx={{
                    width: '32px',
                    height: '24px',
                    background: '#EFF8F7',
                    borderRadius: '4px',
                    padding: '0 5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#0066CC',
                    fontSize: '12px',
                    fontWeight: '700',
                  }}
                >
                  #
                  {props?.athlete?.number}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: '20px',
          }}
        >
          {
            props.athlete?.positions.map((position) => (
              <Typography
                key={`key-${position}`}
                component="span"
                variant="caption"
                sx={{
                  display: 'inline-block',
                  padding: '0px 8px',
                  margin: '5px',
                  background: 'rgba(245, 246, 250, 0.2)',
                  borderRadius: '4px',
                  fontFamily: 'Mulish',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '24px',
                  textAlign: 'center',
                }}
              >
                {position}
              </Typography>
            ))
          }
        </Box>
      </Box>
    </>
  );
}

export default ShareableAthleteHeader;
