import { Box, Container, Grid } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EmptyPage } from 'pages';
import { AthleteItem, ErrorMessage, Loading } from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { Footer, GenericHeader } from 'shared/layouts';
import { useFetchAthletesQuery } from 'shared/services';

export function AthletesList() {
  const team = useContext(TeamContext);
  const { t } = useTranslation();
  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data: athletes,
  } = useFetchAthletesQuery(team._id);

  let content;

  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <ErrorMessage messages={error.data.messages} />;
  } else if (isSuccess) {
    if (athletes.length > 0) {
      content = (
        <>
          {
            athletes.map((athlete) => <Link to={`/athletes/${athlete._id}/stats/tournaments`} key={athlete._id}><AthleteItem athlete={athlete} /></Link>)
          }
        </>
      );
    } else {
      content = <EmptyPage title={t('Ainda não há informações')} content={t('Adicione um novo Jogador.')} />;
    }
  }

  return (
    <>
      <GenericHeader title={t('Atletas')} linkBack="/" linkTo="/athletes/new" />
      <Container component="main" maxWidth="xs" className="list-athletes">
        <Grid item>
          <Box
            sx={{
              mt: 2,
              mb: 10,
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            {content}
          </Box>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default AthletesList;
