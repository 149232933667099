import {
  Avatar,
  Box,
  Card,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RatioLabel, IconAthlete, IconStats } from 'shared/components';

function AthleteItem(props) {
  const { t } = useTranslation();
  function getPlayerPosition() {
    switch (true) {
      case props.athlete.offense && props.athlete.defense:
        return t('Defesa・Ataque');
      case props.athlete.offense && !props.athlete.defense:
        return t('Ataque');
      case !props.athlete.offense && props.athlete.defense:
        return t('Defesa');
      default:
        return '';
    }
  }

  const AthletePosition = getPlayerPosition();

  const AthletePositionText = (
    <Typography
      sx={{
        fontFamily: 'Mulish',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#50729A',
      }}
    >
      {AthletePosition}
    </Typography>
  );

  const AthleteNumber = (
    <Box
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '0px 5px',
          width: '32px',
          height: '24px',
          background: '#EFF3F7',
          borderRadius: '4px',
          marginRight: '8px',
        }}
      >
        <Typography
          sx={{
            display: 'inline',
            fontFamily: 'Mulish',
            fontWeight: '800',
            fontSize: '12px',
            color: '#0066CC',
          }}
        >
          {`#${props.athlete.number}`}
        </Typography>
      </Box>
      <Typography
        sx={{
          display: 'inline',
          fontFamily: 'Mulish',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '14px',
          color: '#3E4A59',
        }}
      >
        {`${props.athlete.name}`}
      </Typography>
    </Box>
  );

  return (
    <Card>
      {props.ratio
        && (
          <Box sx={{ marginLeft: '10px', marginTop: '10px' }}>
            <RatioLabel label={`${t('Aproveitamento')}: ${props.ratio * 100}%`} />
          </Box>
        )}
      <Box
        sx={{
          height: '88px',
          padding: '8px 16px',
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Avatar
            sx={{
              width: '48px',
              height: '48px',
            }}
          >
            <IconAthlete />
          </Avatar>
          <Box sx={{ flexGrow: 1, marginLeft: '15px' }}>
            {AthletePositionText}
            {AthleteNumber}
          </Box>
        </Box>
        <IconButton
          aria-label="Detalhes"
          sx={{
            height: '40px',
            width: '40px',
            marginRight: '17px',
            background: '#EFF3F7',
            borderRadius: '4px',
          }}
        >
          <IconStats color="#0066CC" />
        </IconButton>
      </Box>
    </Card>
  );
}
export default AthleteItem;
