import './styles.css';
import './lang/i18n';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import { Home } from 'pages/';
import {
  AthleteRoutes, CoachRoutes, MatchRoutes, RecoverRoutes,
  RegisterRoutes, StatsRoutes, TeamRoutes, LiveRoutes,
} from 'routes';
import TagManager from 'react-gtm-module';
import {
  Loading,
  Login,
  Splash,
  TeamListTournaments,
  CookieBanner,
} from 'shared/components';
import { TeamContext, UserContext } from 'shared/contexts';
import {
  isAuthenticated, useFetchTeamQuery, useFetchUserQuery, logout,
} from 'shared/services';
import { store } from 'shared/store';
import { theme } from 'shared/themes';
import { useMetaDescription } from 'shared/hooks';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTAG,
};
TagManager.initialize(tagManagerArgs);

function PrivateRoutes({ redirectTo }) {
  return isAuthenticated() ? <Outlet /> : <Navigate to={redirectTo} />;
}

function HasOnboarding({ redirectTo, user }) {
  return (user && user.showOnboarding) ? <Navigate to={redirectTo} /> : <Outlet />;
}

export function App() {
  useMetaDescription();
  window.dataLayer.push({
    event: 'pageview',
  });
  const {
    isFetching,
    data: team,
    error: errorFetchTeam,
  } = useFetchTeamQuery(null, { skip: !isAuthenticated() });
  const contextTeam = team;

  const {
    isFetching: isFetchingUser,
    data: user,
  } = useFetchUserQuery(null, { skip: !isAuthenticated() });
  const contextUser = user;

  if (isFetching || isFetchingUser) return <Loading />;
  if (errorFetchTeam?.status || errorFetchTeam?.originalStatus === 401) {
    logout();
    return <Navigate to="/login" />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ThemeProvider theme={theme}>
        <UserContext.Provider value={contextUser}>
          <TeamContext.Provider value={contextTeam}>
            <CssBaseline />
            <BrowserRouter>
              <div id="app">
                <Routes>
                  <Route path="/" element={<Splash />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/recover/*" element={<RecoverRoutes />} />
                  <Route path="/live/*" element={<LiveRoutes />} />
                  <Route element={<PrivateRoutes redirectTo="/login" />}>
                    <Route path="/coach-ia/*" element={<CoachRoutes />} />
                    <Route path="/register/*" element={<RegisterRoutes />} />
                    <Route element={<HasOnboarding redirectTo="/register/athletes" user={user} />}>
                      <Route path="/dashboard" element={<Home />}>
                        <Route index element={<TeamListTournaments />} />
                      </Route>
                    </Route>
                    <Route path="/team/*" element={<TeamRoutes />} />
                    <Route path="/athletes/*" element={<AthleteRoutes />} />
                    <Route path="/matches/*" element={<MatchRoutes />} />
                    <Route path="/stats/*" element={<StatsRoutes />} />
                  </Route>
                </Routes>
                <CookieBanner />
              </div>
            </BrowserRouter>
          </TeamContext.Provider>
        </UserContext.Provider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
