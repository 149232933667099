import {
  Box,
  Button,
  Modal,
  Typography,
} from '@mui/material';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  Loading,
  Question,
  QuestionDefense,
  QuestionExtraPointDefense,
  QuestionExtraPointOffense,
  QuestionOffense,
} from 'shared/components';
import { TeamContext, PlayTypeContext, ScoutingStatusContext } from 'shared/contexts';
import { PlayHeader } from 'shared/layouts';
import { useCreatePlayMutation, useFetchAthletesQuery, useFetchMatchQuery } from 'shared/services';
import { useTranslation } from 'react-i18next';

export function Play() {
  const team = useContext(TeamContext);
  const { playType, setPlayType } = useContext(PlayTypeContext);
  const { playInsertPosition, selectedPlayId, setIsScouting } = useContext(ScoutingStatusContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const cleanState = {
    playSchema: {},
    questions: [],
    error: '',
    next: false,
    isLoadingMutator: false,
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const modalStyle = {
    position: 'absolute',
    width: '80%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#fff',
    boxShadow: '0px 10px 20px rgba(43, 37, 63, 0.1)',
    borderRadius: '4px',
    p: 4,
    textAlign: 'center',
  };

  const [state, setState] = useState({ ...cleanState });
  const { matchId } = useParams();
  let redirect = false;

  const {
    isFetching: isFetchingMatch,
    isSuccess,
    data: match,
  } = useFetchMatchQuery({ team: team._id, match: matchId }, { refetchOnMountOrArgChange: true });

  const {
    isFetching: isFetchingAthletes,
    data: athletes,
  } = useFetchAthletesQuery(team._id);

  const [createPlay] = useCreatePlayMutation();

  const selectPlayStarter = (starter) => {
    const playSchema = {};
    const currentTime = match.time && match?.time?.first_time?.end ? 2 : 1;
    playSchema.match_time = (currentTime) || 1;
    playSchema.playInsertPosition = playInsertPosition;
    playSchema.selectedPlayId = selectedPlayId;

    switch (starter) {
      case 'offense':
        playSchema.offense = true;
        setState((prevState) => ({
          ...prevState, subtitle: 'Ataque', playSchema, questions: QuestionOffense, next: false, isLoadingMutator: false,
        }));
        break;
      case 'extrapointOffense':
        playSchema.is_extra_point = true;
        playSchema.offense = true;
        setState((prevState) => ({
          ...prevState, subtitle: 'Ataque | Ponto Extra', playSchema, questions: QuestionExtraPointOffense, next: false, isLoadingMutator: false,
        }));
        break;
      case 'defense':
        playSchema.defense = true;
        setState((prevState) => ({
          ...prevState, subtitle: 'Defesa', playSchema, questions: QuestionDefense, next: false, isLoadingMutator: false,
        }));
        break;
      case 'extrapointDefense':
        playSchema.is_extra_point = true;
        playSchema.defense = true;
        setState((prevState) => ({
          ...prevState, subtitle: 'Defesa | Ponto Extra', playSchema, questions: QuestionExtraPointDefense, next: false, isLoadingMutator: false,
        }));
        break;
      default:
        setState((prevState) => ({ ...prevState, playSchema, next: false }));
        break;
    }
  };

  const reset = () => {
    const playSchema = { ...state.playSchema };

    if (playSchema.is_extra_point && playSchema.fault_repeat_down) {
      const nextQuestions = playSchema.offense ? 'extrapointOffense' : 'extrapointDefense';
      selectPlayStarter(nextQuestions);
      return;
    }

    if (
      !playSchema.ignore_play
        && playSchema.is_touchdown
        && playSchema.is_extra_point !== true
    ) {
      if (playSchema.offense) {
        const nextQuestions = playSchema.play_result === 'intercept' ? 'extrapointDefense' : 'extrapointOffense';
        selectPlayStarter(nextQuestions);
      } else if (!playSchema.offense) {
        const nextQuestions = playSchema.play_result === 'intercept' ? 'extrapointOffense' : 'extrapointDefense';
        selectPlayStarter(nextQuestions);
      }
      return;
    }

    navigate(`/matches/${matchId}/timeline`);
  };

  const save = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoadingMutator: true, next: false }));
      if (playInsertPosition && selectedPlayId) {
        setIsScouting(false);
      }
      await createPlay({
        team: team._id, match: matchId, body: { ...state.playSchema },
      });
      reset();
    } catch (err) {
      setState((prevState) => ({ ...prevState, next: false, isLoadingMutator: false }));
    }
  };

  useEffect(() => {
    if (document.querySelector('.question_wrapper:last-of-type')) {
      window.scrollTo(0, document.querySelector('.question_wrapper:last-of-type').offsetTop - 10);
    }

    if (state.next === 'save') {
      save();
    }

    const playTypeActions = {
      offense: 'offense',
      defense: 'defense',
      extrapointOffense: 'extrapointOffense',
      extrapointDefense: 'extrapointDefense',
    };

    if (playTypeActions[playType]) {
      selectPlayStarter(playTypeActions[playType]);
      setPlayType('');
    }

    /* TODO: warning pede para adicionar save como dependendcia
    do useEffect, porém ao adicionar save como dependencia
    a função save passa a exigida dentro do useEffect */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, playType]);

  const showQuestion = (questions, next) => {
    const indexNextQuestion = questions.findIndex((x) => x.id === next);
    if (indexNextQuestion >= 0) {
      const updatedQuestion = {
        ...questions[indexNextQuestion],
        visible: true,
      };
      const updatedQuestions = [
        ...questions.slice(0, indexNextQuestion),
        updatedQuestion,
        ...questions.slice(indexNextQuestion + 1),
      ];
      return updatedQuestions;
    }
    return questions;
  };

  const resetQuestionValues = (playSchema, question) => {
    const result = playSchema;
    if (question?.field) delete result[question.field];

    const keys = question?.reset || [];

    keys.forEach((value) => {
      delete result[value];
    });

    return result;
  };

  const onButtonPress = (event) => {
    const btn = event.target;
    const next = btn.getAttribute('next');
    let playSchema = { ...state.playSchema };

    // @todo otimizar caminhos / transformar em funções
    let questions = [...state.questions];
    const currentQuestionIndex = questions.findIndex((x) => x.id === btn.getAttribute('question_id'));
    if (btn.getAttribute('actiontype') !== 'number') playSchema = resetQuestionValues(playSchema, questions[currentQuestionIndex]);

    if (btn.getAttribute('field')) {
      const field = btn.getAttribute('field');
      const value = (btn.getAttribute('value') === 'true') ? true : btn.getAttribute('value');

      if (value !== 'false') playSchema[field] = value;

      if (playSchema.defense === true && field === 'play_result' && value === 'touchdown') {
        playSchema.is_touchdown = true;
      }
    }

    if (btn.getAttribute('set_values')) {
      const keysValues = btn.getAttribute('set_values').split(',');
      keysValues.forEach((value) => {
        const splited = value.split('=');
        if (splited.length === 2) {
          const [prop, val] = splited;
          playSchema[prop] = (val === 'true') ? true : val;
          playSchema[prop] = (val === 'false') ? false : val;
        }
      });
    }

    if (questions[currentQuestionIndex]?.answered) {
      for (let i = currentQuestionIndex + 1; i < questions.length; i += 1) {
        if (questions[i].visible === true || questions[i].answered === true) {
          questions[i].visible = false;
          questions[i].answered = false;
          playSchema = resetQuestionValues(playSchema, questions[i]);
        }
      }
    }

    if (currentQuestionIndex >= 0) {
      const updatedCurrentQuestion = {
        ...questions[currentQuestionIndex],
        answered: true,
        answer: btn.getAttribute('value'),
      };
      questions[currentQuestionIndex] = updatedCurrentQuestion;
    }
    questions = showQuestion(questions, next);
    handleClose();

    setState((prevState) => ({
      ...prevState, questions, playSchema, next,
    }));
  };

  const handleInputChange = (event) => {
    const playSchema = { ...state.playSchema };
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    playSchema[name] = value;

    setState((prevState) => ({ ...prevState, playSchema }));
  };

  const elQuestions = state?.questions.map(
    (question) => (
      question.visible
        ? (
          <Question
            key={question.id}
            question={question}
            onButtonPress={onButtonPress}
            handleInputChange={handleInputChange}
            athletes={athletes}
            play={state.playSchema}
          />
        )
        : null
    ),
  );

  if (state.isLoadingMutator || isFetchingMatch || isFetchingAthletes) return <Loading />;

  if (isSuccess) {
    const started = !!match?.time;
    const ended = !!(match?.time && match?.time?.second_time?.end);

    if (
      (!started || ended)
      && (!playInsertPosition && !selectPlayStarter)
    ) {
      redirect = `/matches/${matchId}/timeline`;
    }
  }

  const modalConfirmation = (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {t('Deseja encerrar esta jogada?')}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {t('Esta ação é irreversivel!')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          next="save"
          onClick={onButtonPress}
          sx={{
            mt: '20px',
            mb: '10px',
          }}
        >
          {t('Encerrar jogada')}
        </Button>

        <Button
          variant="text"
          color="primary"
          onClick={handleClose}
        >
          {t('Continuar scouting')}
        </Button>
      </Box>
    </Modal>
  );

  return (
    <>
      {redirect && <Navigate to={redirect} />}
      {/* {!state.playSchema.defense && !state.playSchema.offense
        && <Navigate to={`/matches/${matchId}/timeline`} />} */}

      {state.subtitle && <PlayHeader label={t(state.subtitle)} match={match} />}
      {elQuestions}
      {state.questions[0]
        && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleOpen}
            className="btn-finish-play"
            size="large"
          >
            {t('Encerrar jogada')}
          </Button>
        )}
      {modalConfirmation}
    </>
  );
}

export default Play;
