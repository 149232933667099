import { useContext } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  AthletePracticeStatsCard,
  AthleteStatsResumeCard,
  ErrorMessage,
  Loading,
} from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { useFetchAthleteMatchesByTypeQuery, useFetchAthleteResumeStatsByTypeQuery } from 'shared/services';

export function AthleteListPractice() {
  const team = useContext(TeamContext);
  const [athlete] = useOutletContext();

  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data: practiceMatches,
  } = useFetchAthleteMatchesByTypeQuery({ team: team._id, athlete: athlete._id, type: 'practice' });

  const resumeStats = useFetchAthleteResumeStatsByTypeQuery({ team: team._id, athlete: athlete._id, type: 'practice' });

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <ErrorMessage messages={error.data.messages} />;
  } else if (isSuccess) {
    content = practiceMatches.map((match) => <AthletePracticeStatsCard key={`stats-card-${match._id}`} athlete={athlete} match={match} />);
  }

  return (
    <>
      {
        resumeStats.isSuccess
        && <AthleteStatsResumeCard stats={resumeStats.data.stats} athlete={athlete} />
      }
      {content}
    </>
  );
}

export default AthleteListPractice;
