import {
  AppBar, Box, IconButton, Toolbar,
} from '@mui/material';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { IconClose, MatchTypeMenu } from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { HeaderTitle } from 'shared/layouts';

export function StatsHeader(props) {
  const team = useContext(TeamContext);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ background: 'linear-gradient(137.13deg, #004D99 31.91%, #0080FF 140.67%)' }}>
        <Toolbar sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Link to={props.linkBack}>
            <IconButton size="small" aria-label="return" sx={{ padding: '0' }}>
              <IconClose color="#ffffff" />
            </IconButton>
          </Link>
          <HeaderTitle sx={{ flexGrow: 1 }}>{props.title}</HeaderTitle>
          <Box />
        </Toolbar>
        <MatchTypeMenu
          tournamentLink={`/stats/${team._id}/tournaments`}
          scrimmageLink={`/stats/${team._id}/scrimmage`}
          practiceLink={`/stats/${team._id}/practice`}
          color="#FFFFFF"
          activeColor="rgba(255,255,255,0.5)"
        />
      </AppBar>
    </Box>
  );
}

export default StatsHeader;
