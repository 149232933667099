import { Container } from '@mui/material';
import { t } from 'i18next';
import { Outlet } from 'react-router-dom';
import { Footer, StatsHeader } from 'shared/layouts';

function StatsView() {
  return (
    <div id="stats">
      <StatsHeader title={t('Estatísticas')} linkBack="/" />
      <Container component="main" maxWidth="xs" className="home" sx={{ marginTop: '40px', paddingBottom: '110px' }}>
        <Outlet />
      </Container>
      <Footer />
    </div>
  );
}

export default StatsView;
