import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { TeamContext } from 'shared/contexts';
import { useFetchAthletesQuery } from 'shared/services';
import { AthleteItem, ErrorMessage, Loading } from 'shared/components';
import {
  Box,
  Container,
  Grid,
  Typography,
} from '@mui/material';

export function SuggestionList(props) {
  const team = useContext(TeamContext);
  const { t } = useTranslation();
  const location = useLocation();
  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data: athletes,
  } = useFetchAthletesQuery(team._id);

  let content;

  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <ErrorMessage messages={error.data.messages} />;
  } else if (isSuccess) {
    if (athletes.length > 0) {
      const filteredAthletes = athletes
        .map((athlete) => {
          const athletesSuggestion = props.athletesSuggestion.find(
            (athleteSuggestion) => athlete._id === athleteSuggestion._id,
          );
          if (athletesSuggestion) {
            return { ...athlete, ratio: athletesSuggestion.ratio };
          }
          return null;
        })
        .filter((filteredAthlete) => filteredAthlete !== null)
        .sort((a, b) => b.ratio - a.ratio);

      if (filteredAthletes.length > 0) {
        content = (
          <>
            {
              filteredAthletes.map(
                (athlete) => (
                  athlete.ratio > 0
                  && (
                  <Link
                    to={`/athletes/${athlete._id}/stats/tournaments`}
                    state={{ prevPath: location.pathname }}
                    key={athlete._id}
                  >
                    <AthleteItem athlete={athlete} ratio={athlete.ratio} />
                  </Link>
                  )
                ),
              )
            }
          </>
        );
      } else {
        content = (
          <Typography variant="subtitle2">
            {t('Sem sugestões disponíveis para esta jogada no momento.')}
          </Typography>
        );
      }
    }
  } else {
    content = (
      <Typography variant="subtitle2">
        {t('Sem sugestões disponíveis para esta jogada no momento.')}
      </Typography>
    );
  }

  return (
    <Container component="main" maxWidth="xs" className="list-athletes">
      <Typography>
        {t(props.title)}
      </Typography>
      <Grid item>
        <Box
          sx={{
            mt: 2,
            mb: 10,
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          {content}
        </Box>
      </Grid>
    </Container>
  );
}

export default SuggestionList;
