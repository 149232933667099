import AddIcon from '@mui/icons-material/Add';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { IconLeftArrow } from 'shared/components';
import { Header, HeaderTitle } from 'shared/layouts';

export function GenericHeader(props) {
  return (
    <Header>
      <Box
        sx={{
          width: 'fit-content',
        }}
      >
        <Link to={props.linkBack}>
          <IconButton size="small" aria-label="return" sx={{ padding: '0' }}>
            <IconLeftArrow color="#ffffff" />
          </IconButton>
        </Link>
      </Box>
      <HeaderTitle>{props.title}</HeaderTitle>
      <Box
        sx={{
          width: '40px',
        }}
      >
        {props?.linkTo
                && (
                <Link to={props.linkTo}>
                  <IconButton aria-label="settings">
                    <AddIcon sx={{ color: '#ffffff' }} />
                  </IconButton>
                </Link>
                )}
      </Box>
    </Header>
  );
}

export default GenericHeader;
