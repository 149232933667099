import { Box } from '@mui/material';
import React from 'react';
import { HeaderTitle } from 'shared/layouts';

export function Header(props) {
  return (
    <Box
      sx={{
        width: '100%',
        padding: '20px',
        background: 'linear-gradient(137.13deg, #004D99 31.91%, #0080FF 140.67%)',
        color: '#ffffff',
        textAlign: 'center',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: props.children ? 'space-between' : 'center',
        }}
      >
        {props.children ?? <HeaderTitle>{props.title}</HeaderTitle>}
      </Box>
    </Box>
  );
}

export default Header;
