import { Route, Routes } from 'react-router-dom';
import {
  PageNotFound,
  PasswordRecoverForm,
  Recover,
} from 'shared/components';

export function RecoverRoutes() {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route index element={<Recover />} />
      <Route path="create-new-password" element={<PasswordRecoverForm />} />
    </Routes>
  );
}
