import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from 'lang/i18n';

export function SelectLanguageDialog({
  handleClose,
  openSelecteLanguageDialog,
}) {
  const { t } = useTranslation();
  const languages = ['en', 'pt', 'es'];

  return (
    <Dialog
      open={openSelecteLanguageDialog}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {t('Bem vindo!')}
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="h5"
        >
          {t('Selecione o idioma.')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginTop: '20px',
          }}
        >
          {languages.map((locale) => {
            let message;
            switch (locale) {
              case 'en':
                message = 'English';
                break;
              case 'es':
                message = 'Español';
                break;
              default:
                message = 'Português';
                break;
            }

            return (
              <Button
                key={locale}
                variant="contained"
                onClick={() => i18n.changeLanguage(locale)}
                sx={{ marginBottom: '10px' }}
              >
                {message}
              </Button>
            );
          })}
        </Box>
        <p>
          <strong>
            {t('A senha de acesso foi enviada para seu email de cadastro')}
            {', '}
          </strong>
          {t('recomendamos que você altere no primeiro acesso.')}
        </p>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} variant="contained" size="small">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SelectLanguageDialog;
