import styled from '@emotion/styled';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export function CollapsableTableRow(props) {
  const { header, rows } = props;
  const [open, setOpen] = useState(false);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      color: '#3E4A59',
      lineHeight: '24px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <>
      <TableRow>
        <StyledTableCell component="th" scope="row">
          <Typography
            variant="subtitle2"
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
            }}
            aria-label="expand row"
            onClick={() => setOpen(!open)}
          >
            {
              open
                ? <KeyboardArrowUpIcon sx={{ margin: '0 5px', verticalAlign: 'text-bottom' }} />
                : <KeyboardArrowDownIcon sx={{ margin: '0 5px', verticalAlign: 'text-bottom' }} />
            }
            {header}
          </Typography>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table aria-label="purchases">
              <TableBody>
                {rows.map((row) => (
                  <StyledTableRow key={uuidv4()}>
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.value}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default CollapsableTableRow;
