import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  Loading,
  Question,
  SuggestionList,
  SuggestionQuestions,
} from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { GenericHeader } from 'shared/layouts';
import { useRequestSuggestionMutation } from 'shared/services';
import {
  Box, Fab, Fade, useScrollTrigger,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export function Coach() {
  const team = useContext(TeamContext);
  const location = useLocation();
  const prevPath = location.state?.prevPath ? location.state.prevPath : '/';

  const cleanState = {
    dataSchema: {},
    questions: SuggestionQuestions,
    error: '',
    next: false,
    isLoadingMutator: false,
    suggestion: false,
  };

  const [state, setState] = useState({ ...cleanState });
  const [requestSuggestion] = useRequestSuggestionMutation();

  const submitForSuggestion = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoadingMutator: true, next: false }));
      const response = await requestSuggestion({
        team: team._id, body: { ...state.dataSchema },
      });
      if (response.data.success) {
        setState((prevState) => ({
          ...prevState,
          suggestion: response.data.suggestion,
          isLoadingMutator: false,
          next: false,
        }));
      }
    } catch (err) {
      setState((prevState) => ({ ...prevState, next: false, isLoadingMutator: false }));
    }
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  };

  const gradientColor = 'rgba(255, 255, 255, 0.8)';
  const gradient = `radial-gradient(circle at top center, ${gradientColor} 0%, rgba(255, 255, 255, 0) 100%)`;
  const scrollTopButton = (
    <Box
      onClick={handleClick}
      role="presentation"
      sx={{
        position: 'fixed',
        bottom: 136,
        right: 16,
        zIndex: 4,
      }}
    >
      <Fab size="small" aria-label="scroll back to top" sx={{ background: `${gradient}, ${gradientColor}` }}>
        <KeyboardArrowUpIcon />
      </Fab>
    </Box>
  );

  const fadeContent = (
    <Fade in={trigger}>
      {scrollTopButton}
    </Fade>
  );

  useEffect(() => {
    if (state.next === 'save') {
      submitForSuggestion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useLayoutEffect(() => {
    if (state.suggestion.qb || state.suggestion.athletes) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }
  }, [state.suggestion.qb, state.suggestion.athletes]);

  const showQuestion = (questions, next) => {
    const indexNextQuestion = questions.findIndex((x) => x.id === next);
    if (indexNextQuestion >= 0) {
      const updatedQuestion = {
        ...questions[indexNextQuestion],
        visible: true,
      };
      const updatedQuestions = [
        ...questions.slice(0, indexNextQuestion),
        updatedQuestion,
        ...questions.slice(indexNextQuestion + 1),
      ];
      return updatedQuestions;
    }
    return questions;
  };

  const resetQuestionValues = (dataSchema, question) => {
    const result = dataSchema;
    if (question?.field) {
      delete result[question.field];
      setState((prevState) => ({ ...prevState, suggestion: false }));
    }

    const keys = question?.reset || [];

    keys.forEach((value) => {
      delete result[value];
    });

    return result;
  };

  const onButtonPress = (event) => {
    const btn = event.target;
    const next = btn.getAttribute('next');
    let dataSchema = { ...state.dataSchema };

    let questions = [...state.questions];
    const currentQuestionIndex = questions.findIndex((x) => x.id === btn.getAttribute('question_id'));
    if (btn.getAttribute('actiontype') !== 'number') dataSchema = resetQuestionValues(dataSchema, questions[currentQuestionIndex]);

    if (btn.getAttribute('field')) {
      const field = btn.getAttribute('field');
      const value = (btn.getAttribute('value') === 'true') ? true : btn.getAttribute('value');

      if (value !== 'false') dataSchema[field] = value;
    }

    if (btn.getAttribute('set_values')) {
      const keysValues = btn.getAttribute('set_values').split(',');
      keysValues.forEach((value) => {
        const splited = value.split('=');
        if (splited.length === 2) {
          const [prop, val] = splited;
          dataSchema[prop] = (val === 'true') ? true : val;
          dataSchema[prop] = (val === 'false') ? false : val;
        }
      });
    }

    if (questions[currentQuestionIndex]?.answered) {
      for (let i = currentQuestionIndex + 1; i < questions.length; i += 1) {
        if (questions[i].visible === true || questions[i].answered === true) {
          questions[i].visible = false;
          questions[i].answered = false;
          dataSchema = resetQuestionValues(dataSchema, questions[i]);
        }
      }
    }

    if (currentQuestionIndex >= 0) {
      const updatedCurrentQuestion = {
        ...questions[currentQuestionIndex],
        answered: true,
        answer: btn.getAttribute('value'),
      };
      questions[currentQuestionIndex] = updatedCurrentQuestion;
    }
    questions = showQuestion(questions, next);

    setState((prevState) => ({
      ...prevState, questions, dataSchema, next,
    }));
  };

  const handleInputChange = (event) => {
    const dataSchema = { ...state.dataSchema };
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    dataSchema[name] = value;

    setState((prevState) => ({ ...prevState, dataSchema }));
  };

  const elQuestions = state?.questions.map(
    (question) => (
      question.visible
        ? (
          <Question
            key={question.id}
            question={question}
            onButtonPress={onButtonPress}
            handleInputChange={handleInputChange}
            play={state.dataSchema}
            coach
          />
        )
        : null
    ),
  );

  if (state.isLoadingMutator) return <Loading />;

  return (
    <>
      <Box id="back-to-top-anchor">
        <GenericHeader title="Coach AI" linkBack={prevPath} />
        {elQuestions}
        {
          state.suggestion.qb
          && <SuggestionList title="QBs" athletesSuggestion={state.suggestion.qb} />
        }
        {
          state.suggestion.athletes
          && <SuggestionList title="Atletas" athletesSuggestion={state.suggestion.athletes} />
        }
      </Box>
      {fadeContent}
    </>
  );
}

export default Coach;
