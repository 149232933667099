import React, { useState } from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDeleteAthleteMutation } from 'shared/services';
import { useNavigate } from 'react-router-dom';

export function DeleteAthleteDialog({
  openDeleteAthleteDialog,
  handleClose,
  teamId,
  athlete,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [setState] = useState({
    error: '',
  });
  const [deleteAthlete, deleteAthletehResult] = useDeleteAthleteMutation();
  const handleDeleteAthlete = async (e) => {
    e.preventDefault();
    const athleteId = athlete?._id;
    if (!athlete || !teamId) {
      setState((prevState) => ({ ...prevState, error: t('Atleta não encontrada.') }));
    } else {
      const result = await deleteAthlete({
        team: teamId,
        body: {
          athlete: athleteId,
        },
      });
      if (result?.data?.success) {
        navigate('/athletes');
      }
    }
  };

  return (
    <Dialog
      open={openDeleteAthleteDialog}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {t('Exclusão de Atleta')}
        {deleteAthletehResult?.isError && <Alert severity="error">{deleteAthletehResult?.error?.data?.message}</Alert>}
      </DialogTitle>
      <DialogContent>
        <p>{`${t('Você está prestes a excluir a atleta')}: `}</p>
        <p>
          <strong>{`${athlete.name} #${athlete.number}.`}</strong>
        </p>
        <p>{t('Esta ação não pode ser desfeita.')}</p>
        <p>{t('Deseja continuar?')}</p>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} variant="outlined" size="small">
          {t('Cancelar')}
        </Button>
        <Button
          variant="contained"
          size="small"
          color="error"
          onClick={handleDeleteAthlete}
        >
          {t('Excluir atleta')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteAthleteDialog;
