import i18n from 'lang/i18n';
import {
  updateToken,
  useUpdateUserLanguageMutation,
} from 'shared/services';

export const useLanguageManager = () => {
  const [updateLanguage] = useUpdateUserLanguageMutation();

  const handleLanguageChange = async (user, lang) => {
    if (user) {
      try {
        if (user.lang !== lang) {
          const result = await updateLanguage({ lang });
          if (result.data?.token) {
            updateToken(result.data);
          }
        }
      } catch (error) {
        console.error('Erro ao atualizar o idioma:', error);
      }
    }
    i18n.changeLanguage(lang);
  };

  return { handleLanguageChange };
};
