import './Dialog.css';

import React from 'react';
import { Button } from 'shared/components';

class Dialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: this.props.open,
    };
  }

  hide = () => this.setState({ open: false });

  render() {
    return (
      this.state.open
        ? (
          <>
            <div className="modal-overlay" />
            <div className="modal">
              <button type="button" onClick={this.hide} className="modal-close">x</button>
              <div className="modal-content">
                <h2>{this.props.title}</h2>
                {this.props.children}
              </div>
              <div className="modal-footer">
                <Button className="btn-modal btn-modal-confirm" onButtonPress={this.hide}>OK</Button>
              </div>
            </div>
          </>
        )
        : null
    );
  }
}

export default Dialog;
