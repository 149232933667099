import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useParams } from 'react-router-dom';
import {
  IconLeftArrow,
  playerPositionOffensive,
  playerPositionDefensive,
  StepsCounter,
} from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { Header, HeaderTitle } from 'shared/layouts';
import { useFetchAthleteQuery, useUpdateAthleteMutation } from 'shared/services';

function AthleteEdit(props) {
  const team = useContext(TeamContext);
  const { t } = useTranslation();
  const { athleteId } = useParams();
  const [state, setState] = useState({
    name: '', number: '', positions: [], offense: false, defense: false, redirect: false, _id: '', backto: `/athletes/${athleteId}/tournaments`,
  });

  const [updateAthlete, updateAthleteResult] = useUpdateAthleteMutation();
  const {
    isSuccess,
    data: athlete,
  } = useFetchAthleteQuery({ team: team._id, athlete: athleteId });

  useEffect(() => {
    if (isSuccess) {
      setState((prevState) => ({ ...prevState, ...athlete }));
    }
  }, [isSuccess, athlete]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      _id, name, number, positions, offense, defense,
    } = state;
    if (!name || !positions) {
      setState((prevState) => ({ ...prevState, error: 'Preencha seu nome e posição' }));
    } else {
      const result = await updateAthlete({
        team: team._id,
        body: {
          _id, name, number, positions, offense, defense,
        },
      });
      if (result?.data?.success) {
        setState((prevState) => ({ ...prevState, redirect: `/athletes/${athleteId}/stats/tournaments` }));
      }
    }
  };

  const positionArray = [];

  if (state.offense) {
    positionArray.push({ label: t('Ataque'), isHeader: true });
    positionArray.push(...playerPositionOffensive);
  }

  if (state.defense) {
    if (state.offense) {
      positionArray.push({ isDivider: true }); // Adiciona o Divider após a seção de ataque
    }
    positionArray.push({ label: t('Defesa'), isHeader: true });
    positionArray.push(...playerPositionDefensive);
  }

  const handleInputChange = (event) => {
    const {
      name, value, type, checked,
    } = event.target;
    setState((prevState) => ({ ...prevState, [name]: (type === 'checkbox') ? checked : value }));
  };

  const handleMultiselectChange = (event) => {
    const { value } = event.target;
    setState((prevState) => ({ ...prevState, positions: value }));
  };

  const renderSelected = (selected) => {
    let rendered = selected.join(', ');

    if (rendered.length > 35) {
      rendered = rendered.substring(0, 30).concat('...');
    }

    return rendered;
  };

  return (
    <>
      {state.redirect && <Navigate to={state.redirect} />}
      <Header>
        <Box
          sx={{
            width: 'fit-content',
          }}
        >
          <Link to={`/athletes/${athleteId}/stats/tournaments`}>
            <IconButton size="small" aria-label="return" sx={{ padding: '0' }}>
              <IconLeftArrow color="#ffffff" />
            </IconButton>
          </Link>
        </Box>
        <HeaderTitle>FlagStats</HeaderTitle>
        <Box sx={{ width: '40px' }} />
      </Header>
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 8,
            marginBottom: '114px',
            display: 'flex',
            flexDirection: 'center',
            alignItems: 'center',
          }}
        >
          <Grid container direction="column" spacing={2}>
            {props.statusBar
              && (
                <Grid item>
                  <StepsCounter currentStep="3" totalSteps="3" />
                </Grid>
              )}

            <Grid item>
              {updateAthleteResult.isError && <Alert severity="error">{updateAthleteResult.error?.data?.message}</Alert>}
              <Typography component="h1" variant="h6">
                {t('Edição de Atleta')}
              </Typography>
            </Grid>

            <Grid
              item
            >
              <Box
                id="athlete-form"
                component="form"
                onSubmit={handleSubmit}
              >
                <Grid item>
                  <TextField
                    fullWidth
                    required
                    margin="normal"
                    variant="filled"
                    id="name"
                    label={t('Nome')}
                    name="name"
                    value={state.name}
                    onChange={handleInputChange}
                    InputProps={{ disableUnderline: true }}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    required
                    variant="filled"
                    id="number"
                    label={t('Número')}
                    name="number"
                    value={state.number}
                    onChange={handleInputChange}
                    InputProps={{ disableUnderline: true }}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        name="offense"
                        checked={state.offense}
                        onChange={handleInputChange}
                      />
                    )}
                    label={t('Ataque')}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        name="defense"
                        checked={state.defense}
                        onChange={handleInputChange}
                      />
                    )}
                    label={t('Defesa')}
                  />

                  <Box
                    sx={{ mt: 3 }}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        mb: 2,
                      }}
                      component="p"
                      variant="caption"
                    >
                      <strong>{t('Posição')}</strong>
                      {' '}
                      {t('(escolha quantas forem necessárias)')}
                    </Typography>
                    <FormControl variant="filled" fullWidth>
                      <InputLabel id="position">{t('Posição')}</InputLabel>
                      <Select
                        labelId="position"
                        id="position"
                        name="positions"
                        multiple
                        value={state.positions}
                        renderValue={(selected) => renderSelected(selected)}
                        onChange={handleMultiselectChange}
                        disableUnderline
                      >
                        {((state.offense || state.defense) ? positionArray : [
                          { label: t('Selecione Ataque/Defesa para exibir opções.'), isDisabled: true },
                        ]).map((options) => {
                          if (options.isHeader) {
                            return (
                              <ListSubheader key={options.label}>{options.label}</ListSubheader>
                            );
                          }
                          if (options.isDivider) {
                            return <Divider key="divider" />;
                          }
                          if (options.isDisabled) {
                            return (
                              <MenuItem key={options.label} disabled>
                                {options.label}
                              </MenuItem>
                            );
                          }
                          return (
                            <MenuItem key={options.value} value={options.label}>
                              <Checkbox checked={state.positions.includes(options.label)} />
                              <ListItemText primary={options.label} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box
        sx={{
          position: 'fixed',
          bottom: '0',
          left: '0',
          width: '100%',
        }}
      >
        <Button
          type="submit"
          form="athlete-form"
          fullWidth
          variant="contained"
          color="primary"
        >
          {t('save')}
        </Button>
      </Box>
    </>
  );
}

export default AthleteEdit;
