import { Route, Routes } from 'react-router-dom';
import { MatchView, StatsView } from 'pages/';
import {
  PageNotFound,
  StatsListScrimmageMatches,
  StatsListPractice,
  StatsListPracticeMatches,
  StatsListTournamentMatches,
  StatsListTournaments,
} from 'shared/components';

export function StatsRoutes() {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route path="matches/:matchId/stats" element={<MatchView />} />
      <Route path=":teamId/" element={<StatsView />}>
        <Route index element={<StatsListTournaments />} />
        <Route path="tournaments" element={<StatsListTournaments />} />
        <Route path="scrimmage" element={<StatsListScrimmageMatches />} />
        <Route path="practice" element={<StatsListPractice />} />
      </Route>
      <Route path=":teamId/tournaments/:tournamentId" element={<StatsListTournamentMatches />} />
      <Route path=":teamId/practice/matches" element={<StatsListPracticeMatches />} />
    </Routes>
  );
}
