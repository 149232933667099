import {
  Alert, Box, Button, Container, Grid, TextField, Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TeamContext } from 'shared/contexts';
import { GenericHeader } from 'shared/layouts';
import { useCreateTeamMutation } from 'shared/services';

export function Team() {
  const team = useContext(TeamContext);
  const { t } = useTranslation();
  const [state, setState] = useState({ name: team?.name ?? '', error: '' });
  const [createTeam, createTeamResult] = useCreateTeamMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name } = state;
    if (!name) {
      setState((prevState) => ({ ...prevState, error: t('Preencha o nome do seu time') }));
    } else {
      const result = await createTeam({ name });
      if (result?.data?.success) {
        window.location = '/';
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      <GenericHeader title={t('Seu time')} linkBack="/" />
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'center',
            alignItems: 'center',
          }}
          id="team-form"
          component="form"
          onSubmit={handleSubmit}
        >
          <Grid container direction="column">
            <Grid item>
              {createTeamResult.isError && <Alert severity="error">{createTeamResult.error?.data?.message}</Alert>}
              <Typography component="h1" variant="h6">
                {t('Sobre seu time')}
              </Typography>
              {/* <Typography component="h2" variant="caption">
                                Agora defina foto do escudo e nome do time.
                                {this.error && <p className="error-msg">{this.error}</p>}
                            </Typography> */}
            </Grid>

            {/* <Grid item sx={{ mt: 2 }}>
                            <Avatar sx={{ width: '72px', height: '72px' }}>
                                <IconTeam />
                            </Avatar>
                            <Link
                                variant="text"
                                component="label"
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                        mt: 2
                                    }}
                                >
                                    <IconUpload />
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            ml: 1
                                        }}
                                        component="p"
                                        variant='caption'
                                    >
                                        Upload da imagem do escudo
                                    </Typography>
                                </Box>
                                <input
                                    type="file"
                                    hidden
                                />
                            </Link>
                        </Grid> */}

            <Grid
              item
              sx={{
                mt: 4,
              }}
            >
              <TextField
                required
                fullWidth
                id="name"
                label={t('Nome do time')}
                name="name"
                variant="filled"
                value={state.name}
                onChange={handleInputChange}
                InputProps={{ disableUnderline: true }}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box
        sx={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          width: '100%',
          zIndex: '2',
        }}
      >
        <Button
          type="submit"
          form="team-form"
          fullWidth
          variant="contained"
          color="primary"
        >
          {t('Salvar')}
        </Button>
      </Box>
    </>
  );
}

export default Team;
