import { getToken } from 'shared/services';

export async function csvDownloadHandler(url, fileName) {
  try {
    const baseURL = process.env.REACT_APP_API_URL;
    const token = getToken();

    const response = await fetch(baseURL + url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });

    if (!response.ok) {
      throw new Error(`Erro na requisição: ${response.status} ${response.statusText}`);
    }

    const csvContent = await response.text();

    if (!csvContent) {
      console.error('O conteúdo do CSV está vazio.');
      return;
    }

    const blob = new Blob([csvContent], { type: 'text/csv' });

    const urlBlob = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = urlBlob;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(urlBlob);
  } catch (error) {
    console.error('Erro ao baixar o CSV:', error);
    throw error;
  }
}
