// src/helpers/useMetaDescription.js
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useMetaDescription = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const description = t('O FlagStats é um aplicativo para coletar e mostrar dados de forma clara para times de Flag Football, para que os times possam acompanhar sua evolução e tomar decisões baseados em estatísticas.');
    let metaDescriptionTag = document.querySelector('meta[name="description"]');

    if (!metaDescriptionTag) {
      metaDescriptionTag = document.createElement('meta');
      metaDescriptionTag.name = 'description';
      document.head.appendChild(metaDescriptionTag);
    }

    metaDescriptionTag.setAttribute('content', description);
  }, [t]);
};
