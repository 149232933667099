import { useContext } from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EmptyPage } from 'pages';
import { Loading, MatchCard } from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { useFetchMatchesByTypeQuery } from 'shared/services';
import { csvDownloadHandler } from 'shared/helpers';

export function StatsListScrimmageMatches() {
  // @todo: id da atleta está fixo
  const team = useContext(TeamContext);
  const { t } = useTranslation();
  const {
    isLoading,
    isSuccess,
    isError,
    data: scrimmageMatches,
  } = useFetchMatchesByTypeQuery({ team: team._id, type: 'scrimmage' }, { refetchOnMountOrArgChange: true });

  const handleExport = async () => {
    try {
      const url = `/api/team/${team._id}/matches/scrimmage/stats/export`;

      await csvDownloadHandler(url, 'scrimmage_stats.csv');
    } catch (error) {
      console.error('Erro ao exportar CSV:', error);
    }
  };

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <EmptyPage title={t('Ainda não há informações')} content={t('Adicione um novo Jogo de Campeonato, Amistoso ou Treino no botão Criar.')} />;
  } else if (isSuccess && scrimmageMatches.length > 0) {
    content = scrimmageMatches.map((scrimmage) => <MatchCard key={`match-card-${scrimmage._id}`} match={scrimmage} linkTo={`/matches/${scrimmage._id}/stats`} actionLabel={t('Ver Estatísticas')} showIcon />);
  } else {
    content = <EmptyPage title={t('Ainda não há informações')} content={t('Adicione um novo Jogo de Campeonato, Amistoso ou Treino no botão Criar.')} />;
  }

  return (
    <Box>
      <Button
        className="ignore-unselect-playCard"
        variant="outlined"
        color="primary"
        fullWidth
        onClick={handleExport}
      >
        {t('Exportar estatísticas')}
      </Button>
      {content}
    </Box>
  );
}

export default StatsListScrimmageMatches;
