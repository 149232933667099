import './Question.css';

import ShortcutIcon from '@mui/icons-material/Shortcut';
import { Box, Button, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

function Question(props) {
  const { t } = useTranslation();
  const [showAll, setShowAll] = React.useState(false);

  return (
    <div className="question_wrapper">
      <div className="question">{`${t(props.question.label)}`}</div>
      {
        props.question.actions.map((action) => {
          const questionAnswer = String(props.question.answer ?? '');
          const actionValue = String(action.value ?? '');
          const sameAnswer = props.question.answered && questionAnswer === actionValue;

          function getColor() {
            return sameAnswer ? 'primary' : 'secondary';
          }

          function getBoxShadow() {
            return sameAnswer ? 8 : 2;
          }

          switch (action.type) {
            case 'athletes': {
              const handleToggleShowAll = () => setShowAll((prev) => !prev);

              const athletesFiltered = props.athletes.filter((athlete) => athlete[action.team]);

              const athletesWithPosition = athletesFiltered.filter(
                (athlete) => athlete.positions.includes(action.position),
              );
              const athletesWithoutPosition = athletesFiltered.filter(
                (athlete) => !athlete.positions.includes(action.position),
              );

              const orderedAthletes = action.position
                ? [...athletesWithPosition, ...athletesWithoutPosition]
                : athletesFiltered;

              return (
                <Box
                  key={`${props.question.field}-${actionValue}`}
                  sx={{ display: 'flex', flexWrap: 'wrap' }}
                >
                  {orderedAthletes.map((athlete) => (
                    <div
                      className="btn-wrapper"
                      key={`${props.question.field}-${athlete._id}`}
                      style={{
                        display:
                          action.position
                          && !athlete.positions.includes(action.position) && !showAll
                            ? 'none'
                            : 'block',
                      }}
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        question_id={props.question.id}
                        value={athlete._id}
                        field={props.question.field}
                        next={action.next}
                        onClick={props.onButtonPress}
                        color={
                          props.question.answered
                            && props.question.answer === athlete._id
                            ? 'primary'
                            : 'secondary'
                        }
                        sx={{
                          boxShadow:
                            props.question.answered
                            && props.question.answer === athlete._id ? 8 : 2,
                          height: 'unset',
                          minHeight: '100%',
                        }}
                      >
                        {athlete.number}
                        <br />
                        {athlete.name}
                      </Button>
                    </div>
                  ))}

                  {action.position && athletesWithoutPosition.length > 0 && (
                    <div className="btn-wrapper ">
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={handleToggleShowAll}
                        color="primary"
                        sx={{
                          borderColor: '#004D99',
                          color: '#004D99',
                          boxShadow: 2,
                          height: 'unset',
                          minHeight: '100%',
                        }}
                      >
                        {showAll ? t('Ver menos') : t('Ver mais')}
                      </Button>
                    </div>
                  )}
                </Box>
              );
            }
            case 'text':
            case 'number':
              return (
                <div className="btn-wrapper btn-type-number" key={`${props.question.field}-wrapper`}>
                  <TextField
                    key={props.question.field}
                    id={props.question.field}
                    label={t(props.label)}
                    name={props.question.field}
                    value={props.play[props.question.field] || ''}
                    type={action.type}
                    onChange={props.handleInputChange}
                    margin="normal"
                    fullWidth
                    color={getColor()}
                    sx={{ boxShadow: getBoxShadow() }}
                  />
                  <Button
                    key={`${props.question.field}-btn`}
                    fullWidth
                    variant="contained"
                    question_id={props.question.id}
                    next={action.next}
                    onClick={props.onButtonPress}
                    color={getColor()}
                    actiontype={action.type}
                    sx={{ boxShadow: getBoxShadow() }}
                  >
                    {props.coach
                      ? t('Calcular')
                      : t('continuar')}
                  </Button>
                </div>
              );
            case 'skip':
              return (
                <Button
                  question_id={props.question.id}
                  key={`${props.question.field}-skip`}
                  className="skip"
                  value={action.value}
                  field={props.question.field}
                  next={action.next}
                  onClick={props.onButtonPress}
                  variant={sameAnswer ? 'contained' : ''}
                >
                  <ShortcutIcon />
                  {' '}
                  {t(action.label)}
                </Button>
              );
            default:
              return (
                <div key={`btnwrapper_${props.question.field}_${action.value}`} className="btn-wrapper">
                  <Button
                    fullWidth
                    variant="contained"
                    question_id={props.question.id}
                    key={`btn_${props.question.field}_${action.value}`}
                    value={action.value}
                    answer={String(action.value)}
                    set_values={action.set_values}
                    field={props.question.field}
                    next={action.next}
                    onClick={props.onButtonPress}
                    color={getColor()}
                    sx={{ boxShadow: getBoxShadow() }}
                  >
                    {t(action.label)}
                  </Button>
                </div>
              );
          }
        })
      }
    </div>
  );
}

export default Question;
