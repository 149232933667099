import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconStats, TournamentLabel } from 'shared/components';

function TournamentStatsCard(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const resumeTitleStyle = {
    fontFamily: 'Mulish',
    fontWeight: '400',
    lineHeight: '24px',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    color: '#50729A',
  };

  const resumeValueStyle = {
    fontFamily: 'Mulish',
    fontWeight: '800',
    lineHeight: '24px',
    fontSize: '24px',
    display: 'flex',
    alignItems: 'center',
    color: '#3E4A59',
  };

  const defenseStats = (
    <Box sx={{
      display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '5px 10px', padding: '5px 10px',
    }}
    >
      <Box>
        <Typography variant="subtitle2" sx={resumeTitleStyle}>
          Tackles
        </Typography>
        <Typography variant="body1" sx={resumeValueStyle}>
          {props?.stats?.defense?.end_plays?.tackle || 0}
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle2" sx={resumeTitleStyle}>
          {t('Interceptações')}
        </Typography>
        <Typography variant="body1" sx={resumeValueStyle}>
          {props?.stats?.defense?.end_plays?.intercept || 0}
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle2" sx={resumeTitleStyle}>
          Sacks
        </Typography>
        <Typography variant="body1" sx={resumeValueStyle}>
          {props?.stats?.defense?.end_plays?.sack || 0}
        </Typography>
      </Box>
    </Box>
  );

  const offenseStats = (
    <Box sx={{
      display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '5px 10px', padding: '5px 10px',
    }}
    >
      <Box>
        <Typography variant="subtitle2" sx={resumeTitleStyle}>
          Touchdowns
        </Typography>
        <Typography variant="body1" sx={resumeValueStyle}>
          {props?.stats?.offense?.td || 0}
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle2" sx={resumeTitleStyle}>
          First
        </Typography>
        <Typography variant="body1" sx={resumeValueStyle}>
          {props?.stats?.offense?.first || 0}
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle2" sx={resumeTitleStyle}>
          {t('Jardas')}
        </Typography>
        <Typography variant="body1" sx={resumeValueStyle}>
          {props?.stats?.offense?.yards || 0}
        </Typography>
      </Box>
    </Box>
  );

  const content = (
    <>
      {props?.stats?.offense && offenseStats}
      {props?.stats?.defense && defenseStats}
    </>
  );

  return (
    <Card sx={{ margin: '20px 0' }}>
      <CardHeader
        sx={{ paddingBottom: '4px' }}
        title={(
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <TournamentLabel label={props.label} labelWidth="230px" />
            <Typography
              variant="body1"
              color="#0080FF"
              sx={{
                fontFamily: 'Mulish',
                fontWeight: '600',
                lineHeight: '24px',
                fontSize: '14px',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              {props.matches}
              {' '}
              {t('Jogos')}
            </Typography>
          </Box>
        )}
      />
      <CardContent sx={{ paddingTop: '4px' }}>
        {content}
      </CardContent>
      <Divider />
      <CardActions
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <Button
          onClick={() => navigate(`${props.backLink}`)}
          sx={{ height: '32px' }}
        >
          <IconStats color="#0066CC" />
          <Typography
            variant="body2"
            sx={{
              display: 'inline-block',
              fontFamily: 'Mulish',
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: '14px',
              lineHeight: '24px',
              marginLeft: '5px',
            }}
          >
            {t('Ver Estatísticas')}
          </Typography>
        </Button>
      </CardActions>
    </Card>
  );
}

export default TournamentStatsCard;
