import React, { useState } from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useDeleteMatchMutation } from 'shared/services';
import { useNavigate } from 'react-router-dom';

export function DeleteMatchDialog({
  openDeleteMatchDialog,
  handleClose,
  team,
  match,
  type,
  matchTypeLabel,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [setState] = useState({
    redirect: false,
  });
  const [deleteMatch, deleteMatchResult] = useDeleteMatchMutation();
  const formatedDate = match?.date ? DateTime.fromISO(match.date).toFormat('dd/LL・ HH:mm ・ ') : '';

  const handleDeleteMatch = async (e) => {
    e.preventDefault();
    const teamId = team._id;
    if (!match?._id || !teamId) {
      setState((prevState) => ({ ...prevState, error: t('Partida não encontrada.') }));
    } else {
      const result = await deleteMatch({
        team: teamId,
        body: {
          match: match._id,
        },
      });
      if (result?.data?.success) {
        navigate(`/stats/${teamId}/${type}`);
      }
    }
  };

  return (
    <Dialog
      open={openDeleteMatchDialog}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {t('Exclusão de partida')}
        {deleteMatchResult.isError && <Alert severity="error">{deleteMatchResult.error?.data?.messages}</Alert>}
      </DialogTitle>
      <DialogContent>
        <p>{`${t('Você está prestes a excluir a partida')}: `}</p>
        <p>
          <strong>
            {match.type === 'tournament'
              ? `${match.tournament} - `
              : `${matchTypeLabel} - `}
            {`${formatedDate} ${match?.local}.`}
          </strong>
        </p>
        <p>{t('Esta ação não pode ser desfeita.')}</p>
        <p>{t('Deseja continuar?')}</p>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} variant="outlined" size="small">
          {t('Cancelar')}
        </Button>
        <Button
          variant="contained"
          size="small"
          color="error"
          onClick={handleDeleteMatch}
        >
          {t('Excluir partida')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteMatchDialog;
