import { Typography } from '@mui/material';
import React from 'react';

function Logo(props) {
  return (
    <Typography
      sx={{
        fontFamily: 'Red Hat Display',
        fontWeight: 'lighter',
        color: props.color ? props.color : '#000',
      }}
      component="h1"
      variant={props.variant ? props.variant : 'h3'}
    >
      Flag
      <Typography
        sx={{
          color: props.color ? props.color : 'primary.main', fontWeight: 'bold',
        }}
        variant={props.variant ? props.variant : 'h3'}
        component="span"
      >
        Stats
      </Typography>
    </Typography>
  );
}

export default Logo;
