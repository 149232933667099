import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  DeleteAthleteDialog,
  IconAthlete,
  IconCalendar,
  IconLeftArrow,
} from 'shared/components';
import { TeamContext } from 'shared/contexts';

export function AthleteHeader(props) {
  const team = useContext(TeamContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    anchorEl: null,
    openOptions: false,
    openDeleteAthleteDialog: false,
    redirect: false,
  });

  // Options
  const handleOptionsClick = (event) => {
    setState((prevState) => ({ ...prevState, openOptions: true, anchorEl: event.currentTarget }));
  };
  const handleOptionsClose = () => {
    setState((prevState) => ({ ...prevState, openOptions: false }));
  };
  const handleOptionsMenuItemClick = (link) => {
    navigate(link);
    handleOptionsClose();
  };

  const handleClickopenDeleteDialog = () => {
    setState((prevState) => ({ ...prevState, openDeleteAthleteDialog: true }));
  };

  const handleCloseDeleteAthleteDialog = () => {
    setState((prevState) => ({ ...prevState, openDeleteAthleteDialog: false }));
  };

  let athleteTeamPosition;
  if (props?.athlete?.offense && props?.athlete?.defense) {
    athleteTeamPosition = `${t('Ataque')} / ${t('Defesa')}`;
  } else if (props?.athlete?.offense) {
    athleteTeamPosition = t('Ataque');
  } else {
    athleteTeamPosition = t('Defesa');
  }

  return (
    <>
      {state.redirect && <Navigate to={state.redirect} />}
      <Box
        sx={{
          width: '100%',
          minHeight: '260px',
          padding: '20px',
          background: 'linear-gradient(137.13deg, #004D99 31.91%, #0080FF 140.67%)',
          color: '#ffffff',
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              width: 'fit-content',
            }}
          >
            <Link to={props.backto}>
              <IconButton size="small" aria-label="return" sx={{ padding: '0' }}>
                <IconLeftArrow color="#ffffff" />
              </IconButton>
            </Link>
          </Box>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '24px',
              color: '#FFFFFF',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {t('Estatísticas de Atleta')}
          </Typography>
          <Box>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={state.openOptions ? 'long-menu' : undefined}
              aria-expanded={state.openOptions ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleOptionsClick}
            >
              <MoreVertIcon sx={{ color: '#ffffff' }} />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={state.anchorEl}
              open={state.openOptions}
              onClose={handleOptionsClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {props?.match && (
                <MenuItem onClick={() => handleOptionsMenuItemClick(`/athletes/${props?.athlete?._id}/stats/match_share/${props?.match?._id}`)} disableRipple>
                  <ListItemIcon>
                    <ScreenShareIcon />
                  </ListItemIcon>
                  {t('Stats compartilháveis')}
                </MenuItem>
              )}
              <MenuItem onClick={() => handleOptionsMenuItemClick(`/athletes/${props?.athlete?._id}/edit`)} disableRipple>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                {t('Editar atleta')}
              </MenuItem>
              <MenuItem onClick={handleClickopenDeleteDialog} disableRipple>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                {t('Excluir atleta')}
              </MenuItem>
            </Menu>
          </Box>

        </Box>
        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', margin: '16px 0' }}>
          <Grid item>
            <Avatar
              sx={{
                width: '80px',
                height: '80px',
              }}
              alt={props?.athlete?.name}
              src="/broken-image.jpg"
            >
              <IconAthlete />
            </Avatar>
          </Grid>
          <Grid item sx={{ flexGrow: '1' }}>
            <Typography variant="h6" align="left">
              {props?.athlete?.name}
            </Typography>
            <Grid container sx={{ marginBottom: '16px' }}>
              <Grid item xs={2} md={2}>
                <Box
                  sx={{
                    width: '32px',
                    height: '24px',
                    background: '#EFF8F7',
                    borderRadius: '4px',
                    padding: '0 5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#0066CC',
                    fontSize: '12px',
                    fontWeight: '700',
                  }}
                >
                  #
                  {props?.athlete?.number}
                </Box>
              </Grid>
              <Grid item xs={9} md={9}>
                <Typography variant="subtitle1" align="left">
                  {athleteTeamPosition}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
              <IconCalendar color="#ffffff" />
              <Box sx={{ marginLeft: '8px' }}>
                {t('Início no time: {{date}}', { date: '02-2017' })}
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {
            props.athlete?.positions.map((position) => (
              <Typography
                key={`key-${position}`}
                component="span"
                variant="caption"
                sx={{
                  display: 'inline-block',
                  padding: '0px 8px',
                  margin: '5px',
                  background: 'rgba(245, 246, 250, 0.2)',
                  borderRadius: '4px',
                  fontFamily: 'Mulish',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '24px',
                  textAlign: 'center',
                }}
              >
                {position}
              </Typography>
            ))
          }
        </Box>
        {props?.athlete
          && (
            <DeleteAthleteDialog
              openDeleteAthleteDialog={state.openDeleteAthleteDialog}
              handleClose={handleCloseDeleteAthleteDialog}
              teamId={team?._id}
              athlete={props.athlete}
            />
          )}
      </Box>
    </>
  );
}

export default AthleteHeader;
