import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconStats, IconTeamDefault } from 'shared/components';
import { TeamContext } from 'shared/contexts';

export function MatchCard(props) {
  const navigate = useNavigate();
  const team = useContext(TeamContext);
  const { t } = useTranslation();

  const headerTextStyle = {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWweight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#9DB3CC',
  };

  const formatedDate = props?.match?.date ? DateTime.fromISO(props.match.date).toFormat('dd/LL・ HH:mm ・ ') : '';

  const content = (
    <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
      <Grid
        item
        xs={4}
      >
        <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item xs={9} md={9}>
            <Typography
              variant="subtitle2"
              align="center"
              sx={{
                fontFamily: 'Mulish',
                fontWeight: '600',
                lineHeight: '130%',
                textAlign: 'center',
                fontSize: '14px',
                color: '#3E4A59',
              }}
            >
              {team.name}
            </Typography>
          </Grid>
          <Grid item xs={3} md={3}>
            <Avatar
              sx={{
                width: '24px',
                height: '24px',
              }}
              alt=""
              src="/broken-image.jpg"
            >
              <IconTeamDefault />
            </Avatar>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            marginTop: '8px',
            textAlign: 'center',
            fontSize: '24px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '24px',
              lineHeight: '24px',
              color: '#3E4A59',
            }}
          >
            {props.match?.score_team || '0'}
            {' '}
            X
            {' '}
            {props.match?.score_adversary || '0'}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item xs={3} md={3}>
            <Avatar
              sx={{
                width: '24px',
                height: '24px',
              }}
              alt=""
              src="/broken-image.jpg"
            >
              <IconTeamDefault />
            </Avatar>
          </Grid>
          <Grid item xs={9} md={9}>
            <Typography
              variant="subtitle2"
              align="center"
              sx={{
                fontFamily: 'Mulish',
                fontWeight: '600',
                lineHeight: '130%',
                textAlign: 'center',
                fontSize: '14px',
                color: '#3E4A59',
              }}
            >
              {props.match?._id ? props.match?.adversary : t('Adversário')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const action = props.linkTo && (
    <>
      <Divider />
      <CardActions
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <Button
          onClick={() => navigate(props.linkTo)}
          sx={{ height: '32px' }}
        >
          {props.showIcon && <IconStats color="#0066CC" />}
          <Typography
            variant="body2"
            sx={{
              display: 'inline-block',
              fontFamily: 'Mulish',
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: '14px',
              lineHeight: '24px',
              marginLeft: '5px',
            }}
          >
            {props.actionLabel}
          </Typography>
        </Button>
      </CardActions>
    </>
  );

  return (
    <Card
      sx={{
        margin: '24px 0',
        padding: '10px',
      }}
    >
      <CardHeader
        title={(
          <Typography variant="body1" sx={headerTextStyle}>
            {formatedDate}
            {props.match?.local}
          </Typography>
        )}
      />
      <CardContent>
        {content}
      </CardContent>
      {action}
    </Card>
  );
}

export default MatchCard;
