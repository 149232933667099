import { Typography } from '@mui/material';

export function HeaderTitle(props) {
  return (
    <Typography
      variant="subtitle2"
      sx={{
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FFFFFF',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {props.children}
    </Typography>
  );
}

export default HeaderTitle;
