import React from 'react';
import {
  Card,
} from '@mui/material';
import {
  IconWistle,
  IconBall,
  IconShoe,
  IconStopWatch,
} from 'shared/components';

function EventCard(props) {
  const cardStyle = (props.cardStyle) ? props.cardStyle : '';

  function getIcon(icon) {
    switch (icon) {
      case 'ball':
        return <IconBall customSize="24" />;
      case 'wistle':
        return <IconWistle customSize="24" color="#FFF" />;
      case 'shoe':
        return <IconShoe customSize="24" color="#FFF" />;
      case 'watch':
        return <IconStopWatch customSize="24" color="#FFF" />;
      default:
        return false;
    }
  }

  function mountIcon(icon) {
    const elIcon = getIcon(icon);
    if (icon) {
      return (
        <div className="icon-event">
          {elIcon}
        </div>
      );
    }
    return false;
  }

  return (
    <Card
      sx={{
        width: '184px',
        background: '#FFFFFF',
        boxShadow: '0px 8px 32px rgba(0, 102, 204, 0.16)',
        borderRadius: '4px',
      }}
      className={`afterCard ${cardStyle}`}
    >
      {mountIcon(props?.icon)}
      <div style={{ width: '100%' }}>
        {props.label}
      </div>
    </Card>
  );
}

export default EventCard;
