import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { IconStats, IconTimeline } from 'shared/components';

function TimelineStatsMenu(props) {
  const location = useLocation();
  const { t } = useTranslation();
  const { color } = props;
  const tournamentActive = !(location.pathname.split('/').length > 3);
  const listActiveStyle = {
    borderBottom: `1px solid ${color}`, color: `${color}`, padding: '10px', boxSizing: 'border-box',
  };
  const listStyle = { color: '#50729A', padding: '10px', boxSizing: 'border-box' };

  return (
    <>
      <Box
        sx={{
          marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-around',
        }}
      >
        <NavLink
          to={`/matches/${props.matchId}/timeline`}
          style={({ isActive }) => (isActive || tournamentActive ? listActiveStyle : listStyle)}
        >
          <IconTimeline />
          <Typography
            variant="body2"
            sx={{
              display: 'inline-block',
              fontFamily: 'Mulish',
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: '16px',
              lineHeight: '24px',
              marginLeft: '5px',
            }}
          >
            Timeline
          </Typography>
        </NavLink>
        <NavLink
          to={`/matches/${props.matchId}/stats`}
          style={({ isActive }) => (isActive ? listActiveStyle : listStyle)}
        >
          <IconStats />
          <Typography
            variant="body2"
            sx={{
              display: 'inline-block',
              fontFamily: 'Mulish',
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: '16px',
              lineHeight: '24px',
              marginLeft: '5px',
            }}
          >
            {t('Estatísticas')}
          </Typography>
        </NavLink>
      </Box>
      <Divider />

    </>
  );
}

export default TimelineStatsMenu;
