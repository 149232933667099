import './Button.css';

import { Button } from '@mui/material';
import React from 'react';

function CButton(props) {
  const {
    className, onButtonPress, backgroundColor, ...rest
  } = props;
  return (
    <Button
      type="submit"
      variant="contained"
      className={`circleButton ${props.className ? props.className : ''}`}
      fullWidth
      sx={{
        backgroundColor: backgroundColor || 'primary',
      }}
      /* TODO: pesquisar alternativa para passar
      atributos sem utilizar spreding de props */
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {props.children}
    </Button>
  );
}

export default CButton;
