import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import {
  IconLeftArrow,
  playerPositionDefensive,
  playerPositionOffensive,
  // StepsCounter,
  // IconUpload,
  // IconAthlete,
} from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { Header, HeaderTitle } from 'shared/layouts';
import { useCreateAthleteMutation } from 'shared/services';

export function RegisterAthleteForm(props) {
  const team = useContext(TeamContext);
  const { t } = useTranslation();
  const [state, setState] = useState({
    name: '', number: '', positions: [], offense: false, defense: false, redirect: false,
  });
  const [createAthlete, createAthleteResult] = useCreateAthleteMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      name, number, positions, offense, defense,
    } = state;
    if (!name || !positions) {
      setState((prevState) => ({ ...prevState, error: t('Preencha seu nome e posição') }));
    } else {
      const result = await createAthlete({
        team: team._id,
        body: {
          name, number, positions, offense, defense,
        },
      });
      if (result?.data?.success) {
        setState((prevState) => ({ ...prevState, redirect: props.redirect }));
      }
    }
  };

  const positionArray = [];

  if (state.offense) {
    positionArray.push({ label: t('Ataque'), isHeader: true });
    positionArray.push(...playerPositionOffensive);
  }

  if (state.defense) {
    if (state.offense) {
      positionArray.push({ isDivider: true }); // Adiciona o Divider após a seção de ataque
    }
    positionArray.push({ label: t('Defesa'), isHeader: true });
    positionArray.push(...playerPositionDefensive);
  }

  const handleInputChange = (event) => {
    const {
      name, value, type, checked,
    } = event.target;
    setState((prevState) => ({ ...prevState, [name]: (type === 'checkbox') ? checked : value }));
  };

  const handleMultiselectChange = (event) => {
    const { value } = event.target;
    setState((prevState) => ({ ...prevState, positions: value }));
  };

  const renderSelected = (selected) => {
    let rendered = selected.join(', ');

    if (rendered.length > 35) {
      rendered = rendered.substring(0, 30).concat('...');
    }

    return rendered;
  };

  return (
    <>
      {state.redirect && <Navigate to={state.redirect} />}
      <Header>
        <Box
          sx={{
            width: 'fit-content',
          }}
        >
          <Link to={props.redirect}>
            <IconButton size="small" aria-label="return" sx={{ padding: '0' }}>
              <IconLeftArrow color="#ffffff" />
            </IconButton>
          </Link>
        </Box>
        <HeaderTitle>FlagStats</HeaderTitle>
        <Box sx={{ width: '40px' }} />
      </Header>
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 8,
            marginBottom: '114px',
            display: 'flex',
            flexDirection: 'center',
            alignItems: 'center',
          }}
        >
          <Grid container direction="column" spacing={2}>
            {props.statusBar}

            <Grid item>
              {createAthleteResult.isError && <Alert severity="error">{createAthleteResult.error?.data?.message}</Alert>}
              <Typography component="h1" variant="h6">
                {t('Atletas')}
              </Typography>
              <Typography component="h2" variant="caption">
                {t('Cadastre atletas aqui')}
                {state.error && <p className="error-msg">{state.error}</p>}
              </Typography>
            </Grid>

            <Grid
              item
            >
              <Box
                id="athlete-form"
                component="form"
                onSubmit={handleSubmit}
              >
                {/* <Grid item sx={{mt:2}}>
                                <Avatar sx={{width: '72px', height: '72px'}}>
                                    <IconAthlete />
                                </Avatar>
                                <Link
                                    variant="text"
                                    component="label"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'start',
                                            mt:2
                                        }}
                                    >
                                        <IconUpload/>
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                ml: 1
                                            }}
                                            component="p"
                                            variant='caption'
                                        >
                                            Upload da imagem da atleta
                                        </Typography>
                                    </Box>
                                    <input
                                        type="file"
                                        hidden
                                    />
                                </Link>
                            </Grid> */}

                <Grid item>
                  <TextField
                    fullWidth
                    required
                    margin="normal"
                    variant="filled"
                    id="name"
                    label={t('Nome')}
                    name="name"
                    value={state.name}
                    onChange={handleInputChange}
                    InputProps={{ disableUnderline: true }}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    required
                    variant="filled"
                    id="number"
                    label={t('Número')}
                    name="number"
                    value={state.number}
                    onChange={handleInputChange}
                    InputProps={{ disableUnderline: true }}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        name="offense"
                        checked={state.offense}
                        onChange={handleInputChange}
                      />
                                          )}
                    label={t('Ataque')}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        name="defense"
                        checked={state.defense}
                        onChange={handleInputChange}
                      />
                                          )}
                    label={t('Defesa')}
                  />

                  <Box
                    sx={{ mt: 3 }}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        mb: 2,
                      }}
                      component="p"
                      variant="caption"
                    >
                      <strong>{t('Posição')}</strong>
                      {' '}
                      {t('(escolha quantas forem necessárias)')}
                    </Typography>
                    <FormControl variant="filled" fullWidth>
                      <InputLabel id="position">{t('Posição')}</InputLabel>
                      {/* <Select
                        labelId="position"
                        id="position"
                        name="positions"
                        multiple
                        value={state.positions}
                        renderValue={(selected) => renderSelected(selected)}
                        onChange={handleMultiselectChange}
                        disableUnderline
                        MenuProps={{ PaperProps: { sx: { maxHeight: '50vh' } } }}
                      >
                        {playerPositionOffensive.map((options) => (
                          <MenuItem key={options.value} value={options.label}>
                            <Checkbox checked={state.positions.includes(options.label)} />
                            <ListItemText primary={options.label} />
                          </MenuItem>
                        ))}
                      </Select> */}
                      <Select
                        labelId="position"
                        id="position"
                        name="positions"
                        multiple
                        value={state.positions}
                        renderValue={(selected) => renderSelected(selected)}
                        onChange={handleMultiselectChange}
                        disableUnderline
                      >
                        {((state.offense || state.defense) ? positionArray : [
                          { label: t('Selecione Ataque/Defesa para exibir opções.'), isDisabled: true },
                        ]).map((options) => {
                          if (options.isHeader) {
                            return (
                              <ListSubheader key={options.label}>{options.label}</ListSubheader>
                            );
                          }
                          if (options.isDivider) {
                            return <Divider key="divider" />;
                          }
                          if (options.isDisabled) {
                            return (
                              <MenuItem key={options.label} disabled>
                                {options.label}
                              </MenuItem>
                            );
                          }
                          return (
                            <MenuItem key={options.value} value={options.label}>
                              <Checkbox checked={state.positions.includes(options.label)} />
                              <ListItemText primary={options.label} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box
        sx={{
          position: 'fixed',
          bottom: '0',
          left: '0',
          width: '100%',
          zIndex: '2',
        }}
      >
        <Button
          type="submit"
          form="athlete-form"
          fullWidth
          variant="contained"
          color="primary"
        >
          {t('Salvar')}
        </Button>
      </Box>
    </>
  );
}

export default RegisterAthleteForm;
