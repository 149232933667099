import { Box } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EmptyPage } from 'pages';
import { Loading, MatchCard, TournamentLabel } from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { useFetchTeamTournamentMatchesQuery } from 'shared/services';

export function TeamListTournamentMatches() {
  const team = useContext(TeamContext);
  const { tournamentId } = useParams();
  const { t } = useTranslation();
  const {
    isLoading,
    isSuccess,
    isError,
    data: matches,
  } = useFetchTeamTournamentMatchesQuery({ team: team._id, tournament: tournamentId });

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <EmptyPage title={t('Ainda não há informações')} content={t('Adicione um novo Jogo de Campeonato, Amistoso ou Treino no botão Criar.')} />;
  } else if (isSuccess) {
    content = matches.map((match) => <MatchCard key={`match-card-${match._id}`} match={match} linkTo={`/matches/${match._id}/stats`} actionLabel={t('Ver Estatísticas')} showIcon />);
  }

  return (
    <>
      <Box sx={{ marginTop: '20px' }}>
        <TournamentLabel label={matches && matches[0]?.tournament} />
      </Box>
      {content}
    </>
  );
}

export default TeamListTournamentMatches;
