import './Footer.css';

import { Grid, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';
import {
  CircleButton,
  IconAthlete,
  IconAthleteFilled,
  IconHome,
  IconHomeFilled,
  IconStats,
  IconStatsFilled,
  IconScrimmage,
  IconTrophy,
  IconWistle,
  IconTactics,
} from 'shared/components';
import { TeamContext } from 'shared/contexts';

export function Footer() {
  const [state, setState] = useState({ openCreate: false });
  const team = useContext(TeamContext);
  const { t } = useTranslation();

  const location = useLocation();
  const isHome = !!((location.pathname.split('/').length === 3 && location.pathname === `/team/${team._id}`));
  const isAthlete = (location.pathname.split('/')[1] === 'athletes');
  const isStats = (location.pathname.split('/')[1] === 'stats');
  const linkActiveStyle = { boxSizing: 'border-box', color: '#0066CC' };
  const linkStyle = { boxSizing: 'border-box', color: '#5F6C7B' };

  const openCreateToggle = () => {
    setState((prevState) => ({ ...prevState, openCreate: !state.openCreate }));
  };

  return (
    <>
      <div id="footer">
        <CircleButton
          className={`createButton ${state.openCreate ? 'close' : ''}`}
          onClick={openCreateToggle}
        >
          {state.openCreate ? 'x' : '+'}
        </CircleButton>

        <ul className="footerList">
          <li>
            <NavLink
              to="/dashboard"
              style={isHome ? linkActiveStyle : linkStyle}
            >
              {
                isHome
                  ? <IconHomeFilled color="#0066CC" />
                  : <IconHome />
              }
              <br />
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/athletes"
              style={({ isActive }) => (isActive ? linkActiveStyle : linkStyle)}
            >
              {
                isAthlete
                  ? <IconAthleteFilled color="#0066CC" />
                  : <IconAthlete />
              }
              <br />
              {t('Atletas')}
            </NavLink>
          </li>
          <li className="createLabel">
            <span>{t('Criar')}</span>
          </li>
          <li>
            <NavLink
              to={`/stats/${team._id}`}
              style={({ isActive }) => (isActive ? linkActiveStyle : linkStyle)}
            >
              {
                isStats
                  ? <IconStatsFilled color="#0066CC" />
                  : <IconStats />
              }
              <br />
              {t('Estatísticas')}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/coach-ia"
              state={{ prevPath: location.pathname }}
              style={({ isActive }) => (isActive ? linkActiveStyle : linkStyle)}
            >
              <IconTactics />
              {' '}
              <br />
              {t('Coach AI')}
            </NavLink>
          </li>
        </ul>
      </div>

      {state.openCreate
        && (
          <div className="overlay">
            <Grid
              container
              alignItems="flex-end"
              justifyContent="space-around"
              spacing={1}
              sx={{
                color: '#ffffff',
                height: '80%',
              }}
            >
              <Grid
                item
                sx={{
                  width: '100px',
                  height: '80px',
                }}
              >
                <Link
                  to="/matches/new/tournament"
                >
                  <CircleButton
                    backgroundColor="#ffffff"
                  >
                    {' '}
                    {/* O Ideal seria passar theme.secondary como background color, para
                          aplicar cores nainteração do botão além do background */}
                    <IconTrophy />
                  </CircleButton>
                  <Typography
                    sx={{
                      color: '#ffffff',
                      textAlign: 'center',
                    }}
                  >
                    {t('Jogo de campeonato')}
                  </Typography>
                </Link>
              </Grid>
              <Grid
                item
                sx={{
                  width: '100px',
                  height: '80px',
                }}
              >
                <Link to="/matches/new/scrimmage">
                  <CircleButton backgroundColor="#ffffff">
                    <IconScrimmage />
                  </CircleButton>
                  <Typography
                    sx={{
                      color: '#ffffff',
                    }}
                  >
                    {t('Amistoso')}
                  </Typography>
                </Link>
              </Grid>
              <Grid
                item
                sx={{
                  width: '100px',
                  height: '80px',
                }}
              >
                <Link to="/matches/new/practice">
                  <CircleButton backgroundColor="#ffffff">
                    <IconWistle />
                  </CircleButton>
                  <Typography
                    sx={{
                      color: '#ffffff',
                    }}
                  >
                    {t('Treino')}
                  </Typography>
                </Link>
              </Grid>
            </Grid>

          </div>
        )}
    </>
  );
}

export default Footer;
