import { apiSlice } from './apiSlice';

const authSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: '/auth/login',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Teams', 'Matches', 'Plays', 'Athletes'],
    }),
    createUser: builder.mutation({
      query: (body) => ({
        url: '/auth/register',
        method: 'POST',
        body,
      }),
    }),
    recoverPassword: builder.mutation({
      query: (body) => ({
        url: '/auth/password/recover',
        method: 'POST',
        body,
      }),
    }),
    checkToken: builder.query({
      query: ({ email, token }) => `/auth/recover/token/${email}/${token}`,
    }),
    updateUserPassword: builder.mutation({
      query: (body) => ({
        url: '/auth/password/update',
        method: 'POST',
        body,
      }),
    }),
    refreshToken: builder.mutation({
      query: () => '/api/auth/token/refresh',
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useCreateUserMutation,
  useRecoverPasswordMutation,
  useCheckTokenQuery,
  useUpdateUserPasswordMutation,
  useRefreshTokenMutation,
} = authSlice;
