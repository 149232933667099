import React from 'react';
import {
  Box,
  Container,
} from '@mui/material';
import Grid from '@mui/material/Grid';

export default function PageNotFound() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={0}>
          <Grid>
            <img
              src="/assets/img/pageNotFound.jpeg"
              alt=""
              width="100%"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
