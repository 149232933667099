import {
  Alert, Box,
  Button, Container, Grid, InputAdornment, TextField, Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link, Navigate, useNavigate, useSearchParams,
} from 'react-router-dom';
import { IconEmail, IconLeftArrow } from 'shared/components';
import { useRecoverPasswordMutation } from 'shared/services';

export function Recover() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const [state, setState] = useState({ email: '', alertResponse: searchParams.get('invalid') ? { response: 'error', message: t('Link de recuperação de senha inválido') } : false, redirect: false });
  const [recoverPassword] = useRecoverPasswordMutation();
  const navigate = useNavigate();

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const { email } = state;
    if (!email) {
      setState((prevState) => ({ ...prevState, alertResponse: { response: 'error', message: t('Preencha seu email') } }));
    } else {
      const result = await recoverPassword({ email });
      if (result?.data?.success) {
        setState((prevState) => ({ ...prevState, alertResponse: { response: 'success', message: t('Verifique seu email.') } }));
        navigate('/recover', { replace: true });
      } else {
        setState((prevState) => ({ ...prevState, alertResponse: { response: 'error', message: t('E-mail não encontrado') } }));
        navigate('/recover', { replace: true });
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      {state.redirect && <Navigate to={state.redirect} />}
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 2,
            width: 'fit-content',
          }}
        >
          <Link
            to="/dashboard"
            sx={{
              width: 'fit-content',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconLeftArrow />
              <Typography variant="subtitle1" color="#3E4A59">
                {t('Voltar')}
              </Typography>
            </Box>
          </Link>
        </Box>
        <Box
          sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          id="recover-form"
          component="form"
          onSubmit={handleChangePassword}
        >
          <Grid container direction="column">
            <Grid
              item
              sx={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '18px',
                lineHeight: '24px',
                color: '#3E4A59',
                margin: '10px 0',
              }}
            >
              <Box
                sx={{
                  margin: '20px 0',
                }}
              >
                <Typography component="h1" variant="h6">
                  {t('Esqueci minha senha')}
                </Typography>
              </Box>
              <Typography
                variant="p"
                sx={{
                  fontWeight: '400',
                  fontSize: '16px',
                  color: '#3E4A59',
                  margin: '20px 0',
                }}
              >
                {t('Digite o e-mail associado a sua conta e enviaremos um e-mail com instruções para redefinir sua senha')}
              </Typography>
            </Grid>

            <Grid item>
              {
                state.alertResponse
                && (
                  <Alert
                    severity={state.alertResponse.response}
                  >
                    {state.alertResponse.message}
                  </Alert>
                )
              }
            </Grid>

            <Grid item>
              <TextField
                fullWidth
                margin="normal"
                required
                variant="filled"
                id="email"
                value={state.email}
                label="E-mail"
                name="email"
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconEmail />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box
        sx={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          width: '100%',
          zIndex: '2',
        }}
      >
        <Button
          type="submit"
          form="recover-form"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!state.email}
        >
          {t('Resetar Senha')}
        </Button>
      </Box>
    </>

  );
}

export default Recover;
