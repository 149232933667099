import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { EmptyPage } from 'pages';
import { Loading, MatchCard } from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { useFetchMatchesByTypeQuery } from 'shared/services';

// @todo: Alterar url em para api em teamSlice
// useFetchTeamMatchesByTypeQuery está utilizando url para matches de atleta no slice
export function TeamListScrimmageMatches() {
  const team = useContext(TeamContext);
  const { t } = useTranslation();
  const {
    isLoading,
    isSuccess,
    isError,
    data: scrimmageMatches,
  } = useFetchMatchesByTypeQuery({ team: team._id, type: 'scrimmage' }, { refetchOnMountOrArgChange: true });

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <EmptyPage title={t('Ainda não há informações')} content={t('Adicione um novo Jogo de Campeonato, Amistoso ou Treino no botão Criar.')} />;
  } else if (isSuccess && scrimmageMatches.length > 0) {
    content = scrimmageMatches.map((scrimmage) => <MatchCard key={`match-card-${scrimmage._id}`} match={scrimmage} athleteId="63584a7da07acd3e24abe2c9" linkTo={`/matches/${scrimmage._id}/stats`} actionLabel={t('Ver Estatísticas')} showIcon />);
  } else {
    content = <EmptyPage title={t('Ainda não há informações')} content={t('Adicione um novo Jogo de Campeonato, Amistoso ou Treino no botão Criar.')} />;
  }

  return (
    <Box>
      {content}
    </Box>
  );
}

export default TeamListScrimmageMatches;
