export function formatDecimalNumber(num, locale) {
  let formattedLocale;
  switch (locale) {
    case 'pt':
    case 'ptBR':
      formattedLocale = 'pt-BR';
      break;
    case 'en':
      formattedLocale = 'en-US';
      break;
    default:
      formattedLocale = locale;
  }

  const formatter = new Intl.NumberFormat(formattedLocale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formatted = formatter.format(num);
  return formatted.includes('.') || formatted.includes(',')
    ? formatted
    : num.toString();
}
