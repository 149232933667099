import { Button } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

class ConfirmAction extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: this.props.open,
    };
  }

  show = (callback) => (event) => {
    event.preventDefault();
    const e = {
      ...event,
      target: { ...event.target, value: event.target.value },
    };

    this.setState({
      open: true,
      callback: () => callback(e),
    });
  };

  hide = () => this.setState({ open: false, callback: null });

  confirm = () => {
    this.state.callback();
    this.hide();
  };

  render() {
    return (
      <>
        {this.state.open
            && (
            <>
              <div className="modal-overlay" />
              <div className="modal">
                <button type="button" onClick={this.hide} className="modal-close">x</button>
                <div className="modal-content">
                  <h2>{this.props.title}</h2>
                  {this.props.message}
                </div>
                <div className="modal-footer">
                  <Button color="primary" size="small" onClick={this.hide}>{t('Não').toUpperCase()}</Button>
                  <Button color="primary" size="small" onClick={this.confirm}>{t('Sim').toUpperCase()}</Button>
                </div>
              </div>
            </>
            )}
        {this.props.children(this.show)}
      </>
    );
  }
}

export default ConfirmAction;
