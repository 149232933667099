import { Container } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  AthleteStatsMatchTable,
  ErrorMessage,
  Loading,
  MatchCard,
} from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { AthleteHeader } from 'shared/layouts';
import { useFetchAthleteQuery, useFetchAthleteStatsByMatchQuery, useFetchMatchQuery } from 'shared/services';

export function AthleteMatchStats() {
  const team = useContext(TeamContext);
  const { athleteId, matchId } = useParams();
  const { t } = useTranslation();

  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data: stats,
  } = useFetchAthleteStatsByMatchQuery({ team: team._id, athlete: athleteId, match: matchId });

  const athlete = useFetchAthleteQuery({ team: team._id, athlete: athleteId });
  const match = useFetchMatchQuery({ team: team._id, match: matchId });

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <ErrorMessage messages={error.data.messages} />;
  } else if (isSuccess) {
    content = <AthleteStatsMatchTable stats={stats} />;
  }

  return (
    <>
      {athlete.isSuccess && <AthleteHeader athlete={athlete.data} backto={`/athletes/${athleteId}`} match={match.data} />}
      <Container component="main" maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column', minHeight: '70vh' }}>
        <MatchCard match={match.data} athleteId={athlete._id} actionLabel={t('Ver Estatísticas')} showIcon />
        {content}
      </Container>
    </>
  );
}

export default AthleteMatchStats;
