import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { EmptyPage } from 'pages';
import { IconTeamDefault, Loading } from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { useFetchMatchQuery, useFetchPlaysQuery } from 'shared/services';
import { useTranslation } from 'react-i18next';

export function ScoreCard() {
  const team = useContext(TeamContext);
  const { t } = useTranslation();
  const { matchId } = useParams();
  const match = useFetchMatchQuery({ team: team._id, match: matchId }, { skip: !matchId });
  const plays = useFetchPlaysQuery({ team: team._id, match: matchId }, { skip: !matchId });

  let content;
  if (match.isFetching || plays.isFetching) {
    content = <Loading />;
  } else if (match.isError || plays.isError) {
    content = <EmptyPage title="Ainda não há informações" content="Adicione um novo Jogo de Campeonato, Amistoso ou Treino no botão Criar." />;
  } else if (match.isSuccess || plays.isSuccess) {
    content = (
      <Card
        sx={{
          position: 'absolute',
          width: '90%',
          marginLeft: 'auto',
          marginRight: 'auto',
          left: '0',
          right: '0',
          textAlign: 'center',
          top: '80px',
          background: '#FFFFFF',
          boxShadow: '0px 8px 32px rgba(0, 102, 204, 0.16)',
          borderRadius: '4px',
          padding: '5px 0',
        }}
      >
        <CardContent>
          <Grid container sx={{ alignItems: 'center', padding: '8px' }}>
            <Grid
              item
              xs={3}
            >
              <Avatar
                sx={{
                  width: '32px',
                  height: '32px',
                  margin: '0 auto',
                }}
              >
                <IconTeamDefault />
              </Avatar>
              <Typography
                variant="subtitle2"
                align="center"
                sx={{
                  fontFamily: 'Mulish',
                  fontWeight: '600',
                  lineHeight: '130%',
                  textAlign: 'center',
                  color: '#3E4A59',
                  marginTop: '5px',
                }}
              >
                {team.name}
              </Typography>
            </Grid>

            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  marginTop: '8px',
                  textAlign: 'center',
                  fontSize: '24px',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '24px',
                    lineHeight: '24px',
                    color: '#3E4A59',
                  }}
                >
                  {(match.isSuccess && match?.data?.score_team) || '0'}
                  {' '}
                  X
                  {' '}
                  {(match.isSuccess && match.data?.score_adversary) || '0'}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Avatar
                sx={{
                  width: '32px',
                  height: '32px',
                  margin: '0 auto',
                }}
              >
                <IconTeamDefault />
              </Avatar>
              <Typography
                variant="subtitle2"
                align="center"
                sx={{
                  fontFamily: 'Mulish',
                  fontWeight: '600',
                  lineHeight: '130%',
                  textAlign: 'center',
                  color: '#3E4A59',
                  marginTop: '5px',
                }}
              >
                {(match.isSuccess && match.data?.adversary) || t('Adversário')}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  } else {
    content = <EmptyPage title="Ainda não há informações" content="Adicione um novo Jogo de Campeonato, Amistoso ou Treino no botão Criar." />;
  }
  if (match.isError || plays.isError) {
    return <div>Ocorreu um erro ao fazer a requisição</div>;
  }
  if (match.isFetching || plays.isFetching) {
    return <Loading />;
  }

  return (
    <Box>
      {content}
    </Box>
  );
}

export default ScoreCard;
