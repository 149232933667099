import { Container } from '@mui/material';
import { useContext } from 'react';
import {
  Outlet,
  useLocation,
  useParams,
} from 'react-router-dom';
import { ErrorMessage, Loading, MatchTypeMenu } from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { AthleteHeader } from 'shared/layouts';
import { useFetchAthleteQuery } from 'shared/services';

export function AthleteView() {
  const team = useContext(TeamContext);
  const { athleteId } = useParams();
  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data: athlete,
  } = useFetchAthleteQuery({ team: team._id, athlete: athleteId });

  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const backUrl = pathArray.length <= 5 ? `/${pathArray[1]}` : `/${pathArray[1]}/${athleteId}`;
  const prevPath = location.state?.prevPath ? location.state?.prevPath : backUrl;

  let content;

  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <ErrorMessage messages={error.data.messages} />;
  } else if (isSuccess) {
    content = <Outlet context={[athlete]} />;
  }

  return (
    <>
      <AthleteHeader athlete={athlete} backto={prevPath} />
      <MatchTypeMenu
        tournamentLink={`/athletes/${athleteId}/stats/tournaments`}
        scrimmageLink={`/athletes/${athleteId}/stats/scrimmage`}
        practiceLink={`/athletes/${athleteId}/stats/practice`}
        color="#0066CC"
      />
      <Container component="main" maxWidth="xs">
        {content}
      </Container>
    </>
  );
}

export default AthleteView;
