import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Navigate,
  useSearchParams,
} from 'react-router-dom';
import {
  IconEmail, IconLeftArrow,
  LanguageSwitcher,
  Logo,
  SelectLanguageDialog,
} from 'shared/components';
import { login, useLoginMutation } from 'shared/services';
import { useLanguageManager } from 'shared/hooks';

export function Login() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const version = process.env.REACT_APP_VERSION;
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const { handleLanguageChange } = useLanguageManager();

  const [state, setState] = useState({
    email: '',
    password: '',
    alertResponse: searchParams.get('passwordUpdated') ? { response: 'success', message: t('Senha atualizada com sucesso') } : false,
    welcomeMessage: searchParams.get('registered') === 'true'
      ? t('Verifique seu e-mail cadastrado para receber suas credenciais do primeiro acesso.')
      : t('Por favor, faça o login para continuar'),
    redirect: false,
    showPassword: false,
    openSelecteLanguageDialog: searchParams.get('registered') === 'true',
  });
  const [loginMutation] = useLoginMutation();

  const handleClickShowPassword = () => setState((prevState) => ({
    ...prevState, showPassword: !state.showPassword,
  }));

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseSelectLanguageDialog = () => {
    setState((prevState) => ({ ...prevState, openSelecteLanguageDialog: false }));
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    const { email, password } = state;
    if (!email || !password) {
      setState((prevState) => ({ ...prevState, alertResponse: { response: 'error', message: t('Preencha e-mail e senha para continuar!') } }));
    } else {
      try {
        const result = await loginMutation({ email, password });
        if (result.data?.token) {
          const { user, token } = result.data;
          const { lang } = jwtDecode(token);

          login(token);

          if (!lang) {
            await handleLanguageChange(user, Cookies.get('i18next'));
          } else {
            await handleLanguageChange(undefined, lang);
          }

          window.location = '/dashboard';
        } else {
          throw ('Mutation error', result);
        }
      } catch (err) {
        let responseError;
        if (
          err.error.data.messages[0] === 'Incorrect usemailername.'
          || err.error.data.messages[0] === 'Incorrect password.'
        ) {
          responseError = [t('Houve um problema com o login, verifique suas credenciais.')];
        } else {
          responseError = err.error.data.messages;
        }
        setState((prevState) => ({ ...prevState, alertResponse: { response: 'error', message: responseError } }));
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  if (state.redirect) return <Navigate to={state.redirect} />;

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 2,
          width: 'fit-content',
        }}
      >
        <Link to="/"><IconLeftArrow /></Link>
      </Box>
      <LanguageSwitcher color="#0066CC" />
      <Box
        sx={{
          marginTop: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        component="form"
        onSubmit={handleSignIn}
      >
        <Grid container direction="column">
          <Logo variant="h5" />
          {' '}
          <br />
          <Typography component="span">
            {t('Bem vindo!')}
            {' '}
            <br />
            {state.welcomeMessage}
          </Typography>

          {
            state.alertResponse
            && (
              <Alert
                severity={state.alertResponse.response}
              >
                {state.alertResponse.message}
              </Alert>
            )
          }

          <TextField
            margin="normal"
            required
            variant="filled"
            id="email"
            label="E-mail"
            name="email"
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconEmail />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            required
            margin="normal"
            variant="filled"
            id="password"
            label={t('Senha')}
            name="password"
            onChange={handleInputChange}
            type={state.showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment:
                (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
            }}
          />

          <Box sx={{ mt: 1 }}><Link to="/recover">{t('Esqueci a senha')}</Link></Box>

          <Grid item>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>

            <a href={process.env.REACT_APP_STRIPE_CHECKOUT_PAGE}>
              <Button
                fullWidth
                variant="text"
                sx={{
                  textTransform: 'none',
                  color: 'secondary',
                  margin: '20px 0',
                }}
              >
                <Typography component="span" sx={{ color: '#003d32' }}>
                  {t('Ainda não tem conta?')}
                  <br />
                  <Typography component="span" color="primary">{t('Crie uma conta')}</Typography>
                </Typography>
              </Button>
            </a>
          </Grid>
        </Grid>
      </Box>
      <Typography
        component="span"
        color="primary"
        sx={{
          position: 'absolute',
          bottom: 0,
        }}
      >
        {`${t('Versão')}: ${version}`}
        {environment !== 'Production' ? ` - ${environment}` : ''}
      </Typography>
      <SelectLanguageDialog
        openSelecteLanguageDialog={state.openSelecteLanguageDialog}
        handleClose={handleCloseSelectLanguageDialog}
      />
    </Container>
  );
}

export default Login;
