import { apiSlice } from './apiSlice';

const athleteSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchAthletes: builder.query({
      query: (team) => `/api/team/${team}/athletes`,
      providesTags: () => [{ type: 'Athletes', id: 'all' }],
    }),
    fetchAthlete: builder.query({
      query: ({ team, athlete }) => `/api/team/${team}/athlete/${athlete}`,
      providesTags: () => [{ type: 'Athletes', id: 'all' }],
    }),
    fetchAthleteTournaments: builder.query({
      query: ({ team, athlete }) => `/api/team/${team}/athlete/${athlete}/tournaments`,
      providesTags: (result, error, id) => [{ type: 'Athletes', id }],
    }),
    fetchAthleteTournamentsStatsResume: builder.query({
      query: ({ team, athlete }) => `/api/team/${team}/athlete/${athlete}/tournaments/stats/resume`,
      providesTags: (result, error, id) => [{ type: 'Athletes', id }],
    }),
    fetchAthleteResumeStatsByType: builder.query({
      query: ({ team, athlete, type }) => `/api/team/${team}/athlete/${athlete}/stats/${type}/resume`,
      providesTags: (result, error, id) => [{ type: 'Athletes', id }],
    }),
    fetchAthleteResumeStatsByMatch: builder.query({
      query: ({ team, athlete, match }) => `/api/team/${team}/athlete/${athlete}/stats/match/${match}/resume`,
      providesTags: (result, error, id) => [{ type: 'Athletes', id }],
    }),
    fetchAthleteStatsByMatch: builder.query({
      query: ({ team, athlete, match }) => `/api/team/${team}/athlete/${athlete}/stats/match/${match}`,
      providesTags: (result, error, id) => [{ type: 'Athletes', id }],
    }),
    fetchAthleteMatchesByType: builder.query({
      query: ({ team, athlete, type }) => `/api/team/${team}/athlete/${athlete}/matches/${type}`,
      providesTags: (result, error, id) => [{ type: 'Athletes', id }],
    }),
    createAthlete: builder.mutation({
      query: ({ team, body }) => ({
        url: `/api/team/${team}/athlete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Athletes', id: 'all' }],
    }),
    updateAthlete: builder.mutation({
      query: ({ team, body }) => ({
        url: `/api/team/${team}/athlete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Athletes', id: 'all' }],
    }),
    deleteAthlete: builder.mutation({
      query: ({ team, body }) => ({
        url: `/api/team/${team}/athlete`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [{ type: 'Athletes', id: 'all' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchAthletesQuery,
  useFetchAthleteQuery,
  useFetchAthleteTournamentsQuery,
  useFetchAthleteTournamentsStatsResumeQuery,
  useFetchAthleteResumeStatsByTypeQuery,
  useFetchAthleteResumeStatsByMatchQuery,
  useFetchAthleteStatsByMatchQuery,
  useFetchAthleteMatchesByTypeQuery,
  useCreateAthleteMutation,
  useUpdateAthleteMutation,
  useDeleteAthleteMutation,
} = athleteSlice;
