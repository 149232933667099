import { Container } from '@mui/material';
import { useEffect, useRef } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ErrorMessage, Loading } from 'shared/components';
import { LiveMatchHeader } from 'shared/layouts';
import { useFetchLiveMatchQuery } from 'shared/services';

export function LiveMatchView() {
  const { matchId } = useParams();
  let match;
  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data,
    refetch,
  } = useFetchLiveMatchQuery(
    { match: matchId },
    { refetchOnMountOrArgChange: true },
  );

  const matchRef = useRef(null);
  useEffect(() => {
    if (isSuccess) {
      matchRef.current = { ...data };
    }

    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => clearInterval(interval);
  }, [isSuccess, data, refetch]);

  let content;
  let header;
  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <ErrorMessage messages={error.data.message} />;
  } else if (isSuccess) {
    match = { ...data };
    match.started = !!match?.time;
    match.ended = !!(match?.time && match?.time?.second_time?.end);
    match.current_time = match.time && match?.time?.first_time?.end ? 2 : 1;
    content = <Outlet context={[match]} />;
    header = <LiveMatchHeader match={match} />;
  }

  return (
    <>
      {header}
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          minHeight: 'calc(100vh - 150px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: (match?.started) ? 'space-between' : 'space-evenly',
        }}
      >
        {content}
      </Container>
    </>
  );
}

export default LiveMatchView;
