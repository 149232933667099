import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconFlash } from 'shared/components';

export function AthleteStatsResumeCard(props) {
  const { t } = useTranslation();

  const resumeTitleStyle = {
    fontFamily: 'Mulish',
    fontWeight: '400',
    lineHeight: '24px',
    fontSize: '12px',
    color: '#50729A',
  };

  const resumeValueStyle = {
    fontWeight: '800',
    fontSize: '24px',
    color: '#3E4A59',
  };

  const tackle = props?.stats?.defense?.tackle || 0;
  const intercept = props?.stats?.defense?.intercept || 0;
  const pickSix = props?.stats?.defense?.pick_six || 0;

  const td = props?.stats?.offense?.td || 0;
  const first = props?.stats?.offense?.first || 0;
  const yards = props?.stats?.offense?.yards || 0;

  const mountBox = (title, stats) => (
    <Box>
      <Typography variant="subtitle2" sx={resumeTitleStyle}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{ ...resumeTitleStyle, ...resumeValueStyle }}>
        {stats}
      </Typography>
    </Box>
  );

  const defenseStats = (
    <Box sx={{
      display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '5px 10px', padding: '5px 10px',
    }}
    >
      {mountBox('Tackles', tackle)}
      {mountBox(t('Interceptações'), intercept)}
      {mountBox('Pick six', pickSix)}
    </Box>
  );

  const offenseStats = (
    <Box sx={{
      display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '5px 10px', padding: '5px 10px',
    }}
    >
      {mountBox('Touchdowns', td)}
      {mountBox('First', first)}
      {mountBox(t('Jardas'), yards)}
    </Box>
  );

  const mountCard = (title, content) => (
    <Card sx={{ margin: '20px 0' }}>
      <CardHeader
        sx={{ paddingBottom: '4px' }}
        title={(
          <Typography
            variant="body1"
            color="#0080FF"
            sx={{
              fontFamily: 'Mulish',
              fontWeight: '600',
              lineHeight: '24px',
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconFlash color="#0080FF" />
            {t('Resumo de desempenho')}
            {' - '}
            {title}
          </Typography>
                  )}
      />
      <CardContent sx={{ paddingTop: '4px' }}>
        {content}
      </CardContent>
    </Card>
  );

  return (
    <>
      {mountCard(t('Ataque'), offenseStats)}
      {mountCard(t('Defesa'), defenseStats)}
    </>
  );
}

export default AthleteStatsResumeCard;
