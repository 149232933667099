export const playerPositionOffensive = [
  {
    label: 'Quarterback',
    value: 'Quarterback',
  },
  {
    label: 'Center',
    value: 'Center',
  },
  {
    label: 'Running Back',
    value: 'Running Back',
  },
  {
    label: 'Wide Receiver',
    value: 'Wide Receiver',
  },
  {
    label: 'Slot Receiver',
    value: 'Slot Receiver',
  },
];

export const playerPositionDefensive = [
  {
    label: 'Middle Linebacker',
    value: 'Middle Linebacker',
  },
  {
    label: 'Strong Safety',
    value: 'Strong Safety',
  },
  {
    label: 'Free Safety',
    value: 'Free Safety',
  },
  {
    label: 'Linebacker Forte',
    value: 'Linebacker Forte',
  },
  {
    label: 'Linebacker Fraco',
    value: 'Linebacker Fraco',
  },
  {
    label: 'Blitzer',
    value: 'Blitzer',
  },
];

export const availableSectors = [
  {
    value: '',
    label: '',
  },
  {
    value: '1',
    label: 'Head Coach',
  },
  {
    value: '2',
    label: 'Coordenador Ofensivo',
  },
  {
    value: '3',
    label: 'Coordenador Defensivo',
  },
  {
    value: '4',
    label: 'Outros',
  },
];
