export default function IconScrimmage(props) {
  const customSize = props.customSize ? props.customSize : '24';
  return (
    <img
      src="/assets/img/team-shirts.png"
      alt=""
      width={customSize}
      height={customSize}
    />
  );
}
