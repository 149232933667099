import { Container } from '@mui/material';

export function ErrorMessage({ messages }) {
  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {Array.isArray(messages)
        ? messages.join('<br>')
        : messages}
    </Container>
  );
}

export default ErrorMessage;
